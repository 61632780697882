import React from 'react';
import moment from 'moment';

interface ViewCellProps {
  editor_email: string;
  edited_date: Date;
}
const EditTrackerCell = ({ editor_email, edited_date }: ViewCellProps) => {
  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5">
      <div className="flex flex-row items-center w-full justify-start">
        <div className="w-full">{editor_email}</div>
      </div>
      <div
        className="text-xs not-italic font-normal mt-2"
        style={{ color: 'var(--colors-primary-slate-500, #5E718D)' }}
      >
        {edited_date ? moment(edited_date).format('MMM DD, YYYY') : '-'}
      </div>
    </div>
  );
};

export default EditTrackerCell;
