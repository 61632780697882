import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import { CHRONOS_TABS } from 'constants/chronosTabs';
import { PIPELINE_FREEZE_LOAD_STATUS, PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from 'constants/pipelineStatus';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CircleSpinner, StageSpinner } from 'react-spinners-kit';
import useGetFetchConfig from '../../../api/useGetFetchConfig';
import ChronologyEditor from './ChronologyEditor';
import DocViewer from './DocViewer';
import DocumentsEditor from './DocumentsEditor';
import FactsEditor from './FactsEditor';
import PersonsEditor from './PersonsEditor';

const CaseEditor: React.FC = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const docId = searchParams.get('docId');
  const eventId = searchParams.get('eventId');
  const pageNumber = Number(searchParams.get('pageNumber'));
  const location = useLocation();
  const [caseName, setCaseName] = useState<string>('');
  const [casePipelineStatus, setCasePipelineStatus] = useState<string>('');
  const [queueEstimateDate, setQueueEstimateDate] = useState<Date>(new Date());
  const [inQueue, setInQueue] = useState<boolean>(false);
  const [queuePosition, setQueuePosition] = useState<number | null>(null);

  const [estimateDate, setTimeEstimateDate] = useState<Date>(new Date());
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const { fetchConfigGET } = useGetFetchConfig();

  const {
    isFetching: isLoadingCase,
    data: responseCase,
    refetch: refetchCase,
  } = useQuery(
    'currentCase',
    () => {
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: !PIPELINE_FREEZE_LOAD_STATUS.includes(casePipelineStatus),
      refetchInterval: 3000,
    },
  );

  useEffect(() => {
    refetchCase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseCase?.caseObject?.case_id) {
      setCaseName(responseCase?.caseObject?.case_name);
      setCasePipelineStatus(responseCase?.runStatus?.pipeline_status);
      setTimeEstimateDate(responseCase?.runStatus?.time_estimate_datetime);
      setInQueue(responseCase?.runStatus?.in_queue);
      setQueuePosition(responseCase?.runStatus?.queue_position || null);
      setQueueEstimateDate(responseCase?.runStatus?.queue_estimate_datetime);
    }
  }, [responseCase]);

  useEffect(() => {
    if (docId) {
      setModalIsOpen(true);
    }
  }, [docId, eventId]);

  const tabSelectedStyles = 'inline-flex justify-center items-center rounded-lg bg-gray-300';
  const tabUnselectedStyles = 'inline-flex justify-center items-center rounded-lg';

  const customStyles = {
    content: {
      zIndex: 10,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function closeModal() {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('caseId', caseId || '');
    searchParams.delete('eventId');
    searchParams.delete('docId');
    searchParams.delete('pageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
    setModalIsOpen(false);
  }

  const handleClickBack = () => {
    navigate(`/app/chronos/explore?matterId=${responseCase?.caseObject?.matter_id}`);
  };

  const currentView = location.pathname.split('/').pop();

  const getMessage = (
    inQueue: Boolean,
    queuePosition: number | null,
    estimateDate: Date,
    queueEstimateDate: Date,
  ): string => {
    if (inQueue && queuePosition) {
      return (
        `Your case is in queue position ${queuePosition}` +
        (queueEstimateDate
          ? `, estimated time to begin processing: ${moment.tz(queueEstimateDate, 'Europe/London').format('hh:mm A')}`
          : '')
      );
    }
    if (inQueue && !queuePosition) {
      return 'Queue position being calculated';
    }
    if (!inQueue && estimateDate) {
      return `Estimated time of completion for Chronology: ${moment
        .tz(estimateDate, 'Europe/London')
        .format('hh:mm A')} `;
    }
    return 'Case processing. Time estimate being calculated';
  };

  return (
    <>
      <Modal
        isOpen={!!docId && modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Document viewer"
      >
        <DocViewer docId={docId} caseId={caseId} eventId={eventId} pageNumber={pageNumber} />
      </Modal>
      <div className="flex flex-col items-start justify-start w-full h-full text-black overflow-hidden">
        <div className="w-full h-full pt-5 bg-cover bg-center pb-2 pr-8 pl-8">
          {PIPELINE_PROCESSING_STATUS.includes(casePipelineStatus) ? (
            <div>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer"
                onClick={() => handleClickBack()}
              />
              <div
                className="text-black text-3xl not-italic font-semibold mb-6 flex flex-row items-center"
                style={{ color: '#6D6C6C' }}
              >
                {getMessage(inQueue, queuePosition, estimateDate, queueEstimateDate)}
              </div>
              <div className='"text-black text-2xl italic font-semibold mb-5 mt-2' style={{ color: '#8b0000' }}>
                Please do not close this tab
              </div>
              {PIPELINE_PROCESSING_STATUS.map((status: string, index) => {
                const done = PIPELINE_PROCESSING_STATUS.indexOf(casePipelineStatus) > index;
                const inProgress = PIPELINE_PROCESSING_STATUS.indexOf(casePipelineStatus) === index;
                const todo = PIPELINE_PROCESSING_STATUS.indexOf(casePipelineStatus) < index;
                return (
                  <div
                    key={status}
                    className={`text-black text-3xl not-italic font-semibold mb-8 flex flex-row items-center  ${
                      todo ? 'opacity-20' : ''
                    }`}
                  >
                    <div className="mr-4">{PIPELINE_STATUS[status]?.label}</div>{' '}
                    {inProgress && <CircleSpinner color={'#000000'} size={24} />}
                    {done && '✅'}
                  </div>
                );
              })}
            </div>
          ) : casePipelineStatus === PIPELINE_STATUS.failed.value ? (
            <div className={`text-black text-3xl not-italic font-semibold mb-8 flex flex-row items-center mt-20`}>
              <div className="mr-4">
                Chronos pipeline error: a member of the Wexler technical staff has been informed & will reach out
                shortly when the issue has been resolved
              </div>{' '}
              ❌
            </div>
          ) : isLoadingCase ? (
            <div className="w-full flex flex-col items-center justify-center">
              <StageSpinner className="m-auto" color={'#4161FF'} /> Your case is being loaded
            </div>
          ) : [PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(casePipelineStatus) ? (
            <div className="w-full h-full overflow-hidden">
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="mr-3 text-black w-6 h-6 cursor-pointer"
                  onClick={() => handleClickBack()}
                />
                <p
                  className="w-full focus:outline-none text-black text-3xl not-italic font-bold placeholder:text-gray-400 rounded-lg"
                  style={{}}
                >
                  {caseName}
                </p>
              </div>
              <div className="flex flex-row mt-3">
                <div
                  onClick={() => navigate(`/app/chronos/case-editor/documents?caseId=${caseId}`)}
                  className={`cursor-pointer py-2 px-4 mr-4 ${
                    currentView === CHRONOS_TABS.DOCUMENTS ? tabSelectedStyles : tabUnselectedStyles
                  }`}
                >
                  Documents
                </div>
                {/* <div
                      onClick={() => navigate(`/app/chronos/case-editor/persons?caseId=${caseId}`)}
                      className={`cursor-pointer py-2 px-4 mr-4 ${
                        currentView === CHRONOS_TABS.PERSONS ? tabSelectedStyles : tabUnselectedStyles
                      }`}
                    >
                      Persons
                    </div> */}
                <div
                  onClick={() => navigate(`/app/chronos/case-editor/facts?caseId=${caseId}`)}
                  className={`cursor-pointer py-2 px-4 mr-4 ${
                    currentView === CHRONOS_TABS.FACTS ? tabSelectedStyles : tabUnselectedStyles
                  }`}
                >
                  Facts
                </div>
                <div
                  onClick={() => navigate(`/app/chronos/case-editor/chronology?caseId=${caseId}`)}
                  className={`cursor-pointer py-2 px-4 mr-4 ${
                    currentView === CHRONOS_TABS.CHRONOLOGY ? tabSelectedStyles : tabUnselectedStyles
                  }`}
                >
                  Chronologies
                </div>
              </div>
              <Routes>
                <Route path="documents" element={<DocumentsEditor />} />
                <Route path="persons" element={<PersonsEditor />} />
                <Route path="facts" element={<FactsEditor />} />
                <Route path="chronology" element={<ChronologyEditor />} />
                <Route path="*" element={<Navigate to={`/app/chronos/case-editor/documents`} />} />
              </Routes>
            </div>
          ) : (
            <div className="w-full flex flex-col items-center justify-center">
              <StageSpinner className="m-auto" color={'#4161FF'} /> Your case is being loaded
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CaseEditor;
