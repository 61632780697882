import { ColumnDef } from '@tanstack/react-table';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { TooltipStyles } from 'constants/styles';
import { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { APIBaseChronos } from '../../../../api/hosts';
import { ChronosDoc } from '../../../../types';
import DateInputCell from '../components/DateInputCell';
import DocumentIsProcessedCell from './DocumentIsProcessedCell';
import EditTrackerCell from './EditTrackerCell';
import FileAuthorCell from './FileAuthorCell';
import FileNameCell from './FileNameCell';
import ViewCell from './ViewCell';
import { filterFns } from './index';
import DateCell from './DateCell';

export const columnLabels: Record<string, string> = {};

const useGetDocumentColumns = () => {
  const columns = useMemo<ColumnDef<ChronosDoc, any>[]>(
    () => [
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="name-tooltip"
              data-tooltip-content="Name of file as uploaded"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="name-tooltip" style={TooltipStyles} />
            Name
          </div>
        ),
        accessorKey: 'file_name',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { file_name, file_extension, doc_id },
            },
          },
        }: any) => <FileNameCell file_name={file_name} file_extension={file_extension} doc_id={doc_id} />,
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="date-tooltip"
              data-tooltip-content="Date document written"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="date-tooltip" style={TooltipStyles} />
            Document Date
          </div>
        ),
        accessorKey: 'document_date',
        enableColumnFilter: false,
        minSize: 128,
        maxSize: 128,
        cell: ({
          cell: {
            row: {
              original: { document_date, doc_id },
            },
          },
        }: any) => (
          <DateInputCell
            editable={true}
            entryId={doc_id}
            entryFieldKey={'documentDate'}
            entryFieldValue={document_date}
            endpointToUpdate={`${APIBaseChronos}/client/case/doc/${doc_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="processed-tooltip"
              data-tooltip-content="Has Wexler been able to extract information from it"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="processed-tooltip" style={TooltipStyles} />
            Processed
          </div>
        ),
        accessorKey: 'is_processed',
        minSize: 150,
        filterFn: filterFns.boolean,
        enableColumnFilter: true,
        cell: ({
          cell: {
            row: {
              original: { is_processed },
            },
          },
        }: any) => <DocumentIsProcessedCell is_processed={is_processed} />,
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="editted-tooltip"
              data-tooltip-content="When and by whom the document or associated facts was last edited"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="editted-tooltip" style={TooltipStyles} />
            Edited
          </div>
        ),
        accessorKey: 'editor_email',
        enableColumnFilter: false,
        enableSorting: true,
        minSize: 160,
        maxSize: 160,
        cell: ({
          cell: {
            row: {
              original: { editor_email, edited_date },
            },
          },
        }: any) => <EditTrackerCell editor_email={editor_email} edited_date={edited_date} />,
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="author-tooltip"
              data-tooltip-content="Which person wrote the document"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="author-tooltip" style={TooltipStyles} />
            Author
          </div>
        ),
        accessorKey: 'document_author',
        enableColumnFilter: false,
        minSize: 160,
        maxSize: 160,
        cell: ({
          cell: {
            row: {
              original: { document_author, doc_id },
            },
          },
        }: any) => <FileAuthorCell document_author={document_author} doc_id={doc_id} />,
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="facts-tooltip"
              data-tooltip-content="How many facts has Wexler extracted from this document"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="facts-tooltip" style={TooltipStyles} />
            Facts
          </div>
        ),
        accessorKey: 'facts_count',
        enableColumnFilter: false,
        minSize: 70,
        maxSize: 70,
        cell: ({
          cell: {
            row: {
              original: { facts_count },
            },
          },
        }: any) => <div className="flex items-center justify-center">{facts_count}</div>,
      },
      // {
      //   header: () => (
      //     <div className="flex flex-row items-center">
      //       <ReactInfo
      //         data-tooltip-id="sourcedoc-tooltip"
      //         data-tooltip-content="Document from which fact was extracted, click to view source text"
      //         className="text-gray-700 mr-2 text-sm cursor-pointer"
      //         style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
      //       />
      //       <Tooltip id="sourcedoc-tooltip" style={TooltipStyles} />
      //       Attachments
      //     </div>
      //   ),
      //   accessorKey: 'source_doc',
      //   enableColumnFilter: false,
      //   cell: ({
      //     cell: {
      //       row: {
      //         original: { source_entries_details },
      //       },
      //     },
      //   }: any) => (
      //     <div>
      //       {source_entries_details?.map((document: ChronosDoc) => {
      //         return (
      //           <div
      //             key={document.doc_id}
      //             className="cursor-pointer text-blue-500 hover:underline hover:text-blue-600"
      //             onClick={() => goToDocReference(document.doc_id)}
      //           >
      //             {document?.file_name?.split('.')?.filter(Boolean)[0]}
      //           </div>
      //         );
      //       })}
      //     </div>
      //   ),
      // },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <p>Upload Date</p>
          </div>
        ),
        accessorKey: 'date_uploaded',
        enableColumnFilter: false,
        minSize: 150,
        maxSize: 150,
        cell: ({
          cell: {
            row: {
              original: { date_uploaded },
            },
          },
        }: any) => <DateCell format={"DD MMMM YYYY"} date={date_uploaded} />,
      },
      {
        header: () => <div />,
        accessorKey: 'view',
        minSize: 60,
        maxSize: 60,
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { doc_id },
            },
          },
        }: any) => <ViewCell doc_id={doc_id} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return columns;
};

export default useGetDocumentColumns;
