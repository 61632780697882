import { Navigate, Route, Routes } from 'react-router-dom';
import Admin from './Admin';
import CaseCreator from './CaseCreator';
import CaseEditor from './CaseEditor';
import CaseSynopsis from './CaseSynopsis';
import CasesExplorer from './CasesExplorer';
import DocAdder from './DocAdder';
import Guide from './Guide';
import MatterCreator from './MatterCreator';
import MatterEditor from './MatterCreator/MatterEditor';
import MattersExplorer from './MattersExplorer';

const Chronos = () => {
  return (
    <Routes>
      <Route path="matters" element={<MattersExplorer />} />
      <Route path="explore" element={<CasesExplorer />} />
      <Route path="case-creator/:caseId" element={<CaseCreator />} />
      <Route path="matter-creator" element={<MatterCreator />} />
      <Route path="matter-editor/:matterId" element={<MatterEditor />} />
      <Route path="doc-adder/:caseId" element={<DocAdder />} />
      <Route path="case-editor/*" element={<CaseEditor />} />
      <Route path="admin" element={<Admin />} />
      <Route path="guide" element={<Guide />} />
      <Route path="case-synopsis" element={<CaseSynopsis />} />
      <Route path="*" element={<Navigate to="/app/chronos/matters" />} />
    </Routes>
  );
};

export default Chronos;
