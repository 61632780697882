import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rankItem } from '@tanstack/match-sorter-utils';
import { APIBaseChronos } from 'api/hosts';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { ChronosDoc, FilterOption } from 'types';
import SearchBox from '../components/SearchBox';
import useGetDocumentColumns from './useGetDocumentColumns';

import {
  ColumnFiltersState,
  FilterFn,
  Header,
  SortDirection,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import useGetFetchConfig from '../../../../api/useGetFetchConfig';
import MultiselectFilter from '../components/Filters/MultiselectFilter';

const initialSortingState = [
  {
    id: 'file_name',
    desc: false,
  },
];

const sortingIcons: Record<SortDirection, JSX.Element> = {
  asc: (
    <FontAwesomeIcon
      icon={faArrowUp}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
  desc: (
    <FontAwesomeIcon
      icon={faArrowDown}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
};

const HeaderContent = ({
  header,
  filterValue,
}: {
  header: Header<ChronosDoc, unknown>;
  filterValue: FilterOption[];
}) => {
  const centerItem = ['facts_count', 'verified_facts_count', 'is_processed'].includes(header.id);

  return (
    <div
      className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'} pl-3 cursor-pointer`}
    >
      <div
        onClick={header.column.getToggleSortingHandler()}
        className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'}`}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
        {sortingIcons[header.column.getIsSorted() as SortDirection] ?? null}
      </div>
      {header.column.getCanFilter() && <MultiselectFilter column={header.column} filterValue={filterValue} />}
    </div>
  );
};

interface FilterFns {
  fuzzyFilter: FilterFn<ChronosDoc>;
  boolean: FilterFn<ChronosDoc>;
}

export const filterFns: FilterFns = {
  fuzzyFilter: (row, columnId, filterValue: string, addMeta) => {
    if (!filterValue.length) return true;
    const itemRank = rankItem(row.getValue(columnId), filterValue);

    addMeta({
      itemRank,
    });

    const wordsArray = filterValue.split(' ').filter(word => word.trim() !== '');
    const cellValue = row.getValue(columnId) as string || '';
    const isWordPresent = wordsArray.every(word => cellValue.toLowerCase().includes(word.toLowerCase()));
    return isWordPresent;
  },
  boolean: (row, columnId, filterValue: FilterOption[]) => {
    if (!filterValue.length) return true;
    const rowValue = row.getValue(columnId);

    return filterValue.some((filter) => filter.value === rowValue);
  },
};

const DocumentEditor = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>(initialSortingState);
  const [docs, setDocs] = useState<ChronosDoc[]>([]);
  const navigate = useNavigate();

  const columns = useGetDocumentColumns();
  const { fetchConfigGET } = useGetFetchConfig();

  const table = useReactTable<ChronosDoc>({
    columns,
    data: docs,
    state: { globalFilter, columnFilters, sorting },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: filterFns.fuzzyFilter,
    onSortingChange: setSorting,
  });

  const {
    data: responseDocs,
    refetch: refetchCaseDocs,
    isLoading: isLoadingDocsTable,
  } = useQuery(
    ['userDocs'],
    () => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    refetchCaseDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseDocs?.docs && responseDocs?.docs?.length > 0) {
      setDocs(responseDocs.docs);
      // setEmailsMapping(responseDocs.emailsMapping);
    }
  }, [responseDocs]);

  const handleClickAddDocumentsItem = (id: string) => {
    navigate(`/app/chronos/doc-adder/${id}`);
  };

  const processedCount = docs.filter((doc) => doc.is_processed).length;
  const rowsToRender = table.getRowModel().rows;
  const isEmptyAfterFiltering = rowsToRender.length === 0;

  const headersLength = table.getHeaderGroups()[0].headers.length;
  const clearAllFilters = useCallback(() => {
    setColumnFilters([]);
  }, []);

  return (
    <>
      <div className="flex flex-row items-center">
        <div
          className="flex items-center justify-center bg-white mb-3 cursor-pointer rounded-2xl mt-4 w-56"
          style={{ background: 'rgba(66, 98, 255, 0.05)' }}
        >
          <div
            className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold  w-56"
            style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
            onClick={() => handleClickAddDocumentsItem(caseId || '')}
          >
            Add Documents
          </div>
        </div>
        <div>
          <p className="text-gray-900 not-italic font-bold leading-6 ml-8">
            {processedCount}/{docs.length} documents processed
          </p>

          <p className=" text-gray-500 not-italic leading-6 ml-8">
            {processedCount / docs.length !== 1 && 'Please add facts manually to the remaining documents'}
          </p>
        </div>
        <div className="ml-auto flex">
          <button
            className="text-gray-900 not-italic font-bold leading-6 ml-auto mr-5 cursor-pointer"
            onClick={clearAllFilters}
          >
            Clear filters
          </button>
          <SearchBox
            value={globalFilter}
            placeholder={`Search ${docs.length} document(s)`}
            onChange={setGlobalFilter}
          />
        </div>
      </div>
      {isLoadingDocsTable && (
        <div className="w-full h-24 flex items-center justify-center">
          <StageSpinner className="m-auto" color={'#4161FF'} />
        </div>
      )}
      <div
        className="w-full flex flex-column overflow-scroll  max-h-[calc(100vh-180px)]"
        style={{
          borderRadius: '16px',
          border: '1px solid var(--colors-primary-slate-100, #E9EFF6)',
          background: '#FFF',
        }}
      >
        {docs && docs.length > 0 && (
          <div className="w-full">
            <table className="w-full">
              <thead className="h-12" style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)' }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const filterValue = header.column.getFilterValue() as FilterOption[];

                      return (
                        <th
                          key={header.id}
                          className="not-italic font-medium"
                          style={{ width: header.column.getSize() }}
                        >
                          <HeaderContent header={header} filterValue={filterValue} />
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {isEmptyAfterFiltering ? (
                  <tr>
                    <td colSpan={headersLength}>
                      <div className="flex justify-center items-center flex-col mt-10">
                        <div className="mb-5">No results</div>
                        <div
                          className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold  w-56 cursor-pointer"
                          style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                          onClick={clearAllFilters}
                        >
                          Clear filters
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  rowsToRender.map((row, i) => {
                    return (
                      <tr style={{ background: i % 2 === 0 ? 'white' : '#F9FAFB',
                      borderBottom: '1px solid #c8cad7',
                      }} key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td className="pl-3" key={cell.id} id={cell.id} style={{ width: cell.column.getSize() }}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentEditor;
