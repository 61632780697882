import React from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PrivateApp from './PrivateApp';
import { APIBase } from './api/auth';
import { UserProvider } from './Contexts/User';
import { RequiredAuthProvider, RedirectToLogin } from '@propelauth/react';
import { StageSpinner } from 'react-spinners-kit';
// import { HotjarProvider } from './Contexts/Hotjar';
import TopBar from './TopBar';
import 'intro.js/introjs.css';
import 'react-tooltip/dist/react-tooltip.css';

const queryClient = new QueryClient();

const BrowserPrompt = () => {
  return (
    <div className="flex lg:hidden justify-center items-center h-screen">
      <p>Wexler is optimised for desktop screens</p>
    </div>
  );
};

function App() {
  return (
    <RequiredAuthProvider
      authUrl={APIBase}
      displayWhileLoading={
        <div className="h-screen w-screen flex items-center justify-center">
          <StageSpinner className="m-auto" color={'#081D57'} />
        </div>
      }
      displayIfLoggedOut={<RedirectToLogin />}
    >
      <UserProvider>
        {/* <HotjarProvider> */}
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <BrowserPrompt />
              <div className="flex flex-row h-screen">
                <TopBar />
                <Routes>
                  <Route path="app/*" element={<PrivateApp />} />
                  <Route path="*" element={<Navigate to="/app" />} />
                </Routes>
              </div>
            </BrowserRouter>
          </QueryClientProvider>
        {/* </HotjarProvider> */}
      </UserProvider>
    </RequiredAuthProvider>
  );
}

export default App;
