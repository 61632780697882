import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { StageSpinner } from 'react-spinners-kit';

import { ReactComponent as ExcelIcon } from 'assets/Excel.svg';
import Pagination, { PaginationProps } from '../../../Pagination';

import SearchBox from '../../components/SearchBox';
import { ChronosChronology, ChronosChronologyEvent } from '../../../../../types';
import { MutableRefObject } from 'react';
import ShowHideColumns from '../../ShowHideColumns';
import { Column } from '@tanstack/react-table';
import { chronologyColumnLabels } from '../useGetChronologyColumns';

interface ChronologyTableToolbarProps {
  globalFilter: string;
  chronologyId: string;
  isLoadingDownload: boolean;
  viewDownloadOptions: boolean;
  chronologies: ChronosChronology[];
  wrapperRef: MutableRefObject<HTMLDivElement | null>;
  setGlobalFilter: (value: string) => void;
  toggleViewDownloadOptions: () => void;
  fetchDownload: (docType: string) => void;
  handleGoToChronologiesList: () => void;
  resetVisibleColumns: () => void;
  columns: Column<ChronosChronologyEvent>[];
  openModal: () => void;
  isLoadingFacts: boolean;
}

const ChronologyTableToolbar = ({
  columns,
  globalFilter,
  setGlobalFilter,
  chronologyId,
  chronologies,
  wrapperRef,
  isLoadingDownload,
  viewDownloadOptions,
  fetchDownload,
  toggleViewDownloadOptions,
  handleGoToChronologiesList,
  resetVisibleColumns,
  openModal,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  isLoadingFacts,
}: ChronologyTableToolbarProps & PaginationProps) => {
  return (
    <div>
      <div className="flex flex-row cursor-pointer items-center mt-3">
        <div onClick={handleGoToChronologiesList} className="text-blue-500">
          Chronologies List
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-gray-700 mx-2 cursor-pointer"
          style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
        />
        <div className="text-blue-500 font-semibold">
          {
            chronologies?.filter((chronology) => {
              return chronology.chronology_id === chronologyId;
            })?.[0]?.latest_title
          }
        </div>
      </div>
      <div className="mb-3 mt-4 flex flex-row items-center justify-between">
        <ShowHideColumns
          columns={columns}
          resetVisibleColumns={resetVisibleColumns}
          columnLabels={chronologyColumnLabels}
        />

        <div className="relative ml-8 mr-auto" ref={wrapperRef}>
          <div
            className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold w-36 mr-auto cursor-pointer"
            style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
            onClick={toggleViewDownloadOptions}
            data-tooltip-id="download-tooltip"
          >
            {isLoadingDownload ? <StageSpinner className="m-auto h-4" color={'#4161FF'} /> : 'Download'}
          </div>
          {viewDownloadOptions && (
            <div
              className="absolute left-11 top-14 bg-white w-72 rounded-2xl"
              style={{ zIndex: 1000, boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <div className="flex flex-col w-full cursor-pointer">
                <div
                  className="w-full  p-4 hover:bg-slate-100  rounded-t-2xl flex flex-row items-center"
                  onClick={() => fetchDownload('xlsx')}
                >
                  <ExcelIcon className="mr-4" /> Download Excel
                </div>
              </div>
            </div>
          )}
        </div>
        <Pagination
          canGetPrevPage={canGetPrevPage}
          canGetNextPage={canGetNextPage}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          noOfPages={noOfPages}
          isLoadingFacts={isLoadingFacts}
        />


      </div>
      <div className="flex flex-row mb-4">
          <div
            className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold w-36 mr-10 cursor-pointer mr-auto"
            style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
            onClick={openModal}
          >
            Add Fact
          </div>
          <SearchBox value={globalFilter} placeholder={'Search chronology'} onChange={setGlobalFilter} />
        </div>
    </div>
  );
};

export default ChronologyTableToolbar;
