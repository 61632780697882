export const MODES = {
    MENU: {
      id: 'menu',
    },
    NOT_RIGHT: {
      id: 'not_right',
    },
    IMPORTANT: {
      id: 'important',
    },
    COMMENT: {
      id: 'comment',
    },
    VIEW_COMMENT: {
      id: 'view_comment',
    }
  }
  