import styled from 'styled-components';

export const EditorContainer = styled.div`
  .ql-container {
    border: none !important;
  }
  .ql-editor {
    padding: 0px;
    padding-bottom: 45px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: normal;
    font-family: 'Lota Grotesque';
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
      Helvetica Neue, sans-serif;
  }
`;

export const EditorContainerParagraph = styled.div`
  .ql-container {
    border: none !important;
    margin-top: 0px !important;
  }
  .ql-editor {
    padding: 0px;
    padding-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: normal;
    font-family: 'Lota Grotesque';
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
      Helvetica Neue, sans-serif;
  }
`;
