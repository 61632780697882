import React, { useState, useEffect, useMemo } from 'react';
import { StageSpinner } from 'react-spinners-kit';
import { useQuery } from 'react-query';
import { APIBaseChronos } from 'api/hosts';
import { useSearchParams } from 'react-router-dom';
import { useTable, Column, TableState, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { ChronosPerson } from 'types';
import TextInputCell from '../components/TextInputCell';
import { PERSONS_IMPORTANT_OPTIONS } from 'constants/chronos_table_options';
import { faArrowDown, faArrowUp, faRightLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectInputCell from '../components/SelectInputCell';
import PersonAdder from './PersonAdder';
import SearchBox from '../components/SearchBox';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { TooltipStyles } from 'constants/styles';
import useGetFetchConfig from '../../../../api/useGetFetchConfig';

const PersonEditor: React.FC = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [persons, setPersons] = useState<ChronosPerson[]>([]);
  const { fetchConfigGET } = useGetFetchConfig();

  const columns: Column<ChronosPerson>[] = useMemo(
    () => [
      {
        Header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="name-tooltip"
              data-tooltip-content="Name of entity involved in the dispute"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="name-tooltip" openOnClick style={TooltipStyles} />
            Rank
          </div>
        ),
        accessor: 'rank',
        show: false,
        Cell: ({
          cell: {
            row: {
              original: { rank, person_id },
            },
          },
        }: any) => (
          <div className="w-0" style={{ display: 'none' }}>
            {rank}
          </div>
        ),
      },
      {
        Header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="name-tooltip"
              data-tooltip-content="Name of entity involved in the dispute"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="name-tooltip" openOnClick style={TooltipStyles} />
            Name
          </div>
        ),
        accessor: 'person_name',
        Cell: ({
          cell: {
            row: {
              original: { person_name, person_id },
            },
          },
        }: any) => (
          <TextInputCell
            entryId={person_id}
            entryFieldKey={'person_name'}
            entryFieldValue={person_name}
            editable={true}
            endpointToUpdate={`${APIBaseChronos}/client/case/person/${person_id}`}
          />
        ),
      },
      {
        Header: () => (
          <div className="flex flex-row items-center ">
            <ReactInfo
              data-tooltip-id="description-tooltip"
              data-tooltip-content="Description of the entity, taken directly from the document provided and/or initial case description"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="description-tooltip" openOnClick style={TooltipStyles} />
            Description
          </div>
        ),
        accessor: 'person_description',
        Cell: ({
          cell: {
            row: {
              original: { person_description, person_id },
            },
          },
        }: any) => (
          <TextInputCell
            entryId={person_id}
            entryFieldKey={'person_description'}
            entryFieldValue={person_description}
            editable={true}
            endpointToUpdate={`${APIBaseChronos}/client/case/person/${person_id}`}
          />
        ),
      },
      {
        Header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="important-tooltip"
              data-tooltip-content="Is this person important to the dispute, defined as whether they are the subject of any important facts in the dispute, as extracted by Wexler"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="important-tooltip" openOnClick style={TooltipStyles} />
            Important
          </div>
        ),
        accessor: 'important',
        Cell: ({
          cell: {
            row: {
              original: { important, person_id },
            },
          },
        }: any) => (
          <SelectInputCell
            entryId={person_id}
            entryFieldKey={'important'}
            entryFieldValue={important}
            endpointToUpdate={`${APIBaseChronos}/client/case/person/${person_id}`}
            options={PERSONS_IMPORTANT_OPTIONS}
          />
        ),
      },
    ],
    [],
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data: persons,
      initialState: {
        sortBy: [
          {
            id: 'rank',
            desc: false,
          },
        ],
      } as Partial<TableState<ChronosPerson>>,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  ) as any;

  const {
    data: responsePersons,
    isLoading: isLoadingPersonsTable,
    refetch: refetchCasePersons,
  } = useQuery(
    ['userPersons'],
    () => {
      return fetch(`${APIBaseChronos}/client/case/person/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    refetchCasePersons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responsePersons?.persons && responsePersons?.persons?.length > 0) {
      setPersons(responsePersons.persons);
    }
  }, [responsePersons]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleNewPersonCreated = () => {
    refetchCasePersons();
    closeModal();
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <PersonAdder case_id={caseId || ''} handleNewPersonCreated={handleNewPersonCreated} />
      </Modal>
      <div className="mb-3 mt-4 flex flex-row">
        <div
          className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold  w-56 cursor-pointer"
          style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
          onClick={() => setModalIsOpen(true)}
        >
          Add person
        </div>
        <div className="ml-auto">
          <SearchBox
            value={globalFilter || ''}
            placeholder={'Search person'}
            onChange={(value) => setGlobalFilter(value)}
          />
        </div>
      </div>
      {isLoadingPersonsTable && (
        <div className="w-full h-24 flex items-center justify-center">
          <StageSpinner className="m-auto" color={'#4161FF'} />
        </div>
      )}
      <div
        className="w-full flex flex-column overflow-scroll"
        style={{
          borderRadius: '16px',
          border: '1px solid var(--colors-primary-slate-100, #E9EFF6)',
          background: '#FFF',
        }}
      >
        {persons && persons.length > 0 && (
          <div className="w-full" style={{ minHeight: '500px' }}>
            <table {...getTableProps()} className="w-full">
              <thead className="h-12" style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)' }}>
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => {
                      const headerStyle = column.id === 'rank' ? { display: 'none' } : {};
                      return (
                        <th {...column.getHeaderProps()} className="not-italic font-medium" style={headerStyle}>
                          {generateSortingIndicator(column)}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row: any, i: number) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} style={{ background: i % 2 === 0 ? 'white' : '#F9FAFB' }}>
                      {row.cells.map((cell: any) => {
                        const cellStyle = cell.column.id === 'rank' ? { display: 'none' } : {};
                        return (
                          <td {...cell.getCellProps()} className="pl-3" style={cellStyle}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

const generateSortingIndicator = (column: any) => {
  const centerItem = ['facts_count', 'verified_facts_count', 'is_processed'].includes(column.id);
  return (
    <div className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'} pl-3`}>
      {column.render('Header')}
      {column.disableSortBy ? (
        <></>
      ) : column.isSorted ? (
        <FontAwesomeIcon
          {...column.getSortByToggleProps()}
          icon={column.isSortedDesc ? faArrowDown : faArrowUp}
          className="text-gray-700 mx-2 cursor-pointer"
          style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
        />
      ) : (
        <FontAwesomeIcon
          {...column.getSortByToggleProps()}
          icon={faRightLeft}
          style={{ transform: 'rotate(90deg)', color: 'var(--colors-primary-slate-400, #8897AE)' }}
          className="text-gray-700 mx-2 text-sm cursor-pointer"
        />
      )}
    </div>
  );
};

export default PersonEditor;
