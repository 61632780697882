import { APIBaseChronos } from 'api/hosts';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import 'react-toggle/style.css';
import Swal from 'sweetalert2';
import * as styledComponents from './styles';
import { CheckState } from '../../../../../types';
import useGetFetchConfig from '../../../../../api/useGetFetchConfig';

export type SetCheckedRows = React.Dispatch<React.SetStateAction<Record<string, CheckState>>>;

interface VerifyCellProps {
  editable?: boolean;
  verified_by_email: string;
  verified_date: Date;
  included: boolean;
  event_id: string;
  verified: boolean;
  setCheckedRows: SetCheckedRows;
  editor_id_email: string;
}
const VerifyCell = ({
  verified: currentVerified,
  verified_by_email,
  verified_date,
  included: currentIncluded,
  event_id,
  setCheckedRows,
  editor_id_email,
}: VerifyCellProps) => {
  const [currentVerifiedByEmail, setCurrentVerifiedByEmail] = useState(verified_by_email);
  const [currentVerifiedByDate, setCurrentVerifiedByDate] = useState<Date>(verified_date);
  const [isLoadingUpdateInclude, setLoadingUpdateInclude] = useState<boolean>(false);
  const { getFetchConfig } = useGetFetchConfig();
  const { isFetching: isFetchingUpdateVerified, refetch: refetchUpdateVerified } = useQuery(
    [`updateFieldValue`, event_id, currentVerified, verified_by_email, verified_by_email, editor_id_email],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          verified: true,
        },
      });

      return fetch(`${APIBaseChronos}/client/case/fact/${event_id}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          const newObj = res.updatedOriginalFact.rows[0];
          setCurrentVerifiedByEmail('you');
          setCurrentVerifiedByDate(newObj.verified_date);
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error on update',
            text: 'There was an error on updating the fields. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleVerifyFact = () => {
    setCheckedRows((prev) => ({
      ...prev,
      [event_id]: {
        isIncluded: prev[event_id].isIncluded,
        isVerified: true,
        verified_by_email: 'you',
        verified_date: new Date(Date.now()),
      },
    }));
    refetchUpdateVerified();
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingUpdateInclude(true);
    if (!event.target.checked) {
      setCurrentVerifiedByDate(new Date(Date.now()));
      setCurrentVerifiedByEmail('');
    }
    setCheckedRows((prev) => ({
      ...prev,
      [event_id]: {
        isIncluded: event.target.checked,
        isVerified: !event.target.checked ? false : prev[event_id].isVerified,
        verified_by_email: '',
        verified_date: new Date(Date.now()),
      },
    }));
    const fetchConfig = getFetchConfig({ method: 'PUT', data: { included: event.target.checked } });

    return fetch(`${APIBaseChronos}/client/case/fact/${event_id}`, fetchConfig)
      .then((res) => {
        setLoadingUpdateInclude(false);
        return res.json();
      })
      .catch((err) => {
        setLoadingUpdateInclude(false);
        console.error('Fetch Error: ', err);
        Swal.fire({
          title: 'Error on update',
          text: 'There was an error on updating the fields. Please try again later.',
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <div className="flex flex-row justify-center items-center w-40">
      <styledComponents.ToggleContainer className="mr-3">
        {isLoadingUpdateInclude ? (
          <StageSpinner className="m-auto" color={'#4161FF'} />
        ) : (
          <label>
            <div className="flex flex-col items-center">
              <input
                onChange={handleToggle}
                id={`default-checkbox-${event_id}`}
                type="checkbox"
                checked={currentIncluded}
                className="cursor-pointer w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-xl focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mb-2"
              />
              {editor_id_email && (
                <div className="flex items-center justify-center text-center text-xs">{`Fact last edited by ${editor_id_email}`}</div>
              )}
            </div>
          </label>
        )}
      </styledComponents.ToggleContainer>
      {false && (
        <>
          {currentVerified && currentIncluded ? (
            <div className="flex flex-col items-center justify-center mx-auto">
              <div className="flex flex-col items-center justify-center text-center">
                {currentVerifiedByEmail ? `Verified by ${currentVerifiedByEmail}` : 'Verified'}
              </div>
              {currentVerifiedByDate && (
                <div
                  className="text-xs not-italic font-normal "
                  style={{ color: 'var(--colors-primary-slate-500, #5E718D)' }}
                >
                  {currentVerifiedByDate ? moment(currentVerifiedByDate).format('MMM DD, YYYY') : '-'}
                </div>
              )}
            </div>
          ) : currentIncluded ? (
            <div
              className="cursor-pointer flex justify-center items-center rounded-lg mx-auto not-italic font-bold  w-16 h-10 px-3 shrink-0"
              style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
              onClick={handleVerifyFact}
            >
              {isFetchingUpdateVerified ? <StageSpinner className="m-auto" color={'#4161FF'} /> : 'Verify'}
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </div>
  );
};

export default VerifyCell;
