import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { APIBase } from 'api/hosts';
import { ExploreCompositions } from 'types';
import useGetFetchConfig from '../../api/useGetFetchConfig';

const CompositionsExplorer = () => {
  const navigate = useNavigate();
  const { fetchConfigGET } = useGetFetchConfig();

  const [exploreResponse, setExploreResponse] = useState<ExploreCompositions>({
    data: {
      compositionItems: {
        title: 'My compositions',
        items: [],
      },
    },
  });

  const { isFetching, data } = useQuery(
    'myCompositionsResult',
    () => {
      return fetch(`${APIBase}/client/composer`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (data) {
      setExploreResponse({
        data: {
          searchQuery: {
            title: 'My compositions',
            items: data.compositions,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClickComposeItem = (id: string, caseObjectId: string) => {
    navigate(`/app/generator/${caseObjectId}?compositionId=${id}`);
  };

  return (
    <div className="LotaGrotesque flex flex-col">
      <div className="font-sans flex flex-col md:flex-row items-start justify-start text-white h-screen">
        <div className="flex flex-col items-start justify-start w-full h-full">
          <div className={`flex flex-row w-full flex-wrap`}>
            {Object.keys(exploreResponse?.data)?.map((responseKey, idxSection) => {
              return (
                <div key={idxSection} className="flex flex-wrap flex-row items-start justify-start w-full pl-5">
                  {!isFetching && exploreResponse.data[responseKey] && (
                    <div className="text-4xl text-gray-900 mb-4 w-full px-10 font-semibold mt-10">
                      {exploreResponse.data[responseKey]?.title}
                    </div>
                  )}
                  {!isFetching && !exploreResponse.data[responseKey]?.items?.length && (
                    <div className="text-xl text-gray-900 mb-4 w-full px-10 ml-0.5">No compositions found</div>
                  )}
                  {isFetching && (
                    <div className="w-full h-full flex items-center justify-center">
                      <StageSpinner className="m-auto" color={'#081D57'} />
                    </div>
                  )}
                  {!isFetching &&
                    exploreResponse.data[responseKey]?.items.map((item, idxItem) => {
                      return (
                        <div key={idxItem} className="w-1/2 shrink-0 p-3">
                          <div className="bg-white w-full rounded-lg p-8 flex items-start justify-start flex-col">
                            <div
                              className="font-semibold text-xl flex items-center text-gray-900 self-stretch mt-4 cursor-pointer underline"
                              onClick={() => handleClickComposeItem(item._id, item.caseObjectId)}
                              style={{ color: '#0865E0' }}
                            >
                              {item.subtitle}
                            </div>
                            <div className="font-semibold text-sm leading-5 flex items-center text-green-700 mt-2">
                              {moment(item.dateComposeGenerated).format('DD MMM YYYY')}
                            </div>
                            {item.compose_sections.length && (
                              <p className="font-normal text-sm text-gray-500 leading-5 mt-3 line-clamp-5">
                                {item.compose_sections[0].section_content}
                              </p>
                            )}
                            <button
                              className="cursor-pointer flex justify-center items-center h-8 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 font-semibold text-sm text-white mt-5 w-32 ml-auto"
                              onClick={() => handleClickComposeItem(item._id, item.caseObjectId)}
                            >
                              See Composition
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompositionsExplorer;
