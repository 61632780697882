import React, { useState, createRef, useEffect } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import * as styledComponents from './styles';
import { MODES } from 'constants/modes';

interface EditorContentProps {
  content: string;
  setHTMLCopy: (val: String) => void;
  menuOpen: boolean;
  setMenuOpen: (val: boolean) => void;
  setPageYPosition: (val: number) => void;
  scrollTop: number;
  setCurrentQuillEditor: (val: Quill | undefined) => void;
  setMode: (val: string) => void;
  setCommentToShow: (val: string) => void;
  setViewType?: (val: string) => void;
  setSelectedStartIndex: (val: number) => void;
  setSelectedEndIndex: (val: number) => void;
  tourClassname?: string;
  viewType?: string;
}

const EditorContent: React.FC<EditorContentProps> = ({
  content,
  setHTMLCopy,
  setMenuOpen,
  setPageYPosition,
  scrollTop,
  setCurrentQuillEditor,
  setMode,
  setCommentToShow,
  setSelectedStartIndex,
  setSelectedEndIndex,
  tourClassname = '',
  setViewType,
  viewType,
}) => {
  const [value, setValue] = useState(content);
  const quillRef = createRef<ReactQuill>();
  const [quillEditor, setQuillEditor] = useState<Quill>();

  const updateSelection = (startIndex: number, endIndex: number) => {
    setSelectedStartIndex(startIndex);
    setSelectedEndIndex(endIndex);
  };

  const handleOnChangeSelection = (range: any, source: any, editor: any) => {
    if (range) {
      updateSelection(range.index, range.index + range.length);
    }
  };

  useEffect(()=>{
    setValue(content);
  }, [content])
  const handleChangeValue = (content: any) => {
    setValue(content);
    setHTMLCopy(content);
  };

  const scrollIntoReference = (reference: string) => {
    const refNumber = parseInt(reference.split('_')[1]);
    const paragrah_id = `paragraph_id_${refNumber}.`;
    const elem = document.getElementById(paragrah_id);
    elem?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>, quillEditor: Quill | undefined) => {
    e.preventDefault();
    setMenuOpen(true);
    setPageYPosition(e.pageY + scrollTop);
    setCurrentQuillEditor(quillEditor);
    const elem = document.elementFromPoint(e.clientX, e.clientY);
    const reference = elem?.getAttribute('href');
    if (reference && setViewType) {
      if (viewType === 'SIDE_TO_SIDE') {
        scrollIntoReference(reference);
      } else {
        setViewType('SIDE_TO_SIDE');
        setTimeout(() => {
          scrollIntoReference(reference);
        }, 500);
      }

      return;
    }
    const comment = elem?.parentElement?.getAttribute('href');
    if (comment) {
      setMenuOpen(true);
      setMode(MODES.VIEW_COMMENT.id);
      setCommentToShow(comment.split('_')[2]);
      setPageYPosition(e.pageY + scrollTop);
      if (setViewType) {
        setViewType('NORMAL');
      }
    } else {
      setMode(MODES.MENU.id);
    }
  };

  useEffect(() => {
    setQuillEditor(quillRef.current?.getEditor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quillRef.current]);

  return (
    <styledComponents.EditorContainer
      onClick={(e: React.MouseEvent<HTMLDivElement>) => handleOnClick(e, quillEditor)}
      onContextMenu={(e: React.MouseEvent<HTMLDivElement>) => handleOnClick(e, quillEditor)}
      className={tourClassname}
    >
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={handleChangeValue}
        onChangeSelection={handleOnChangeSelection}
        modules={{}}
      />
    </styledComponents.EditorContainer>
  );
};

export default EditorContent;
