import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Background from 'assets/bg-explorer.png';
import moment from 'moment';
import { APIBase } from 'api/hosts';
import { UserContext } from 'Contexts/User';
import { HotjarContext } from 'Contexts/Hotjar';
import { Explore } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFilter } from '@fortawesome/free-solid-svg-icons';
import Joyride, { Step, STATUS, CallBackProps } from 'react-joyride';
import { tourStyles } from 'constants/tour';
import { TOUR_STEPS, TOUR_KEYS } from 'tour';
import Select, { MultiValue } from 'react-select';
import { SORT_BY } from 'constants/sorting_options';
import { reactSelectStylesExplorer, reactSelectStylesExplorerMulti } from 'constants/styles';
import { MyOptionType } from 'types';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import 'react-datepicker/dist/react-datepicker.css';
import useGetFetchConfig from '../../api/useGetFetchConfig';

interface ExplorerProps {
  privateProjects?: Boolean;
}

const Explorer: React.FC<ExplorerProps> = ({ privateProjects }) => {
  const [searchParams] = useSearchParams();
  const searchTextQuery = searchParams.get('searchText');
  const searchTypeQuery = searchParams.get('searchType');
  const caseNameQuery = searchParams.get('caseName');
  const citationQuery = searchParams.get('citation');
  const catchwordsQuery = searchParams.get('catchwords');
  const dateFromQuery = searchParams.get('dateFrom');
  const dateToQuery = searchParams.get('dateTo');
  const courtQuery = searchParams.get('court');
  const judgeQuery = searchParams.get('judge');
  const sortByQuery = searchParams.get('sortBy') || 'score_desc';
  const pageQuery = parseInt(searchParams.get('page') || '1') || 1;
  const [searchText, setSearchText] = useState(searchTextQuery || '');
  const [nPages, setNPages] = useState(0);
  const [court, setCourt] = useState<MultiValue<MyOptionType>>([]);
  const [courtOptions, setCourtOptions] = useState<MyOptionType[]>([]);
  const [judge, setJudge] = useState<string>('');
  const [caseName, setCaseName] = useState<string>('');
  const [citation, setCitation] = useState<string>('');
  const [catchwords, setCatchwords] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [message, setMessage] = useState('');
  const [backupLink, setBackupLink] = useState('');
  const { fetchConfigGET } = useGetFetchConfig();

  const [advancedFiltersActive, setAdvancedFiltersActive] = useState<Boolean>(false);

  const sortByOptionFromQuery =
    sortByQuery && SORT_BY[sortByQuery.toUpperCase()] ? SORT_BY[sortByQuery.toUpperCase()] : null;

  const navigate = useNavigate();
  const { hotjar } = useContext(HotjarContext);
  const { markTourKeyDone } = useContext(UserContext);
  const [exploreResponse, setExploreResponse] = useState<Explore>({
    data: {
      initialData: {
        title: '',
        items: [],
        totalCount: 0,
      },
    },
  });

  const {
    isFetching: isLoading,
    data,
    refetch,
  } = useQuery(
    'exploreResult',
    () => {
      const cleanSearchText = searchTextQuery?.replace(/\s+/g, ' ').trim() || '';
      if (privateProjects) {
        return fetch(
          `${APIBase}/client/summary?search=${cleanSearchText}&page=${pageQuery}&mode=private`,
          fetchConfigGET,
        ).then((res) => {
          return res.json();
        });
      } else if (searchTypeQuery) {
        return fetch(`${APIBase}/client/summary?type=${searchTypeQuery}&page=${pageQuery}`, fetchConfigGET).then(
          (res) => {
            return res.json();
          },
        );
      } else {
        return fetch(
          `${APIBase}/client/summary?search=${cleanSearchText}&page=${pageQuery}}&caseName=${caseNameQuery}&citation=${citationQuery}&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&court=${courtQuery}&judge=${judgeQuery}&sortBy=${sortByQuery}`,
          fetchConfigGET,
        ).then((res) => {
          return res.json();
        });
      }
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  useEffect(() => {
    setSearchText(searchTextQuery || '');
    if (privateProjects || searchTypeQuery || (searchTextQuery && pageQuery)) {
      refetch();
    } else {
      navigate(`/app/explore?searchType=recommended&page=1`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTextQuery,
    searchTypeQuery,
    pageQuery,
    privateProjects,
    caseNameQuery,
    citationQuery,
    catchwordsQuery,
    dateFromQuery,
    dateToQuery,
    courtQuery,
    judgeQuery,
    sortByQuery,
  ]);

  useEffect(() => {
    if (data) {
      if (searchTypeQuery === 'recommended' || searchTypeQuery === 'recentCases') {
        setExploreResponse({
          data: {
            initialData: {
              title: '',
              items: data.items,
              totalCount: data.totalCount,
            },
          },
        });
      } else {
        if (privateProjects) {
          setExploreResponse({
            data: {
              myCases: {
                title: 'My Cases',
                items: data.items,
                totalCount: data.totalCount,
              },
            },
          });
        } else {
          setExploreResponse({
            data: {
              searchQuery: {
                title: 'Search Results',
                items: data.items,
                totalCount: data.totalCount,
              },
            },
          });
        }

        if (!privateProjects) {
          if (data?.metadata?.date_from) {
            setDateFrom(new Date(data.metadata.date_from));
          }
          if (data?.metadata?.date_to) {
            setDateTo(new Date(data.metadata.date_to));
          }
          if (data?.metadata?.court?.all_options?.length) {
            setCourtOptions(data.metadata.court.all_options.map((court: string) => ({ value: court, label: court })));
          }
          if (data?.metadata?.court?.active_options?.length) {
            setCourt(data.metadata.court.active_options.map((court: string) => ({ value: court, label: court })));
          }
          setMessage(data.message || '');
          setBackupLink(data.backupLink || '');
        }
      }

      setNPages(Math.ceil(data.totalCount / 20));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const goToSearch = () => {
    if (advancedFiltersActive) {
      const courtValues = court.map((court) => court?.value).join(',') || 'undefined';
      navigate(
        `/app/explore?searchText=${searchText}&page=1&caseName=${caseName}&citation=${citation}&catchwords=${catchwords}&dateFrom=${dateFrom}&dateTo=${dateTo}&court=${courtValues}&judge=${judge}&sortBy=${sortByQuery}`,
      );
    } else {
      navigate(`/app/explore?searchText=${searchText}&page=1&sortBy=${sortByQuery}`);
    }
  };

  const showMessageNoInputText = () => {
    Swal.fire({
      title: 'Search text input required',
      text: 'Search text input is required to perform a search',
      showConfirmButton: false,
      background: '#FFFFFF',
      color: '#081D57',
    });
  };
  const handleClickSearch = () => {
    hotjar.event('search-click');
    if (!searchText) {
      showMessageNoInputText();
    } else {
      goToSearch();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      hotjar.event('search-click');
      if (!searchText) {
        showMessageNoInputText();
      } else {
        goToSearch();
      }
    }
  };

  const handleClickSummaryItem = (id: string) => {
    hotjar.event('case-click');
    window.open(`/app/editor/${id}`, '_blank');
  };

  // const handleGoToInitialPage = () => {
  //   if (pageQuery > 1) {
  //     if (privateProjects) {
  //       navigate(`/app/my-projects?searchText=${searchText}&page=${1}`);
  //     } else {
  //       navigate(`/app/explore?searchText=${searchText}&page=${1}`);
  //     }
  //   }
  // };

  const handleGoToPreviousPage = () => {
    if (pageQuery > 1) {
      if (privateProjects) {
        navigate(`/app/my-projects?searchText=${searchText}&page=${pageQuery - 1}`);
      } else if (searchTypeQuery) {
        navigate(`/app/explore?searchType=${searchTypeQuery}&page=${pageQuery - 1}`);
      } else if (advancedFiltersActive) {
        navigate(
          `/app/explore?searchText=${searchTextQuery}&page=${
            pageQuery - 1
          }&caseName=${caseNameQuery}&citation=${citationQuery}&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&court=${courtQuery}&judge=${judgeQuery}&sortBy=${sortByQuery}`,
        );
      } else {
        navigate(`/app/explore?searchText=${searchText}&page=${pageQuery - 1}`);
      }
    }
  };
  const handleGoToNextPage = () => {
    if (pageQuery < nPages) {
      if (privateProjects) {
        navigate(`/app/my-projects?searchText=${searchText}&page=${pageQuery + 1}`);
      } else if (searchTypeQuery) {
        navigate(`/app/explore?searchType=${searchTypeQuery}&page=${pageQuery + 1}`);
      } else if (advancedFiltersActive) {
        navigate(
          `/app/explore?searchText=${searchTextQuery}&page=${
            pageQuery + 1
          }&caseName=${caseNameQuery}&citation=${citationQuery}&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&court=${courtQuery}&judge=${judgeQuery}&sortBy=${sortByQuery}`,
        );
      } else {
        navigate(`/app/explore?searchText=${searchText}&page=${pageQuery + 1}`);
      }
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as 'finished' | 'skipped')) {
      markTourKeyDone(TOUR_KEYS.EXPLORER_SECTION);
    }
  };

  // const handleGoToEndPage = () => {
  //   if (pageQuery < nPages) {
  //     if (privateProjects) {
  //       navigate(`/app/my-projects?searchText=${searchText}&page=${nPages}`);
  //     } else {
  //       navigate(`/app/explore?searchText=${searchText}&page=${nPages}`);
  //     }
  //   }
  // };

  const enabledTour = !!(
    exploreResponse?.data &&
    Object.values(exploreResponse?.data)[0]?.items?.length &&
    localStorage.getItem(TOUR_KEYS.EXPLORER_SECTION) !== 'true'
  );

  const goToRecentCases = () => {
    navigate(`/app/explore?searchType=recentCases&page=1`);
  };

  const goToRecommended = () => {
    navigate(`/app/explore?searchType=recommended&page=1`);
  };

  const handleChangeCourt = (val: MultiValue<MyOptionType>) => {
    setCourt(val);
  };

  const handleChangeJudge = (val: string) => {
    setJudge(val);
  };

  const handleChangeSortBy = (val: MyOptionType) => {
    if (advancedFiltersActive) {
      navigate(
        `/app/explore?searchText=${searchTextQuery}&page=1&caseName=${caseNameQuery}&citation=${citationQuery}&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&court=${courtQuery}&judge=${judgeQuery}&sortBy=${val?.value}`,
      );
    } else {
      navigate(`/app/explore?searchText=${searchTextQuery}&page=1&sortBy=${val?.value}`);
    }
  };
  const clearFilters = () => {
    setCourt([]);
    setJudge('');
    setCaseName('');
    setCitation('');
    setCatchwords('');
    setDateFrom(undefined);
    setDateTo(undefined);
  };

  return (
    <div className="LotaGrotesque flex flex-col">
      {enabledTour && (
        <Joyride
          steps={TOUR_STEPS.EXPLORER_SECTION as Step[]}
          continuous
          styles={tourStyles}
          callback={handleJoyrideCallback}
          run
          disableScrolling
        />
      )}{' '}
      <div className="font-sans flex flex-col md:flex-row items-start justify-start text-white h-screen">
        <div className="flex flex-col items-start justify-start w-full h-full">
          {!privateProjects && (
            <div
              className="w-full mb-5 bg-cover bg-center py-20 px-10 bg-white"
              style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}
            >
              <div className="LotaGrotesque font-bold text-4xl text-black mb-4">Search</div>
              <div className="flex flex-row w-full items-center">
                <div className="w-full flex flex-row bg-white rounded-lg p-2" style={{ border: '1px solid #ECECEC' }}>
                  <input
                    className="searchBar h-12 pl-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
                    placeholder={`Input a natural language query e.g. "child maintenance claims in England and Wales" or a neutral citation number e.g. "[2023] EWHC 25 (civ)"`}
                    onChange={handleChangeSearchInput}
                    value={searchText}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    className="cursor-pointer flex flex-row justify-center items-center w-40 h-12 rounded-lg font-bold bg-white text-gray-700"
                    style={{ border: '2px solid #afafaf', boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)' }}
                    onClick={() => {
                      setAdvancedFiltersActive(!advancedFiltersActive);
                    }}
                  >
                    {!advancedFiltersActive && <FontAwesomeIcon icon={faFilter} className="text-gray-700 mr-2" />}
                    {advancedFiltersActive ? 'Hide Filters' : 'Advanced'}
                  </button>
                </div>
                <button
                  className="searchButton cursor-pointer flex flex-row justify-center items-center h-16 w-56 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 shrink-0 ml-2"
                  onClick={handleClickSearch}
                >
                  {isLoading ? <StageSpinner className="m-auto" color={'white'} /> : 'Search'}
                </button>
              </div>
              {advancedFiltersActive && (
                <div className="w-full flex flex-row mt-10">
                  <div className="w-full flex flex-col items-start justify-start mt-3 mr-3">
                    <div className="flex flex-row items-center mb-3  w-full">
                      <div className="w-48 text-black flex flex-row items-end justify-end pr-5  font-semibold">
                        Case name
                      </div>
                      <input
                        placeholder="Enter one or more words in the case name"
                        className="mr-3 outline-none w-full bg-white rounded-md h-12 px-4 text-black"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCaseName(e.target.value)}
                        value={caseName}
                      ></input>
                    </div>
                    <div className="flex flex-row items-center mb-3  w-full">
                      <div className="w-48 text-black flex flex-row items-end justify-end pr-5  font-semibold">
                        Citation/Reference
                      </div>
                      <input
                        placeholder="Enter a Neutral Citation or a publication reference"
                        className="mr-3 outline-none w-full bg-white rounded-md h-12 px-4 text-black"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCitation(e.target.value)}
                        value={citation}
                      ></input>
                    </div>
                    <div className="flex flex-row items-center mb-3 w-full">
                      <div className="w-48 text-black flex flex-row items-end justify-end pr-5  font-semibold">
                        Catchwords
                      </div>
                      <input
                        placeholder="Enter subject matter keyword or phrases"
                        className="mr-3 outline-none w-full bg-white rounded-md h-12 px-4 text-black"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCatchwords(e.target.value)}
                        value={catchwords}
                      ></input>
                    </div>
                  </div>
                  <div className="shrink-0 flex flex-col items-start justify-start mt-3">
                    <div className="flex flex-row items-center mb-3  w-full">
                      <div className="w-28 text-white flex flex-row items-end justify-end pr-5  font-semibold">
                        From
                      </div>
                      <div className="w-96 flex flex-row">
                        <div className="mr-3 outline-none w-48 bg-white rounded-lg h-12" style={{ width: '130px' }}>
                          <DatePicker
                            selected={dateFrom}
                            onChange={(date: Date) => setDateFrom(date)}
                            placeholderText="From"
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                        <div className="w-28 h-12 text-white flex flex-row items-center justify-end pr-5 font-semibold">
                          To
                        </div>
                        <div className="outline-none w-48 bg-white rounded-md h-12" style={{ width: '130px' }}>
                          <DatePicker
                            selected={dateTo}
                            onChange={(date: Date) => setDateTo(date)}
                            placeholderText="To"
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center mb-3  w-full">
                      <div className="w-28 text-white flex flex-row items-end justify-end pr-5  font-semibold">
                        Judge
                      </div>
                      <input
                        placeholder="Judge"
                        className="w-96 outline-none bg-white rounded-md h-12 px-4 text-black"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeJudge(e.target.value)}
                        value={judge}
                      ></input>
                    </div>
                    <div className="flex flex-row items-center mb-3  w-full">
                      <div className="w-28 text-white flex flex-row items-end justify-end pr-5  font-semibold">
                        Court
                      </div>
                      <Select
                        options={courtOptions}
                        className="outline-none w-96 bg-white rounded-md mt-3"
                        styles={reactSelectStylesExplorerMulti}
                        onChange={handleChangeCourt}
                        value={court}
                        placeholder={'Court'}
                        isMulti
                      />
                    </div>
                    <button
                      className="cursor-pointer ml-auto flex flex-row justify-center items-center w-40 h-12 rounded-lg font-semibold bg-white text-gray-700"
                      style={{ border: '1px solid #D0D5DD', boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)' }}
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={`flex flex-row w-full flex-wrap ${privateProjects ? 'mt-10' : ''}`}>
            {(searchTypeQuery === 'recommended' || searchTypeQuery === 'recentCases') && (
              <div className="flex flex-row font-semibold text-white pl-10">
                <div
                  className={`flex flex-row text-xl justify-center items-center h-16 w-56  rounded-l-lg hover:bg-blue-500 disabled:bg-gray-500 shrink-0 px-10 font-semibold  cursor-pointer ${
                    searchTypeQuery === 'recommended' ? 'bg-blue-600' : 'bg-blue-300'
                  }`}
                  onClick={goToRecommended}
                >
                  Recommended
                </div>{' '}
                <div
                  className={`flex flex-row  text-xl justify-center items-center h-16 w-56  rounded-r-lg hover:bg-blue-500 disabled:bg-gray-500 shrink-0 px-10 font-semibold  cursor-pointer ${
                    searchTypeQuery === 'recentCases' ? 'bg-blue-600' : 'bg-blue-300'
                  }`}
                  onClick={goToRecentCases}
                >
                  Recent Cases
                </div>
              </div>
            )}
            {Object.keys(exploreResponse?.data).map((responseKey, idxSection) => {
              const isSearch = responseKey === 'searchQuery';
              return (
                <div key={idxSection} className="flex flex-wrap flex-row items-start justify-start w-full pl-5">
                  {!isLoading && exploreResponse?.data[responseKey] && (
                    <div className="mb-4 w-full px-10 flex flex-row">
                      <div className="text-4xl text-gray-900 font-semibold">
                        {exploreResponse?.data[responseKey]?.title}
                      </div>
                      {isSearch && exploreResponse?.data[responseKey]?.items?.length && (
                        <Select
                          options={Object.values(SORT_BY)}
                          className="outline-none bg-white rounded-md ml-auto w-56"
                          styles={reactSelectStylesExplorer}
                          onChange={handleChangeSortBy}
                          value={sortByOptionFromQuery}
                          placeholder={'Sort by'}
                        />
                      )}
                    </div>
                  )}
                  {!isLoading && !exploreResponse?.data[responseKey]?.items?.length && (
                    <div className="text-xl text-gray-900 mb-4 w-full px-10 ml-0.5">
                      {message ? `${message} ` : 'No items found'}
                      {backupLink && (
                        <>
                          <a
                            style={{ color: '#0865E0', textDecoration: 'underline' }}
                            href={backupLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            here
                          </a>
                          .
                        </>
                      )}
                    </div>
                  )}
                  {isLoading && (
                    <div className="w-full h-full flex items-center justify-center">
                      <StageSpinner className="m-auto" color={'#081D57'} />
                    </div>
                  )}
                  {!isLoading &&
                    exploreResponse?.data[responseKey]?.items?.map((item, idxItem) => {
                      return (
                        <div key={idxItem} className="w-1/2 shrink-0 p-3">
                          <div className="bg-white w-full rounded-lg p-8 flex items-start justify-start flex-col">
                            <div className="flex items-start justify-start">
                              {item.categorySubjectMatterNew && (
                                <div className="font-normal text-xs flex items-center text-blue-600 h-5 bg-indigo-100 rounded mr-3 px-2">
                                  {item.categorySubjectMatterNew}
                                </div>
                              )}
                              {item.categoryLegalTopicNew && (
                                <div className="font-normal text-xs flex items-center text-blue-600 h-5 bg-yellow-100 rounded mr-3 px-2">
                                  {item.categoryLegalTopicNew}
                                </div>
                              )}
                            </div>
                            <div
                              className="font-semibold text-xl flex items-center text-gray-900 self-stretch mt-4 cursor-pointer underline"
                              onClick={() =>
                                ((item.is_parsed && !item.is_reporting_restrictions) || privateProjects) &&
                                handleClickSummaryItem(item._id)
                              }
                              style={{ color: '#0865E0' }}
                            >
                              {item.title}
                            </div>
                            <div className="font-semibold text-sm leading-5 flex items-center text-green-700 mt-2">
                              {moment(item.case_date).format('DD MMM YYYY')}
                              {item.neutral_citation ? ` · ${item.neutral_citation}` : ''}
                            </div>
                            <div className="font-semibold text-sm text-gray-500 leading-5 flex items-center italic mt-2">
                              {privateProjects &&
                                `Last edit: ${moment(item.last_update_date).format('DD MMM h:mm:ss a')}`}
                            </div>
                            {item.summary_sections?.length && (
                              <div className="font-normal text-sm text-gray-500 leading-5 mt-3">
                                {item.is_summary_complete &&
                                  item.summary_sections?.filter(
                                    (section) => section?.section_title?.toLowerCase() === 'summary',
                                  )[0]?.section_content}
                                {item.is_reporting_restrictions ? (
                                  <div className="text-red-500">Reporting Restriction</div>
                                ) : !item.is_parsed ? (
                                  'Case out of scope'
                                ) : !item.is_summary_complete ? (
                                  'Ready to generate summary'
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                            {item.is_parsed && !item.is_reporting_restrictions && (
                              <button
                                className={`${
                                  idxItem === 0 && idxSection === 0 ? 'exploreButton' : ''
                                } cursor-pointer flex justify-center items-center min-w-20 px-4 h-8 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 font-semibold text-sm text-white mt-5`}
                                onClick={() => handleClickSummaryItem(item._id)}
                              >
                                {item.is_summary_complete ? 'Explore' : 'Generate Summary'}
                              </button>
                            )}
                            {item.alert && (
                              <div
                                className="flex flex-row items-start min-h-8 bg-yellow-200 py-2 px-4 rounded-full font-normal text-sm leading-4 text-gray-800 mt-6"
                                style={{ gap: '8px' }}
                              >
                                💡 {item.alert}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {(isSearch || privateProjects || searchTypeQuery) && nPages > 1 && (
                    <div className="w-full flex flex-row items-center justify-center text-black my-10">
                      {/* <button
                        className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                        disabled={pageQuery <= 1}
                        onClick={handleGoToInitialPage}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </button> */}
                      <button
                        className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                        disabled={pageQuery <= 1}
                        onClick={handleGoToPreviousPage}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </button>
                      <button className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer">
                        {pageQuery}
                      </button>
                      <button
                        className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                        disabled={pageQuery >= nPages}
                        onClick={handleGoToNextPage}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                      {/* <button
                        className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                        disabled={pageQuery >= nPages}
                        onClick={handleGoToEndPage}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button> */}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explorer;
