import { KEY_VALUE_TYPE } from 'types';

export const IMPORTANCY_OPTIONS: KEY_VALUE_TYPE = {
  'Yes': {
    value: 'Yes',
    label: 'Yes',
  },
  'No': {
    value: 'No',
    label: 'No',
  },
  'May be relevant': {
    value: 'May be relevant',
    label: 'May be relevant',
  },
};

export const AGREED_OPTIONS: KEY_VALUE_TYPE = {
  Yes: {
    value: 'Yes',
    label: 'Yes',
  },
  "Potentially not agreed": {
    value: 'Potentially not agreed',
    label: 'Potentially not agreed',
  },
  No: {
    value: 'No',
    label: 'No',
  },
};

export const PERSONS_IMPORTANT_OPTIONS: KEY_VALUE_TYPE = {
  YES: {
    value: 'YES',
    label: 'YES',
  },
  NO: {
    value: 'NO',
    label: 'NO',
  },
};
