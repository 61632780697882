export const getSettingsHost = () => {
  const environment = process.env.REACT_APP_WEXLER_ENV;
  switch (environment) {
    case 'development':
      return 'https://auth.dev.app.wexler.ai/account';
    case 'production':
      return 'https://auth.app.wexler.ai/account';
    case 'local':
      return 'https://64007810.propelauthtest.com/account';
    default:
      return 'https://64007810.propelauthtest.com/account';
  }
};

export const settingsHost = getSettingsHost();
