import { UserObject } from '../types/user';

interface UserListInterface {
  users: UserObject[];
  onRemove: (userId: string) => void;
}

const UserList = ({ users, onRemove }: UserListInterface) => {
  return (
    <div className="w-full flex flex-col mt-6">
      {users?.map((user: any) => {
        return (
          <div className={`bg-white mb-3 p-3 w-full rounded-lg  border-red-500`} key={user.user_id}>
            <div className="w-full flex items-center justify-between">
              <div className={`text-base not-italic font-medium w-20 text-black   `}>{user.user_email}</div>
              <div
                className={`flex justify-center items-center px-2 py-3 rounded-lg ml-auto w-24 not-italic shrink-0 font-bold leading-6 cursor-pointer bg-blue-100 text-blue-600`}
                onClick={() => onRemove(user.user_id)}
              >
                Remove
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserList;
