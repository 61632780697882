import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import { APIBase } from 'api/hosts';
import { advancedInterestsOptions } from 'constants/interests';
import SubInterests from './SubInterests';
import { useNavigate } from 'react-router-dom';
import useGetFetchConfig from '../../api/useGetFetchConfig';

type SelectedInterestsType = {
  [key: string]: string[];
};
const Interests: React.FC = () => {
  const [detailedSelection, setDetailedSelection] = useState(false);
  const [currentInterests, setcurrentInterests] = useState<SelectedInterestsType>({});
  const [selectedInterests, setSelectedInterests] = useState<SelectedInterestsType>({});
  const [dataToUpdate, setDataToUpdate] = useState(false);
  const navigate = useNavigate();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const { isLoading, data } = useQuery(
    'myData',
    () => {
      return fetch(`${APIBase}/client/user/interests`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      cacheTime: 0,
    },
  );

  const { isLoading: isLoadingUpdate, data: dataUpdated } = useQuery(
    ['myDataUpdated', selectedInterests, currentInterests],
    () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { interests: selectedInterests } });

      return fetch(`${APIBase}/client/user/interests`, fetchConfig).then((res) => {
        return res.json();
      });
    },
    {
      enabled: dataToUpdate,
      cacheTime: 0,
    },
  );

  const handleClickInterest = (option: string) => {
    if (currentInterests && Object.keys(currentInterests).includes(option)) {
      const newSelectedInterestsKeys = { ...currentInterests };
      delete newSelectedInterestsKeys[option];
      setSelectedInterests(newSelectedInterestsKeys);
      setDataToUpdate(true);
    } else {
      const newSelectedInterestsKeys = { ...currentInterests };
      newSelectedInterestsKeys[option] = [];
      setSelectedInterests(newSelectedInterestsKeys);
      setDataToUpdate(true);
    }
  };

  const handleClickNext = () => {
    if (detailedSelection) {
      navigate('app/explorer');
    } else {
      setDetailedSelection(true);
    }
  };

  const handleClickPrev = () => {
    setDetailedSelection(false);
  };

  useEffect(() => {
    if (data) {
      setcurrentInterests(data.interests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (dataUpdated) {
      setcurrentInterests(dataUpdated.interests.interests);
      setDataToUpdate(false);
    }
  }, [dataUpdated]);

  const updateSubInterests = (keyInterest: string, newSubInterests: string[]) => {
    const newSelectedInterestsKeys = { ...currentInterests };
    newSelectedInterestsKeys[keyInterest] = newSubInterests;
    setSelectedInterests(newSelectedInterestsKeys);
    setDataToUpdate(true);
  };

  return (
    <div className="LotaGrotesque flex flex-col">
      <div className="font-sans flex flex-col md:flex-row items-start justify-start text-white h-screen">
        <div className="flex flex-col items-start justify-start w-full h-full text-black">
          <div className="w-full h-full pb-5 bg-cover bg-center py-20 px-28 bg-white">
            <div className="not-italic font-semibold text-4xl text-gray-900">
              {detailedSelection
                ? 'Select the subject matters within those legal topics that interest you:'
                : 'Select legal topics that interest you:'}
            </div>
            {isLoading ? (
              <StageSpinner className="m-auto" color={'#081D57'} />
            ) : (
              <div className="w-full max-w flex flex-row flex-wrap mt-8" style={{ maxWidth: '653px' }}>
                {Object.keys(advancedInterestsOptions).map((option, idx) => {
                  const currentlySelected = currentInterests ? Object.keys(currentInterests).includes(option) : false;
                  const selectedInterestsKeys = selectedInterests ? Object.keys(selectedInterests) : [];
                  const currentInterestsKeys = currentInterests ? Object.keys(currentInterests) : [];

                  const toChangeSelection =
                    isLoadingUpdate &&
                    ((selectedInterestsKeys.includes(option) && !currentInterestsKeys.includes(option)) ||
                      (currentInterestsKeys.includes(option) && !selectedInterestsKeys.includes(option)));
                  return !detailedSelection ? (
                    <div
                      key={idx}
                      className="flex flex-col justify-center items-center h-12 bg-white rounded-full py-2 not-italic font-semibold text-sm px-12 mr-4 mb-4 cursor-pointer"
                      style={{
                        border: '1px solid #8C8D96',
                        background: currentlySelected ? '#DCEAFE' : '#FFFFFF',
                        color: currentlySelected ? '#081D57' : '#585858',
                        borderWidth: currentlySelected ? '0px' : '1px',
                      }}
                      onClick={() => handleClickInterest(option)}
                    >
                      {toChangeSelection ? <StageSpinner className="m-auto" color={'#081D57'} /> : option}
                    </div>
                  ) : currentlySelected ? (
                    <div key={idx}>
                      <div className="font-semibold">{option}</div>
                      <SubInterests
                        availableOptions={advancedInterestsOptions[option]}
                        interestKey={option}
                        currentInterests={currentInterests[option] || []}
                        updateSubInterests={updateSubInterests}
                        isLoadingUpdate={isLoadingUpdate}
                      />
                    </div>
                  ) : (
                    <></>
                  );
                })}
                <div className="w-full mt-6" />
                {detailedSelection && (
                  <div
                    className="flex flex-col justify-center items-center h-12 rounded-full py-2 not-italic font-semibold text-sm px-12 mb-4 cursor-pointer mr-auto w-40"
                    style={{
                      background: '#081D57',
                      color: 'white',
                    }}
                    onClick={() => handleClickPrev()}
                  >
                    Previous
                  </div>
                )}
                <div
                  className="flex flex-col justify-center items-center h-12 rounded-full py-2 not-italic font-semibold text-sm px-12 mr-4 mb-4 cursor-pointer ml-auto w-40"
                  style={{
                    background: '#081D57',
                    color: 'white',
                  }}
                  onClick={() => handleClickNext()}
                >
                  {detailedSelection ? 'Finish' : 'Next'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interests;
