import { Viewer } from '@react-pdf-viewer/core';
import { HighlightArea, highlightPlugin, RenderHighlightsProps, Trigger } from '@react-pdf-viewer/highlight';

import '@react-pdf-viewer/core/lib/styles/index.css';

interface PDFViewerProps {
  fileUrl: string;
  initialPage: number;
  highlights: HighlightArea[];
}

const PDFViewer = ({ fileUrl, initialPage, highlights = [] }: PDFViewerProps) => {
  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        {highlights
          .filter((area) => {
            return area.pageIndex === props.pageIndex;
          })
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={Object.assign(
                {},
                {
                  background: '#FFFF00',
                  opacity: 0.4,
                },
                props.getCssProperties(area, props.rotation),
              )}
            />
          ))}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  return (
    <Viewer
      fileUrl={fileUrl}
      plugins={[highlightPluginInstance]}
      initialPage={initialPage - 1} // package 0 indexes pages
    />
  );
};

export default PDFViewer;
