import { KEY_VALUE_TYPE } from 'types';

export const PIPELINE_STATUS: KEY_VALUE_TYPE = {
  document_encryption: {
    value: 'document_encryption',
    label: 'Document encryption',
  },
  text_extraction: {
    value: 'text_extraction',
    label: 'Extracting text',
  },
  anonymisation: {
    value: 'anonymisation',
    label: 'Anonymising',
  },
  facts_per_document: {
    value: 'facts_per_document',
    label: 'Extracting facts',
  },
  success_facts_per_document: {
    value: 'success_facts_per_document',
    label: 'Documents processed successfully',
  },
  building_chronology: {
    value: 'building_chronology',
    label: 'Building chronology',
  },
  success: {
    value: 'success',
    label: 'Success',
  },
  failed: {
    value: 'failed',
    label: 'Failed',
  },
  complete: {
    value: 'complete',
    label: 'complete',
  },
};

export const PIPELINE_PROCESSING_STATUS = [
  PIPELINE_STATUS.document_encryption.value,
  PIPELINE_STATUS.text_extraction.value,
  PIPELINE_STATUS.anonymisation.value,
  PIPELINE_STATUS.facts_per_document.value,
  PIPELINE_STATUS.building_chronology.value,
];

export const PIPELINE_FREEZE_LOAD_STATUS = [
  PIPELINE_STATUS.success_facts_per_document,
  PIPELINE_STATUS.success.value,
  PIPELINE_STATUS.failed.value,
  PIPELINE_STATUS.complete.value,
];
