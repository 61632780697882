export const TOUR_STEPS = {
  ACTIONS_SECTION: [
    {
      target: '.menuView',
      content:
        "Comment on the significance of this text to your clients or practice area, mark as particularly important to you or if it's not quite right (we'll learn from your feedback)",
      placement: 'auto',
      disableBeacon: true,
    },
  ],
  EDITOR_SECTION: [
    {
      target: '.docView',
      content:
        'Select a piece of text to annotate it with your expert legal opinion, to be fed into your final composition. You can edit text just like in a Word document - add, change, remove any section to your liking.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.docView',
      content:
        'Click on a paragraph reference (e.g. [1] or [26]) to see the original source judgment text where this was cited from',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.goToCompose',
      content:
        'After annotating the case summary, click Compose to transform your annotations into a new document such as an update or memo etc.',
      placement: 'bottom',
    },
  ],
  EXPLORER_SECTION: [
    {
      target: '.searchBar',
      content: 'Search for a case by neutral citation, title, parties or court',
      placement: 'top',
      disableBeacon: true,
    },
    {
      target: '.searchButton',
      content: 'Click to start your search',
      placement: 'top',
    },
    {
      target: '.exploreButton',
      content: 'Click to view the case summary and explorer',
      placement: 'top',
    },
  ],
  GENERATOR_SECTION: [
    {
      target: '.docTypeInput',
      content: 'Select type of output content, depending on the intended reader and purpose of your content',
    },
    {
      target: '.docLengthInput',
      content: 'Select the length of your output',
    },
    {
      target: '.docAudienceInput',
      content: 'Select your intended audience - this influences the complexity of language and tone of the output',
    },
    {
      target: '.commentSectionInput',
      content:
        'Write out which section headers you\'d like the output to have, each separated by a comma',
      disableBeacon: true,
    },
    {
      target: '.commandInput',
      content:
        'Wexler combines the case summary with your expert annotations to create internal updates & BD articles. Type any additional instructions for your composition here, such as "Remove abbreviations"',
      disableBeacon: true,
    },
    {
      target: '.buttonCompose',
      content: 'Click to start generating your composition',
    },
  ],
  SIDE_VIEW_SECTION: [
    {
      target: '.closeSideView',
      content:
        'Click on the cross to return to the original case summary view',
      disableBeacon: true,
    },
  ],
};

export const TOUR_KEYS = {
  ACTIONS_SECTION: 'ACTIONS_SECTION',
  EDITOR_SECTION: 'EDITOR_SECTION',
  EXPLORER_SECTION: 'EXPLORER_SECTION',
  GENERATOR_SECTION: 'GENERATOR_SECTION',
  SIDE_VIEW_SECTION: 'SIDE_VIEW_SECTION',
};
