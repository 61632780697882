import React from 'react';
import Editor from './Editor';
import Generator from './Generator';
import Explorer from './Explorer';
import Interests from './Interests';
import CompositionsExplorer from './CompositionsExplorer';
import Liability from './Liability';
import Chronos from './Chronos';

import { Route, Routes, Navigate } from 'react-router-dom';

const PrivateApp = () => {
  return (
    <Routes>
      <Route path="editor/:summaryId" element={<Editor />} />
      <Route path="generator/:summaryId" element={<Generator />} />
      <Route path="explore" element={<Explorer />} />
      <Route path="my-projects" element={<Explorer privateProjects />} />
      <Route path="my-compositions" element={<CompositionsExplorer />} />
      <Route path="explore" element={<Explorer />} />
      <Route path="interests" element={<Interests />} />
      <Route path="liability" element={<Liability />} />
      <Route path="chronos/*" element={<Chronos />} />
      <Route path="*" element={<Navigate to="/app/chronos" />} />
    </Routes>
  );
};

export default PrivateApp;
