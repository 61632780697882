import { HOTJAR_KEYS } from 'constants/hotjar';
import React from 'react';
import { hotjar } from 'react-hotjar';

export const HotjarContext = React.createContext();
export const HotjarProvider = ({ children }) => {
  hotjar.initialize(HOTJAR_KEYS.hjid, HOTJAR_KEYS.hjsv);
  return (
    <HotjarContext.Provider
      value={{
        hotjar: hotjar,
      }}
    >
      {children}
    </HotjarContext.Provider>
  );
};
