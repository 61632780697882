import React, { useState, createRef, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { StageSpinner } from 'react-spinners-kit';
import { MODES } from 'constants/modes';
import Select from 'react-select';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { UserContext } from 'Contexts/User';
import { DOC_TYPES, DOC_LENGTHS, DOC_AUDIENCES } from 'constants/doc';
import { reactSelectStyles } from 'constants/styles';
import { Generation, MyOptionType } from 'types';
import { APIBase } from 'api/hosts';
import ActionsSectionAdvanced from '../ActionsSectionAdvanced';
import Quill from 'quill';
import IndexSectionAdvanced from '../IndexSectionAdvanced';
import QuillEditorAdvanced from 'components/QuillEditorAdvanced';
import LiabilityDisclaimer from 'PrivateApp/LiabilityDisclaimer';
import moment from 'moment';
import { HotjarContext } from 'Contexts/Hotjar';
import Joyride, { Step, STATUS, CallBackProps } from 'react-joyride';
import { tourStyles } from 'constants/tour';
import { TOUR_STEPS, TOUR_KEYS } from 'tour';
import useGetFetchConfig from '../../api/useGetFetchConfig';

const emptyResponse = {
  _id: '',
  caseObjectId: '',
  command: '',
  documentType: '',
  documentLength: '',
  title: '',
  subtitle: '',
  is_compose_complete: false,
  is_compose_error: '',
  compose_sections: [],
  dateComposeGenerated: new Date(),
  compose_html: '',
  compose_time_estimate: 0,
};

const useDownloadCompositionDocument = (compositionId: string, APIBase: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const {
    isFetching: isFetchingDownloadComposition,
    refetch: fetchDownload,
    data: blob,
  } = useQuery(
    ['downloadCompositionDocument', compositionId],
    async () => {
      const response = await fetch(`${APIBase}/client/composer/createAndDownload/${compositionId}`, fetchConfigGET);
      if (!response.ok) {
        throw new Error('Error fetching composition document');
      }
      return response.blob();
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (blob) {
      const link = document.createElement('a');
      const file = new Blob([blob], { type: 'text/plain' });
      link.href = URL.createObjectURL(file);
      link.download = `CompositionDocument-${compositionId}.docx`; // Adjust the file naming convention as needed
      document.body.appendChild(link); // Append link to body to make it work in Firefox
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link); // Clean up
    }
  }, [blob, compositionId]);

  return { isFetchingDownloadComposition, fetchDownload };
};

function Generator() {
  const listRef = createRef<HTMLDivElement>();
  const [documentType, setDocumentType] = useState<MyOptionType>();
  const [documentLenght, setDocumentLenght] = useState<MyOptionType>();
  const [documentAudience, setDocumentAudience] = useState<MyOptionType>();
  const [command, setCommand] = useState('');
  const [commentSection, setCommentSection] = useState('');
  const [pageYPosition, setPageYPosition] = useState(-1000);
  const [scrollTop, setScrollTop] = useState(0);
  const [newComment, setNewComment] = useState('');
  const [selectedStartIndex, setSelectedStartIndex] = useState(-1);
  const [selectedEndIndex, setSelectedEndIndex] = useState(-1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mode, setMode] = useState(MODES.MENU.id);
  const [commentToShow, setCommentToShow] = useState('');
  const [generationResponse, setGenerationResponse] = useState<Generation>(emptyResponse);
  const { summaryId } = useParams();
  const [HTMLCopy, setHTMLCopy] = useState<String>('');
  const [currentQuillEditor, setCurrentQuillEditor] = useState<Quill>();
  const [searchParams] = useSearchParams();
  const compositionId = searchParams.get('compositionId');
  const [lastSaveTime, setLastSavetime] = useState(new Date(Date.now()));
  const { hotjar } = useContext(HotjarContext);
  const { markTourKeyDone } = useContext(UserContext);
  const navigate = useNavigate();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const { isFetching: isFetchingNewGenerationResult, refetch } = useQuery(
    'generationResult',
    async () => {
      setGenerationResponse(emptyResponse);
      const composerBody = {
        objectId: summaryId,
        command: command,
        commentSection: commentSection,
        documentType: documentType?.value || '',
        documentLength: documentLenght?.value || '',
        documentAudience: documentAudience?.value || '',
      };
      const fetchConfig = getFetchConfig({ method: 'POST', data: composerBody });

      const response = await fetch(`${APIBase}/client/composer`, fetchConfig).then((res) => {
        return res.json();
      });
      navigate(`/app/generator/${summaryId}?compositionId=${response.composition._id}`);
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  const { isFetching: isFetchingSavedComposition, data: savedCompositionData } = useQuery(
    ['savedGenerationResult', compositionId],
    async () => {
      return fetch(`${APIBase}/client/composer/${compositionId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: !!compositionId && !generationResponse.is_compose_complete,
      cacheTime: 0,
      refetchInterval: generationResponse.is_compose_complete ? 3000 : 500,
    },
  );

  const { isFetchingDownloadComposition, fetchDownload } = useDownloadCompositionDocument(compositionId || '', APIBase);

  const { isFetching: isFetchingUpdate } = useQuery(
    'compositionUpdateById',
    async () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { compose_html: HTMLCopy } });

      setLastSavetime(new Date(Date.now()));
      return fetch(`${APIBase}/client/composer/${compositionId || generationResponse._id}`, fetchConfig).then((res) => {
        return res.json();
      });
    },
    {
      enabled: !!generationResponse._id && !!generationResponse.is_compose_complete,
      refetchInterval: generationResponse.is_compose_complete ? 3000 : 500,
    },
  );

  useEffect(() => {
    hotjar.stateChange('/app/compose');
    setGenerationResponse(emptyResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (savedCompositionData) {
      setGenerationResponse(savedCompositionData.composition);
      setHTMLCopy(savedCompositionData.composition.compose_html);
      setCommand(savedCompositionData.composition.command);
      setCommentSection(savedCompositionData.composition.commentSection);
      setDocumentLenght(
        Object.values(DOC_LENGTHS).filter(
          (DOC_LENGTH) => DOC_LENGTH.value === savedCompositionData.composition.documentLength,
        )[0],
      );
      setDocumentType(
        Object.values(DOC_TYPES).filter(
          (DOC_TYPE) => DOC_TYPE.value === savedCompositionData.composition.documentType,
        )[0],
      );
      setDocumentAudience(
        Object.values(DOC_AUDIENCES).filter(
          (DOC_AUDIENCE) => DOC_AUDIENCE.value === savedCompositionData.composition.documentAudience,
        )[0],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCompositionData]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  const handleChangeCommand = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommand(e.target.value);
  };

  const handleChangeCommentSection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommentSection(e.target.value);
  };

  const handleClickCompose = () => {
    hotjar.event('compose-click');
    refetch();
  };
  const handleClickDownload = () => {
    hotjar.event('download-click');
    fetchDownload();
  };

  const handleClickShare = () => {
    hotjar.event('share-click');
  };

  const handleChangeDocumentType = (val: MyOptionType) => {
    setDocumentType(val);
  };

  const handleChangeDocumentLength = (val: MyOptionType) => {
    setDocumentLenght(val);
  };

  const handleChangeDocumentAudience = (val: MyOptionType) => {
    setDocumentAudience(val);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as 'finished' | 'skipped')) {
      markTourKeyDone(TOUR_KEYS.GENERATOR_SECTION);
    }
  };

  const isFetching =
    isFetchingNewGenerationResult ||
    isFetchingSavedComposition ||
    (!!generationResponse._id && !generationResponse.is_compose_complete);

  return (
    <div className="flex flex-col">
      {localStorage.getItem(TOUR_KEYS.GENERATOR_SECTION) !== 'true' && (
        <Joyride
          steps={TOUR_STEPS.GENERATOR_SECTION as Step[]}
          continuous
          styles={tourStyles}
          callback={handleJoyrideCallback}
          run
          disableScrolling
        />
      )}
      <div className="font-sans flex flex-col md:flex-row items-start justify-start max-w-full pt-7 text-white h-screen">
        <IndexSectionAdvanced
          {...{
            documentSections: generationResponse.compose_sections,
            listRef,
            HTMLCopy,
            isLoading: isFetching,
            defaultFirstIndexTitle: generationResponse.title,
          }}
        />
        <div
          className="flex flex-col items-start justify-start p-5 w-full md:w-2/4 h-full overflow-scroll"
          style={{ width: '1050px' }}
          onScroll={handleScroll}
        >
          {!compositionId && (
            <div className="w-full bg-white px-3 py-4 rounded-lg mb-5">
              <div className="LotaGrotesque not-italic font-semibold text-base text-gray-900 mb-3">
                Select the type, length & intended audience of your audience of your content:
              </div>
              <div className="docTypeLenghtInput flex items-start justify-start mt-3">
                <Select
                  options={Object.values(DOC_TYPES)}
                  className="docTypeInput mr-3 outline-none w-72 shrink-0"
                  styles={reactSelectStyles}
                  onChange={handleChangeDocumentType}
                  value={documentType}
                  isOptionDisabled={(option) => !!option?.disabled || false}
                />
                <Select
                  options={Object.values(DOC_LENGTHS)}
                  className="docLengthInput mr-3 w-40 outline-none shrink-0"
                  styles={reactSelectStyles}
                  onChange={handleChangeDocumentLength}
                  value={documentLenght}
                />
                <Select
                  options={Object.values(DOC_AUDIENCES)}
                  className="docAudienceInput mr-3 w-full outline-none"
                  styles={reactSelectStyles}
                  onChange={handleChangeDocumentAudience}
                  value={documentAudience}
                />
              </div>
              <div className="LotaGrotesque not-italic font-semibold text-base text-gray-900 mb-3 mt-5">
                Tell us what section headers you'd like, separate each with a comma (optional)
              </div>
              <div
                className="w-full flex flex-row bg-gray-200 rounded-lg p-2 mt-2"
                style={{ border: '1px solid #ECECEC' }}
              >
                <input
                  className="commentSectionInput h-12 pl-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-600 rounded-lg bg-transparent"
                  placeholder="e.g. Background, The decision, Conclusion"
                  onChange={handleChangeCommentSection}
                  value={commentSection}
                />
              </div>
              <div className="LotaGrotesque not-italic font-semibold text-base text-gray-900 mb-3 mt-5">
                Ask Wexler about any extra adjustments or edits (optional)
              </div>
              <div
                className="w-full flex flex-row bg-gray-200 rounded-lg p-2 mt-2"
                style={{ border: '1px solid #ECECEC' }}
              >
                <input
                  className="commandInput h-12 pl-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-600 rounded-lg bg-transparent"
                  placeholder="e.g. remove abbreviations"
                  onChange={handleChangeCommand}
                  value={command}
                />
                <button
                  className="buttonCompose cursor-pointer flex flex-row justify-center items-center w-40 h-12 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500"
                  onClick={handleClickCompose}
                  disabled={!documentType || !documentLenght || !documentAudience}
                >
                  Compose
                </button>
              </div>
            </div>
          )}
          {(generationResponse.compose_html || isFetching) && (
            <div ref={listRef} className="w-full bg-white pb-16 text-black not-italic text-base h-auto">
              {isFetching && !compositionId && !generationResponse.compose_html && (
                <div className="w-ful flex flex-col items-center justify-center mt-6">
                  <StageSpinner className="m-auto" color={'#081D57'} />
                </div>
              )}
              {generationResponse.compose_html && (
                <div className="w-full shrink-0 px-28 mb-3" style={{ background: '#081D57' }}>
                  <div className="not-italic font-normal text-xs uppercase text-white mb-5 pt-16">
                    {generationResponse.title}
                  </div>
                  <div className="outline-none RecklessNeue not-italic font-medium text-3xl text-white pb-14">
                    {generationResponse.subtitle}
                  </div>
                </div>
              )}
              {!isFetching && (
                <div className="w-full shrink-0 bg-white text-black italic bold text-sm px-28 my-0 py-0 flex items-center">
                  Last saved edit: {moment(lastSaveTime).format('DD MMM h:mm:ss a')}{' '}
                  {isFetchingUpdate && <div className="text-xs ml-4">Saving changes</div>}
                </div>
              )}
              {generationResponse.compose_html && (
                <div className="mx-28 bg-white">
                  <QuillEditorAdvanced
                    content={generationResponse.compose_html}
                    setHTMLCopy={setHTMLCopy}
                    setMenuOpen={setMenuOpen}
                    setPageYPosition={setPageYPosition}
                    scrollTop={scrollTop}
                    setCurrentQuillEditor={setCurrentQuillEditor}
                    setMode={setMode}
                    setCommentToShow={setCommentToShow}
                    menuOpen={menuOpen}
                    setSelectedEndIndex={setSelectedEndIndex}
                    setSelectedStartIndex={setSelectedStartIndex}
                  />
                  {isFetching && compositionId && !generationResponse.is_compose_complete && (
                    <div className="w-ful flex flex-col items-center justify-center mt-6">
                      <StageSpinner className="m-auto" color={'#081D57'} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {generationResponse.compose_html.length && !isFetching && (
            <>
              <div className="w-full bg-white px-3 py-4">
                <div className="w-full flex flex-row bg-gray-200 p-2" style={{ border: '1px solid #ECECEC' }}>
                  <button
                    className="flex flex-row justify-center items-center w-full h-12 bg-indigo-200 rounded-lg not-italic font-semibold text-blue-600 mr-2"
                    onClick={handleClickShare}
                    title={'Comming soong...'}
                  >
                    Share (coming soon)
                    <FontAwesomeIcon icon={faShare} className="pl-2" />
                  </button>
                  <button
                    className="flex flex-row justify-center items-center w-full h-12 bg-blue-600 rounded-lg not-italic font-semibold text-white mr-2"
                    onClick={handleClickDownload}
                  >
                    {isFetchingDownloadComposition ? (
                      <StageSpinner className="m-auto" color={'#FFFFFF'} />
                    ) : (
                      'Download to .docx'
                    )}

                    <FontAwesomeIcon icon={faDownload} className="pl-2" />
                  </button>
                </div>
              </div>
              <LiabilityDisclaimer />
            </>
          )}
        </div>
        <ActionsSectionAdvanced
          {...{
            pageYPosition,
            scrollTop,
            newComment,
            setNewComment,
            selectedStartIndex,
            setSelectedStartIndex,
            selectedEndIndex,
            setSelectedEndIndex,
            menuOpen,
            setMenuOpen,
            mode,
            setMode,
            commentToShow,
            HTMLCopy,
            setHTMLCopy,
            currentQuillEditor,
          }}
        />
      </div>
    </div>
  );
}

export default Generator;
