import { faArrowLeft, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '@ramonak/react-progress-bar';
import { APIBaseChronos } from 'api/hosts';
import { TooltipStyles } from 'constants/styles';
import { niceBytes } from 'helpers/bytesCalculator';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import useGetFetchConfig from '../../../api/useGetFetchConfig';

const DocAdder: React.FC = () => {
  const navigate = useNavigate();
  const [successfulUploads, setSuccessfulUploads] = useState<number>(0);
  const [expectedUploads, setExpectedUploads] = useState<number>(0);
  const [caseName, setCaseName] = useState<string>('');
  const [posting, setPosting] = useState(false);
  const [docRemoving, setDocRemoving] = useState<string>('');
  const { caseId } = useParams();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const {
    isFetching: isLoadingNewCase,
    data: responseNewCase,
    refetch: refetchNewCase,
  } = useQuery(
    'getCaseForDocAdder',
    () => {
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    isFetching: isLoadingUpdateCase,
    data: responseUpdateCase,
    refetch: refetchUpdateCase,
  } = useQuery(
    'addNewFiles',
    () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { isUpdate: true } });
      return fetch(`${APIBaseChronos}/client/case/addNewFiles/${caseId}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(
    ['userNewDocs'],
    () => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}?toUpdate=${true}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (responseUpdateCase?.success) {
      navigate(`/app/chronos/case-editor/documents?caseId=${caseId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUpdateCase]);

  useEffect(() => {
    refetchNewCase();
    refetchCaseDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseNewCase?.caseObject?.case_name) {
      setCaseName(responseNewCase?.caseObject?.case_name);
    }
  }, [responseNewCase]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setPosting(true);
      setSuccessfulUploads(0);
      setExpectedUploads(acceptedFiles.length);
      const uploadPromises = acceptedFiles.map((file) =>
        uploadFile(file)
          .then(() => {
            setSuccessfulUploads((prev) => prev + 1);
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
              showConfirmButton: false,
              timer: 3000,
            });
          }),
      );

      await Promise.allSettled(uploadPromises);

      refetchCaseDocs();
      setPosting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [caseId],
  );

  async function uploadFile(file: File): Promise<void> {
    if (caseId) {
      return new Promise<void>(async (resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('caseId', caseId);

            const fetchConfig = getFetchConfig({ method: 'POST', data: formData, isFormData: true });

            const endpoint = `client/case/doc`;
            await fetch(`${APIBaseChronos}/${endpoint}`, fetchConfig)
              .then(async (results) => {
                return results.json();
              })
              .catch(() => {
                reject(new Error(`The file ${file.name} could not be uploaded. Please try again later`));
              });
            resolve();
          } catch (error) {
            reject(error);
          }
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      });
    }
  }

  const handleRemoveDoc = async (docId: string) => {
    setDocRemoving(docId);
    const fetchConfig = getFetchConfig({ method: 'DELETE' });

    await fetch(`${APIBaseChronos}/client/case/doc/${docId}`, fetchConfig);
    setDocRemoving('');
    refetchCaseDocs();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpdateCase = () => {
    refetchUpdateCase();
  };

  const handleClickBack = () => {
    Swal.fire({
      title: '',
      text: 'Are you sure you want to stop adding documents to the case?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'rgb(37 99 235)',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate(`/app/chronos/case-editor/documents?caseId=${caseId}`);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  const sum = responseDocs?.docs?.reduce((acc: any, doc: any) => acc + (doc?.file_size || 0), 0) || 0;

  // 250MB in bytes

  const GB_1_25_IN_BYTES = 1.25 * 1e9;
  const size_limit_exceeded = sum > GB_1_25_IN_BYTES;
  const count_limit_exceeded = (responseDocs?.docs?.length || 0) > 500;

  const message =
    size_limit_exceeded && count_limit_exceeded
      ? 'Exceeded 1.25GB file size and 500 file count limits.'
      : size_limit_exceeded
      ? 'Exceeded 1.25GB file size limit.'
      : count_limit_exceeded
      ? 'Exceeded 500 file count limit.'
      : '';

  return (
    <div className="LotaGrotesque flex flex-row relative overflow-auto w-full text-white">
      <div className="font-sans flex flex-col pl-8 w-full items-start justify-start">
        {isLoadingNewCase ? (
          <div className="w-full h-full flex flex-col items-center justify-start text-black pt-14">
            <StageSpinner className="m-auto" color={'#4161FF'} />
          </div>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer mt-6"
              onClick={() => handleClickBack()}
            />
            <div className="text-black text-3xl not-italic font-bold w-full">{caseName}</div>
            <div className="" style={{ width: '665px' }}>
              <div className="text-black text-xl not-italic font-bold mt-4">
                Additional Files {responseDocs?.docs?.length ? `(${responseDocs?.docs?.length})` : ''}
              </div>
              {!!message && <div className="text-red-700 font-semibold italic">{message}</div>}
              <div
                className="w-108 h-36 flex items-center justify-center bg-white mb-3 cursor-pointer rounded-2xl mt-4"
                style={{ border: '1px dashed var(--purple-100, #4161FF)', background: 'rgba(66, 98, 255, 0.05)' }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {posting && (
                  <div className="flex flex-col">
                    <div className="text-gray-900 text-center text-base not-italic font-bold leading-6 mb-4">
                      Uploaded {successfulUploads} of {expectedUploads} files
                    </div>
                    <ProgressBar
                      completed={(100 * successfulUploads) / expectedUploads}
                      customLabel={''}
                      bgColor={'#4161FF'}
                      height={'6px'}
                      isLabelVisible={false}
                    />
                  </div>
                )}
                {!posting &&
                  (isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <div>
                      <button
                        data-tooltip-id="doc-upload-tooltip"
                        data-tooltip-target="tooltip-hover"
                        data-tooltip-trigger="hover"
                        data-tooltip-content="Supported file types: EML, MHT, DocX, Doc, PDF, and Spreadsheet files (more coming soon)"
                        className="cursor-pointer flex flex-row justify-center items-center h-12 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 shrink-0 ml-2 text-center text-base not-italic font-bold leading-6 text-white px-6 py-4"
                      >
                        <FontAwesomeIcon icon={faFolderPlus} className="text-white mr-2" />
                        Upload Files
                      </button>
                      <Tooltip id="doc-upload-tooltip" style={TooltipStyles} />
                      <div
                        className="text-center text-sm not-italic font-bold leading-4 mt-4"
                        style={{ color: 'var(--purple-100, #4161FF)' }}
                      >
                        Or drag here
                      </div>
                    </div>
                  ))}
              </div>
              <div className="w-full flex flex-column">
                {responseDocs?.docs && responseDocs?.docs.length > 0 && (
                  <div className="w-full">
                    {responseDocs?.docs?.map((doc: any) => {
                      return (
                        <div className="bg-white mb-3 p-3 w-full rounded-lg" key={doc._id}>
                          <div
                            className="w-full flex items-center justify-between"
                            style={{ color: 'var(--dark-blue-80, #373D68)' }}
                          >
                            <div className="w-1/3 shrink-0 truncate">
                              {doc?.file_name?.split('.')?.filter(Boolean)[0]}
                            </div>
                            <div className="w-1/3 shrink-0 flex items-center justify-center">
                              <div
                                className="flex w-13 h-7 justify-center items-center py-3 rounded-3xl text-sm not-italic font-bold leading-4 uppercase px-2"
                                style={{ backgroundColor: '#EEF8FD', color: '#2D9CDB' }}
                              >
                                {doc.file_extension ? doc.file_extension.toUpperCase() : '---'}
                              </div>
                            </div>
                            <div className="text-base not-italic font-medium w-20">
                              {doc.file_size ? `${niceBytes(doc.file_size)}` : '---'}
                            </div>
                            <div
                              className="flex justify-center items-center px-2 py-3 rounded-lg ml-auto w-24 not-italic shrink-0 font-bold leading-6 cursor-pointer"
                              style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                              onClick={() => handleRemoveDoc(doc.doc_id)}
                            >
                              {docRemoving === doc.doc_id ? (
                                <StageSpinner className="m-auto" color={'#4161FF'} />
                              ) : (
                                'Remove'
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!isLoadingNewCase && (
        <div className="w-full flex flex-row h-full sticky top-0 ml-7">
          <button
            style={{ bottom: '10px' }}
            className="absolute cursor-pointer flex flex-row justify-center items-center  h-12 w-96 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:opacity-20 shrink-0 text-center text-base not-italic font-bold leading-6 text-white disabled:cursor-not-allowed px-6 py-4"
            onClick={handleUpdateCase}
            disabled={
              isLoadingUpdateCase ||
              posting ||
              !responseDocs?.docs?.length ||
              size_limit_exceeded ||
              count_limit_exceeded
            }
          >
            {isLoadingUpdateCase ? <StageSpinner className="m-auto" color={'white'} /> : 'Next'}
          </button>
        </div>
      )}
    </div>
  );
};

export default DocAdder;
