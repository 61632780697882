import { useEffect, useRef, useState } from 'react';

import { faChevronLeft, faChevronRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import useGetFetchConfig from '../../../../api/useGetFetchConfig';
import PDFViewer from '../../PDFViewer';
import FactsEditor from '../FactsEditor';
import FactAdder from '../FactsEditor/FactAdder';

const documentWidth = 750;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    height: '90vh',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

const DocViewer = ({ caseId, docId, eventId, pageNumber }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const viewerRef = useRef(null);
  const [fileUrl, setFileUrl] = useState('');
  const [isDocumentView, setIsDocumentView] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [highlightCoordinates, setHighlightCoordinates] = useState([]);
  const isFactsOrChronologiesPage = location.pathname.includes('/facts') || location.pathname.includes('/chronology');
  const { fetchConfigGET } = useGetFetchConfig();

  const getDocumentUrl = async () => {
    if (docId) {
      const downloadResult = await fetch(`${APIBaseChronos}/client/case/doc/download/${docId}`, fetchConfigGET);
      const blob = await downloadResult.blob();
      const fileBlobUrl = URL.createObjectURL(blob); // Convert the blob to object URL
      return fileBlobUrl;
    }
  };

  const { refetch: fetchDocument } = useQuery(
    { queryKey: ['downloadDoc', docId], queryFn: getDocumentUrl },
    { enabled: false },
  );
  const handleCloseModal = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('caseId', caseId);
    searchParams.delete('docId');

    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  };

  const getResponseDocs = () => {
    if (caseId) {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    }
  };

  const { data: responseDocs } = useQuery({ queryKey: ['userDocs'], queryFn: getResponseDocs });

  const getRefCoords = () => {
    if (eventId) {
      return fetch(`${APIBaseChronos}/client/case/fact/coordinates/${eventId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    }
  };

  const { data: responseCoordinates } = useQuery({
    queryKey: ['referenceCoordinates', docId, eventId],
    queryFn: getRefCoords,
  });

  const filename = responseDocs?.docs?.find((doc) => doc.doc_id === docId)?.file_name || '';
  const fileDescription = responseDocs?.docs?.find((doc) => doc.doc_id === docId)?.file_description || '';

  const goToDoc = ({ newDocIndex }) => {
    setFileUrl('');
    const document = documents[newDocIndex];
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', document.docId);
    searchParams.set('pageNumber', document.initialPage);
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  };

  const handleCloseDocDetailView = () => {
    setIsDocumentView(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', docId);
    searchParams.delete('eventId');
    searchParams.delete('pageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    handleCloseDocDetailView();
  };

  useEffect(() => {
    if (docId) {
      fetchDocument().then(({ data: documentUrl }) => {
        setFileUrl(documentUrl);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  useEffect(() => {
    if (responseDocs && !eventId) {
      setDocuments(
        responseDocs.docs.map((doc) => {
          return { docId: doc.doc_id, initialPage: 0 };
        }),
      );
    }
  }, [responseDocs, eventId]);

  useEffect(() => {
    if (docId && eventId) {
      const docIdsRelatedToEvent = [
        ...new Set(
          responseCoordinates?.factsCoordinates.map((coordinate) => {
            return {
              docId: coordinate.doc_id,
              initialPage: coordinate.n_page,
            };
          }),
        ),
      ];

      setDocuments(docIdsRelatedToEvent);
      setHighlightCoordinates(
        responseCoordinates?.factsCoordinates
          ?.filter((coordinate) => coordinate.doc_id === docId)?.[0]
          .coordinate_details.map((coordinate) => {
            return {
              height: coordinate.height_c * 100,
              left: coordinate.left_c * 100,
              pageIndex: coordinate.n_page - 1,
              top: coordinate.top_c * 100,
              width: coordinate.width_c * 100,
            };
          }) || [],
      );
    }
  }, [responseCoordinates, docId, eventId]);

  const handleNewFactCreated = () => {
    setModalIsOpen(false);
    const currentPathWithQuery = window.location.pathname + window.location.search;
    navigate(currentPathWithQuery, { replace: true, state: { key: Date.now() } });
  };

  useEffect(() => {
    if (eventId) {
      setIsDocumentView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const currentDocIndex = documents.map((x) => x.docId).indexOf(docId);
  const isPrevPage = currentDocIndex > 0;
  const isNextPage = currentDocIndex < documents.length - 1;

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <FactAdder docs={responseDocs?.docs} handleNewFactCreated={handleNewFactCreated} docId={docId} />
      </Modal>
      <div
        className=""
        style={{
          width: isDocumentView ? '750px' : '90vw',
          height: '90vh',
          position: 'relative',
        }}
      >
        <div className="flex flex-row w-full justify-between items-center" style={{ height: '50px' }}>
          <FontAwesomeIcon
            icon={isDocumentView && !isFactsOrChronologiesPage ? faChevronLeft : faClose}
            className="w-7 h-7 cursor-pointer"
            color={'black'}
            onClick={() =>
              isDocumentView && !isFactsOrChronologiesPage ? handleCloseDocDetailView() : handleCloseModal()
            }
          />
          {!eventId && documents.length > 1 && (
            <div className="flex flex-row items-center justify-center ml-auto">
              <div
                className={`${
                  isPrevPage ? 'cursor-pointer' : 'cursor-not-allowed'
                }  text-sm not-italic font-bold leading-6  flex flex-row items-center justify-center-center`}
                style={{ color: '#4161FF', opacity: isPrevPage ? 1 : 0.5 }}
                onClick={() => isPrevPage && goToDoc({ newDocIndex: currentDocIndex - 1 })}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="w-6 h-6 " />
                Back
              </div>
              <div className="mx-6 text-gray-900 text-sm not-italic font-bold leading-6">
                Document {currentDocIndex + 1} of {documents.length || 0}
              </div>
              <div
                className={`${
                  isNextPage ? 'cursor-pointer' : 'cursor-not-allowed'
                } text-sm not-italic font-bold leading-6 flex flex-row items-center justify-center-center`}
                style={{ color: '#4161FF', opacity: isNextPage ? 1 : 0.5 }}
                onClick={() => {
                  isNextPage && goToDoc({ newDocIndex: currentDocIndex + 1 });
                }}
              >
                Next
                <FontAwesomeIcon icon={faChevronRight} className="w-6 h-6 " />
              </div>
            </div>
          )}
        </div>
        {!isDocumentView && (
          <>
            <div className="text-black text-3xl not-italic font-bold mb-2 mt-5">{filename}</div>
            <div className="text-gray-700 text-base not-italic font-medium leading-6 mb-6" style={{ opacity: 0.8 }}>
              {fileDescription}
            </div>
          </>
        )}
        {fileUrl ? (
          isDocumentView ? (
            <div
              ref={viewerRef}
              className="mx-auto"
              style={{ width: documentWidth, height: '100%', position: 'relative' }}
            >
              <PDFViewer fileUrl={fileUrl} initialPage={pageNumber} highlights={highlightCoordinates} />
            </div>
          ) : (
            <div className="flex flex-row items-end mb-7" style={{ height: '162px' }}>
              <div
                style={{
                  width: '304px',
                  height: '162px',
                  overflowY: 'hidden',
                  borderRadius: '16px',
                  position: 'relative',
                }}
              >
                <PDFViewer fileUrl={fileUrl} />
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: '0',
                    top: '0',
                    background: 'rgba(0,0,0,0.5)',
                  }}
                ></div>
              </div>
              <div
                className="flex justify-center items-center px-2 py-3 rounded-lg  not-italic font-bold  w-56 h-12 cursor-pointer ml-4"
                style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                onClick={() => setIsDocumentView(true)}
              >
                View unredacted
              </div>
            </div>
          )
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            <StageSpinner className="m-auto" color={'#4161FF'} /> Your document is being loaded
          </div>
        )}
        {!isDocumentView && <FactsEditor docId={docId} onlyTable={true} />}
      </div>
    </>
  );
};

export default DocViewer;
