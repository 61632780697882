import { faArrowLeft, faFolderPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import ProgressBar from '@ramonak/react-progress-bar';
import { APIBaseChronos } from 'api/hosts';
import { DISPUTE_STATUS_OPTIONS } from 'constants/disputeStatusOptions';
import { TooltipStyles, reactSelectStylesCaseCreator } from 'constants/styles';
import { TIMEZONE_OPTIONS } from 'constants/timezoneOptions';
import { niceBytes } from 'helpers/bytesCalculator';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { MyOptionType } from 'types';
import useGetFetchConfig from '../../../api/useGetFetchConfig';

const CaseCreator: React.FC = () => {
  const navigate = useNavigate();
  const [successfulUploads, setSuccessfulUploads] = useState<number>(0);
  const [expectedUploads, setExpectedUploads] = useState<number>(0);
  const [posting, setPosting] = useState(false);
  const [caseName, setCaseName] = useState<string>('');
  const [caseType, setCaseType] = useState<string>('');
  const [caseIssues, setCaseIssues] = useState<string>('');
  const [caseParties, setCaseParties] = useState<string>('');
  const [docRemoving, setDocRemoving] = useState<string>('');
  const [disputeStatus, setDisputeStatus] = useState<MyOptionType | null>();
  const [timeZone, setTimeZone] = useState<MyOptionType | null>();
  const { caseId } = useParams();

  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  // Define refs for each textarea
  const textAreaNameRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaTypeRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaIssuesRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaPartiesRef = useRef<HTMLTextAreaElement | null>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = '56px';
      if (ref.current.scrollHeight > 56) {
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
    }
  };

  const {
    isFetching: isLoadingCase,
    data: responseCase,
    refetch: refetchGetCase,
  } = useQuery(
    ['getCase', caseId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    isFetching: isLoadingUpdateCase,
    data: responseUpdateCase,
    refetch: refetchUpdateCase,
  } = useQuery(
    ['updateCase', caseId],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          caseName,
          caseType,
          caseIssues,
          caseParties,
          disputeStatus: disputeStatus?.value,
          isDateAmerican: timeZone?.value === 'American' ? true : false,
        },
      });
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(
    ['userDocs', caseId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (caseId) {
      refetchGetCase();
      refetchCaseDocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  useEffect(() => {
    if (responseUpdateCase?.updateDocObject?.case_id) {
      navigate(`/app/chronos/case-editor/documents?caseId=${caseId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUpdateCase]);

  const uploadFile = async (file: File): Promise<void> => {
    if (!caseId) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('caseId', caseId);

    const fetchConfig = getFetchConfig({ method: 'POST', data: formData, isFormData: true });

    const endpoint = `client/case/doc`;

    try {
      const response = await fetch(`${APIBaseChronos}/${endpoint}`, fetchConfig);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error occurred while uploading file');
      }
    } catch (error) {
      throw error;
    }
  };

  const uploadWithRetry = async (file: File, maxRetries: number = 5): Promise<void> => {
    let attempt = 0;
    while (attempt <= maxRetries) {
      try {
        await uploadFile(file);
        setSuccessfulUploads((prev) => prev + 1);
        break;
      } catch (error) {
        if (attempt >= maxRetries) {
          Swal.fire({
            title: 'Error',
            text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
            showConfirmButton: false,
            timer: 3000,
          });
          throw error; // We're rethrowing the error here so it can be caught in Promise.allSettled
        } else {
          const delay = Math.pow(2, attempt) * 1000; // Exponential backoff
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        attempt++;
      }
    }
  };

  const processInBatches = async (files: File[], batchSize: number = 5): Promise<void> => {
    setPosting(true);
    setSuccessfulUploads(0);
    setExpectedUploads(files.length);

    const batches: File[][] = [];
    while (files.length) {
      batches.push(files.splice(0, batchSize));
    }

    for (const batch of batches) {
      await Promise.allSettled(batch.map((file) => uploadWithRetry(file)));
      // Here you can handle responses, update the UI, etc.
    }

    setPosting(false);
    refetchCaseDocs(); // Uncomment this if you have this function for updating the UI or any other post-upload logic
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      processInBatches(acceptedFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [caseId, accessToken, APIBaseChronos], // Add dependencies here if they'll be used inside the useCallback
  );

  const handleRemoveDoc = async (docId: string) => {
    setDocRemoving(docId);
    const fetchConfig = getFetchConfig({ method: 'DELETE' });

    await fetch(`${APIBaseChronos}/client/case/doc/${docId}`, fetchConfig);
    setDocRemoving('');
    refetchCaseDocs();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleChangeCaseName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseName(e.target.value);
    autoResize(textAreaNameRef);
  };

  const handleChangeCaseType = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseType(e.target.value);
    autoResize(textAreaTypeRef);
  };
  const handleChangeCaseIssues = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseIssues(e.target.value);
    autoResize(textAreaIssuesRef);
  };

  const handleChangeCaseParties = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseParties(e.target.value);
    autoResize(textAreaPartiesRef);
  };
  const handleUpdateCase = () => {
    refetchUpdateCase();
  };

  useEffect(() => {
    if (responseCase?.caseObject) {
      setCaseName(responseCase?.caseObject?.case_name);
      setCaseIssues(responseCase?.caseObject?.case_legal_issues);
      setCaseParties(responseCase?.caseObject?.case_parties);
      setDisputeStatus(
        DISPUTE_STATUS_OPTIONS[responseCase?.caseObject?.case_type as keyof typeof DISPUTE_STATUS_OPTIONS],
      );
      setTimeZone(TIMEZONE_OPTIONS[responseCase?.caseObject?.timezone as keyof typeof TIMEZONE_OPTIONS]);
      setCaseType(responseCase?.caseObject?.case_type);
    }
  }, [responseCase]);

  const handleClickBack = () => {
    Swal.fire({
      title: '',
      text: 'Are you sure you want to stop creating the case?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'rgb(37 99 235)',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate(`/app/chronos/explore?matterId=${responseCase?.caseObject?.matter_id}`);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  const handleChangeDisputeStatus = (val: MyOptionType) => {
    setDisputeStatus(val);
  };

  const handleChangeTimeZone = (val: MyOptionType) => {
    setTimeZone(val);
  };

  const MB_150_IN_BYTES = 150 * 1e6;
  const sum = responseDocs?.docs?.reduce((acc: any, doc: any) => acc + (doc?.file_size || 0), 0) || 0;
  const prohibited_docs_length = responseDocs?.docs?.filter((doc: any) => {
    return doc?.file_size > MB_150_IN_BYTES;
  }).length;
  const at_least_one_prohibited = prohibited_docs_length?.length;
  const only_1_prohibited = prohibited_docs_length === 1;
  const GB_2_5_IN_BYTES = 2.5 * 1e9;
  const size_limit_exceeded = sum > GB_2_5_IN_BYTES;
  const count_limit_exceeded = (responseDocs?.docs?.length || 0) > 1000;

  const message = prohibited_docs_length
    ? `${prohibited_docs_length} file${only_1_prohibited ? '' : 's'} exceed${
        only_1_prohibited ? 's' : ''
      } 150MB file size limit`
    : size_limit_exceeded && count_limit_exceeded
    ? 'Exceeded 2.5GB total files size and 1,000 file count limits.'
    : size_limit_exceeded
    ? 'The total files size limit of 2.5GB is exceeded.'
    : count_limit_exceeded
    ? 'The number of files limit is 1,000.'
    : '';

    console.log(Object.values(DISPUTE_STATUS_OPTIONS));
  return (
    <div className="LotaGrotesque flex flex-row relative overflow-auto w-full text-white">
      <div className="font-sans flex flex-col pl-8 w-full items-start justify-start">
        {isLoadingCase ? (
          <div className="w-full h-full flex flex-col items-center justify-start text-black pt-14">
            <StageSpinner className="m-auto" color={'#4161FF'} />
          </div>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer mt-6"
              onClick={() => handleClickBack()}
            />
            <div className="text-black text-3xl not-italic font-bold w-full">New Case</div>
            <div className="" style={{ width: '690px' }}>
              <div className="relative mt-4 flex items-center mr-16">
                <textarea
                  ref={textAreaNameRef}
                  id="caseIssuesInput"
                  className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-2xl bg-white resize-none"
                  style={{
                    backgroundColor: 'rgb(238, 238, 238, 0.5)',
                    overflow: 'hidden',
                    outline: 'none',
                    lineHeight: '26px',
                    height: '66px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    verticalAlign: 'middle',
                    zIndex: 2,
                    border: '1px solid var(--black-10, #E7E7E7)',
                  }}
                  onChange={handleChangeCaseName}
                  value={caseName}
                  placeholder={''}
                  onInput={handleChangeCaseName}
                />

                <label
                  style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
                  htmlFor="caseIssuesInput"
                  className={`absolute left-3 top-5 transition-all duration-300 ${
                    caseName ? 'text-xs transform -translate-y-4' : 'text-base'
                  }`}
                >
                  Name of case
                </label>
              </div>

              <div className="relative mt-4 flex items-center mr-16">
                <textarea
                  ref={textAreaTypeRef}
                  id="caseTypeInput"
                  className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-2xl bg-white resize-none"
                  style={{
                    backgroundColor: 'rgb(238, 238, 238, 0.5)',
                    overflow: 'hidden',
                    border: '1px solid var(--black-10, #E7E7E7)',
                    outline: 'none',
                    lineHeight: '26px',
                    height: '66px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    verticalAlign: 'middle',
                    zIndex: 2,
                  }}
                  onChange={handleChangeCaseType}
                  value={caseType}
                  placeholder={''}
                  onInput={handleChangeCaseType}
                />
                <label
                  style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
                  htmlFor="caseIssuesInput"
                  className={`absolute left-3 top-5 transition-all duration-300 ${
                    caseType ? 'text-xs transform -translate-y-4' : 'text-base'
                  }`}
                >
                  What is the type of case?
                </label>
              </div>

              <div className="relative mt-4 flex items-center mr-8">
                <textarea
                  ref={textAreaIssuesRef}
                  id="caseIssuesInput"
                  className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-2xl bg-white resize-none"
                  style={{
                    backgroundColor: 'rgb(238, 238, 238, 0.5)',
                    overflow: 'hidden',
                    border: '1px solid var(--black-10, #E7E7E7)',
                    outline: 'none',
                    lineHeight: '26px',
                    height: '66px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    verticalAlign: 'middle',
                    zIndex: 2,
                  }}
                  onChange={handleChangeCaseIssues}
                  value={caseIssues}
                  placeholder={''}
                  onInput={handleChangeCaseIssues}
                />
                <label
                  style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
                  htmlFor="caseIssuesInput"
                  className={`absolute left-3 top-5 transition-all duration-300 ${
                    caseIssues ? 'text-xs transform -translate-y-4' : 'text-base'
                  }`}
                >
                  Provide a short but detailed synopsis of the case
                </label>
                <div className="ml-2">
                  <FontAwesomeIcon
                    data-tooltip-id="tooltip-2"
                    icon={faInfoCircle}
                    data-tooltip-content="Please refer to the Case Synopsis page below the User Guide for how to draft a case synopsis"
                    className=" text-gray-700 ml-2 text-sm cursor-pointer"
                    style={{ color: 'var(--colors-primary-slate-400, #8897AE)', width: '16px' }}
                  />
                  <Tooltip
                    id="tooltip-2"
                    className="z-10"
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      borderRadius: '16px',
                      background: '#FFF',
                      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                      color: 'var(--colors-primary-slate-600, #455468)',
                      fontFamily: 'Lota Grotesque',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '-0.3px',
                    }}
                  />
                </div>
              </div>

              <div className="relative mt-4 flex items-center mr-16">
                <textarea
                  ref={textAreaPartiesRef}
                  id="casePartiesInput"
                  className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-2xl bg-white resize-none"
                  style={{
                    backgroundColor: 'rgb(238, 238, 238, 0.5)',
                    overflow: 'hidden',
                    border: '1px solid var(--black-10, #E7E7E7)',
                    outline: 'none',
                    lineHeight: '26px',
                    height: '66px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    verticalAlign: 'middle',
                    zIndex: 2,
                  }}
                  onChange={handleChangeCaseParties}
                  value={caseParties}
                  placeholder={''}
                  onInput={handleChangeCaseParties}
                />
                <label
                  style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
                  htmlFor="caseIssuesInput"
                  className={`absolute left-3 top-5 transition-all duration-300 ${
                    caseParties ? 'text-xs transform -translate-y-4' : 'text-base'
                  }`}
                >
                  Who are the parties in the case?
                </label>
              </div>
              <Select
                options={Object.values(DISPUTE_STATUS_OPTIONS)}
                className={`outline-none bg-white rounded-md mr-16`}
                styles={reactSelectStylesCaseCreator}
                onChange={handleChangeDisputeStatus}
                value={disputeStatus}
                placeholder={'Dispute status'}
              />
              <Select
                options={Object.values(TIMEZONE_OPTIONS)}
                className={`outline-none bg-white rounded-md mr-16`}
                styles={reactSelectStylesCaseCreator}
                onChange={handleChangeTimeZone}
                value={timeZone}
                placeholder={'Are dates American or European?'}
              />
              <div className="text-black text-3xl not-italic font-bold mt-4">
                Files {responseDocs?.docs?.length ? `(${responseDocs?.docs?.length})` : ''}
              </div>
              {!!message && <div className="text-red-700 font-semibold italic">{message}</div>}
              <div
                className="w-108 h-36 flex items-center justify-center bg-white mb-3 cursor-pointer rounded-2xl mt-4 mr-16"
                style={{ border: '1px dashed var(--purple-100, #4161FF)', background: 'rgba(66, 98, 255, 0.05)' }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {posting && (
                  <div className="flex flex-col">
                    <div className="text-gray-900 text-center text-base not-italic font-bold leading-6 mb-4">
                      Uploaded {successfulUploads} of {expectedUploads} files
                    </div>
                    <ProgressBar
                      completed={(100 * successfulUploads) / expectedUploads}
                      customLabel={''}
                      bgColor={'#4161FF'}
                      height={'6px'}
                      isLabelVisible={false}
                    />
                  </div>
                )}
                {!posting &&
                  (isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <div>
                      <button
                        data-tooltip-id="agreed-tooltip"
                        data-tooltip-target="tooltip-hover"
                        data-tooltip-trigger="hover"
                        data-tooltip-content="Supported file types: EML, MHT, DocX, Doc, PDF, and Spreadsheet files (more coming soon). Max file size is 150MB."
                        className="cursor-pointer flex flex-row justify-center items-center h-12 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 shrink-0 ml-2 text-center text-base not-italic font-bold leading-6 text-white px-6 py-4"
                      >
                        <FontAwesomeIcon icon={faFolderPlus} className="text-white mr-2" />
                        Upload Files
                      </button>
                      <Tooltip id="agreed-tooltip" style={TooltipStyles} />

                      <div
                        className="text-center text-sm not-italic font-bold leading-4 mt-4"
                        style={{ color: 'var(--purple-100, #4161FF)' }}
                      >
                        Or drag here
                      </div>
                    </div>
                  ))}
              </div>
              <div className="w-full flex flex-column">
                {responseDocs?.docs && responseDocs?.docs.length > 0 && (
                  <div className="w-full mr-16">
                    {responseDocs?.docs?.map((doc: any) => {
                      const file_size_exceeded = doc.file_size > MB_150_IN_BYTES;
                      return (
                        <div
                          className={`bg-white mb-3 p-3 w-full rounded-lg  ${
                            file_size_exceeded ? ' border border-red-500' : 'border-red-500'
                          }`}
                          key={doc._id}
                        >
                          <div
                            className="w-full flex items-center justify-between"
                            style={{ color: 'var(--dark-blue-80, #373D68)' }}
                          >
                            <div
                              className={`w-1/3 shrink-0 truncate ${
                                file_size_exceeded ? 'text-red-700 font-semibold italic' : '  '
                              }`}
                            >
                              {doc?.file_name?.split('.')?.filter(Boolean)[0]}
                            </div>
                            <div className="w-1/3 shrink-0 flex items-center justify-center">
                              <div
                                className={`flex w-13 h-7 justify-center items-center py-3 rounded-3xl text-sm not-italic font-bold leading-4 uppercase px-2 ${
                                  file_size_exceeded ? 'text-red-600 bg-red-200' : 'bg-blue-50 text-blue-400'
                                }`}
                              >
                                {doc.file_extension ? doc.file_extension.toUpperCase() : '---'}
                              </div>
                            </div>
                            <div
                              className={`text-base not-italic font-medium w-20 ${
                                file_size_exceeded ? 'text-red-700 font-semibold italic' : '  '
                              }`}
                            >
                              {doc.file_size ? `${niceBytes(doc.file_size)}` : '---'}
                            </div>
                            <div
                              className={`flex justify-center items-center px-2 py-3 rounded-lg ml-auto w-24 not-italic shrink-0 font-bold leading-6 cursor-pointer ${
                                file_size_exceeded ? 'text-red-600 bg-red-200' : 'bg-blue-100 text-blue-600'
                              }`}
                              onClick={() => handleRemoveDoc(doc.doc_id)}
                            >
                              {docRemoving === doc.doc_id ? (
                                <StageSpinner className="m-auto" color={'#4161FF'} />
                              ) : (
                                'Remove'
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!isLoadingCase && (
        <div className="w-full flex flex-row h-full sticky top-0 ml-7">
          <button
            style={{ bottom: '10px' }}
            className="absolute cursor-pointer flex flex-row justify-center items-center  h-12 w-96 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:opacity-20 shrink-0 text-center text-base not-italic font-bold leading-6 text-white disabled:cursor-not-allowed px-6 py-4"
            onClick={handleUpdateCase}
            disabled={
              at_least_one_prohibited ||
              isLoadingUpdateCase ||
              !caseName ||
              !caseType ||
              !caseIssues ||
              !caseParties ||
              !disputeStatus ||
              !timeZone ||
              posting ||
              !responseDocs?.docs?.length ||
              size_limit_exceeded ||
              count_limit_exceeded
            }
          >
            {isLoadingUpdateCase ? <StageSpinner className="m-auto" color={'white'} /> : 'Next'}
          </button>
        </div>
      )}
    </div>
  );
};

export default CaseCreator;
