import { SortingFn } from '@tanstack/react-table';

const customDateSortV8: SortingFn<any> = (rowA, rowB, columnId, desc: boolean = false): number => {
  const valA = rowA.getValue(columnId);
  const valB = rowB.getValue(columnId);

  if ((!valA || valA === '') && (!valB || valB === '')) return 0;
  if (!valA || valA === '') return 1;
  if (!valB || valB === '') return -1;

  const dateA = new Date(valA as string);
  const dateB = new Date(valB as string);

  // Compare the dates and return accordingly
  return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

export default customDateSortV8;
