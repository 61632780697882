import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface SearchBoxProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ value, onChange, placeholder, onKeyDown }) => {
  return (
    <div
      className="relative  bg-white"
      style={{ width: '276px', borderRadius: '16px', border: '1px solid var(--black-10, #E7E7E7)' }}
    >
      <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-4 text-gray-400" />
      <input
        className="pl-10 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 bg-white"
        style={{ borderRadius: '16px', height: '48px' }}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder || 'Search cases'}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default SearchBox;
