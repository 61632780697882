import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import { APIBaseChronos } from 'api/hosts';
import { StageSpinner } from 'react-spinners-kit';
import useGetFetchConfig from '../../../../../api/useGetFetchConfig';

interface ViewCellProps {
  document_author: string;
  doc_id: string;
}

const FileAuthorCell = ({ document_author, doc_id }: ViewCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFileAuthor, setCurrentFileAuthor] = useState(document_author);
  const { getFetchConfig } = useGetFetchConfig();

  const { isFetching: isLoadingUpdateFileAuthor, refetch: refetchUpdateFileAuthor } = useQuery(
    'updateFileAuthor',
    () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { fileAuthor: currentFileAuthor } });

      return fetch(`${APIBaseChronos}/client/case/doc/${doc_id}`, fetchConfig)
        .then((res) => {
          setEditMode(false);
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const togleEditMode = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      refetchUpdateFileAuthor();
    }
  };

  const handleChangeFileAuthor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFileAuthor(e.target.value);
  };

  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5">
      <div className="flex flex-row items-center w-full justify-start">
        {editMode === true ? (
          <input
            onChange={handleChangeFileAuthor}
            value={currentFileAuthor}
            className="w-full"
            style={{ outline: 'none', border: '1px solid rgba(0,0,0,0.1)' }}
          />
        ) : isLoadingUpdateFileAuthor ? (
          <StageSpinner className="m-auto" color={'#4161FF'} />
        ) : (
          <div className="w-full text-center">{currentFileAuthor || '-'}</div>
        )}
        <FontAwesomeIcon
          onClick={togleEditMode}
          icon={editMode === true ? faCheck : faPencil}
          className="text-gray-700 mr-2 ml-2 cursor-pointer"
        />
      </div>
    </div>
  );
};

export default FileAuthorCell;
