import { useState, useEffect, useMemo } from 'react';
import { StageSpinner } from 'react-spinners-kit';
import { useQuery } from 'react-query';
import { APIBaseChronos } from 'api/hosts';
import { useTable, Column, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { AdminUser } from 'types';
import { faArrowDown, faArrowUp, faRightLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from '../CaseEditor/components/SearchBox';
import useGetFetchConfig from '../../../api/useGetFetchConfig';

const Admin = () => {
  const [users, setUsers] = useState<AdminUser[]>([]);
  const { fetchConfigGET } = useGetFetchConfig();
  const columns: Column<AdminUser>[] = useMemo(
    () => [
      {
        Header: () => <div className="flex-row items-start justify-start">User Id</div>,
        accessor: 'userId',
        Cell: ({
          cell: {
            row: {
              original: { userId },
            },
          },
        }: any) => <div>{userId}</div>,
      },
      {
        Header: () => <div className="flex-row items-start justify-start">Email</div>,
        accessor: 'email',
        Cell: ({
          cell: {
            row: {
              original: { email },
            },
          },
        }: any) => <div>{email}</div>,
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data: users,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  ) as any;

  const {
    data: responseUsers,
    isLoading: isLoadingUsersTable,
    refetch: refetchUsers,
  } = useQuery(
    ['usersAdmin'],
    () => {
      return fetch(`${APIBaseChronos}/client/org/users`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (responseUsers?.users && responseUsers?.users?.length > 0) {
      setUsers(responseUsers.users);
    }
  }, [responseUsers]);

  useEffect(() => {
    refetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="LotaGrotesque flex flex-col">
      <div className="font-sans flex flex-col md:flex-row items-start justify-start text-white h-screen">
        <div className="flex flex-col items-start justify-start w-full h-full text-black">
          <div className="w-full h-full pt-5 bg-cover bg-center pb-20 pr-28">
            <div className="mb-3 mt-4 flex flex-row w-full justify-end">
              <SearchBox
                value={globalFilter || ''}
                onChange={(value) => setGlobalFilter(value)}
                placeholder={'Search users'}
              />
            </div>
            {isLoadingUsersTable && (
              <div className="w-full h-24 flex items-center justify-center">
                <StageSpinner className="m-auto" color={'#4161FF'} />
              </div>
            )}
            <div
              className="w-full flex flex-column overflow-scroll"
              style={{
                borderRadius: '16px',
                border: '1px solid var(--colors-primary-slate-100, #E9EFF6)',
                background: '#FFF',
              }}
            >
              {users && users.length > 0 && (
                <div className="w-full">
                  <table {...getTableProps()} className="w-full">
                    <thead className="h-12" style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)' }}>
                      {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column: any) => (
                            <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              className="not-italic font-medium"
                            >
                              {generateSortingIndicator(column)}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row: any, i: number) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} style={{ background: i % 2 === 0 ? 'white' : '#F9FAFB' }}>
                            {row.cells.map((cell: any) => {
                              return (
                                <td {...cell.getCellProps()} className="pl-2">
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const generateSortingIndicator = (column: any) => {
  const centerItem = false;
  return (
    <div className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'} pl-2`}>
      {column.render('Header')}
      {column.disableSortBy ? (
        <></>
      ) : column.isSorted ? (
        <FontAwesomeIcon
          icon={column.isSortedDesc ? faArrowDown : faArrowUp}
          className="text-gray-700 mx-2"
          style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faRightLeft}
          style={{ transform: 'rotate(90deg)', color: 'var(--colors-primary-slate-400, #8897AE)' }}
          className="text-gray-700 mx-2 text-sm"
        />
      )}
    </div>
  );
};

export default Admin;
