import moment from 'moment';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { MatterItem } from '../types';

interface MatterItemComponentProps {
  item: MatterItem;
  isCreator: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onView: (id: string) => void;
}

const MatterItemComponent: React.FC<MatterItemComponentProps> = ({ item, isCreator, onEdit, onDelete, onView }) => {
  const [isExpanded, setIsExpanded] = useState(false); // State to manage text expansion
  const handleDelete = (matterId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(matterId);
      }
    });
  };

  return (
    <div className="bg-white w-full rounded-lg px-8 pt-8 flex items-start justify-start flex-col">
      {item.latest_matter_name && (
        <div
          className="text-xl not-italic font-semibold"
          onClick={() => onView(item.matter_id)}
          style={{ color: 'var(--black-100, #1C1C1C)' }}
        >
          {item.latest_matter_name}
        </div>
      )}
      {item.latest_matter_description && (
        <div
          className={`overflow-hidden text-sm not-italic font-normal leading-4 mt-3 ${
            isExpanded ? '' : 'line-clamp-3'
          }`}
          style={{ color: 'var(--black-40, rgba(0, 0, 0, 0.40))' }}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {item.latest_matter_description}
        </div>
      )}
      <div className="overflow-hidden text-green-700 text-sm not-italic font-semibold leading-5 right-0 flex justify-between items-center mt-2">
        Date: {`${moment(item.created_date).format('MMMM DD, YYYY')}`}
      </div>
      <div className="flex flex-row mt-5 w-full">
        <button
          className={`exploreButton cursor-pointer flex justify-center items-center min-w-20 px-4 h-8 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 font-semibold text-sm text-white `}
          onClick={() => onView(item.matter_id)}
        >
          View
        </button>
        {isCreator && (
          <button
            className={`exploreButton cursor-pointer flex justify-center items-center min-w-20 px-4 h-8 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 font-semibold text-sm text-white ml-2`}
            style={{ backgroundColor: 'rgba(65, 97, 255, 0.10)', color: '#3662E3' }}
            onClick={() => onEdit(item.matter_id)}
          >
            Edit
          </button>
        )}
      </div>
      {item.ultimate_edit_date && (
        <div
          className="text-sm not-italic font-normal leading-4 flex items-start bg-yellow-200 mt-3 mb-3"
          style={{ color: 'rgba(0, 0, 0, 0.80)', padding: '8px 15px', borderRadius: '40px' }}
        >
          {`Last updated by ${item.editor_email} on ${moment(item.ultimate_edit_date).format('DD/MM/YYYY')}`}
        </div>
      )}
      {isCreator && false && (
        <div
          className=" cursor-pointer text-xs font-semibold flex items-center rounded-lg text-red-600 bg-red-200 h-8  px-4 ml-auto mb-3"
          onClick={() => handleDelete(item.matter_id)}
        >
          Delete matter
        </div>
      )}
    </div>
  );
};

export default MatterItemComponent;
