import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import { reactSelectStylesCaseCreator } from 'constants/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import { MyOptionType } from 'types'; // Assuming type definitions are correctly placed
import CreateMatterButton from './components/CreateMatterButton';
import TextAreaWithLabel from './components/TextAreaWithLabel';
import UserList from './components/UserList';
import useGetMatter from './hooks/useGetMatter';
import useOrgUsers from './hooks/useOrgUsers';
import useUpdateMatter from './hooks/useUpdateMatter';
import { UserObject, UserObjectResponse } from './types/user';

const MatterCreator: React.FC = () => {
  const navigate = useNavigate();
  const { matterId } = useParams<{ matterId: string }>(); // Get matterId from URL
  const [matterName, setMatterName] = useState<string>('');
  const [matterDescription, setMatterDescription] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<UserObject[]>([]);
  const textAreaNameRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaDescriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const authInfo = useAuthInfo();

  const { data: orgUsers, refetch: refetchOrgUsers } = useOrgUsers();
  const { mutate: updateMatter, isLoading: isLoadingUpdate, data: updateResponse } = useUpdateMatter();

  const { isLoadingMatter, responseMatterData, refetchMatterData } = useGetMatter(matterId || '');

  useEffect(() => {
    if (matterId) {
      refetchMatterData();
    }
  }, [matterId, refetchMatterData]);

  // Pre-fill form when data is fetched
  useEffect(() => {
    console.log('responseMatterData> ', responseMatterData);
    if (responseMatterData) {
      setMatterName(responseMatterData.matterDetails.matter_name);
      setMatterDescription(responseMatterData.matterDetails.matter_description);
      setSelectedUsers(
        responseMatterData.permissions
          .filter((x: any) => x.enabled)
          .map((x: any) => {
            return {
              user_id: x.user_id || '',
              user_email: x.user_email || '',
            };
          }),
      );
    }
  }, [responseMatterData]);

  useEffect(() => {
    // console.log("updateResponse> ", updateResponse);
    if (updateResponse?.matterUpdate) {
      navigate(`/app/chronos/explore?matterId=${updateResponse?.matterUpdate?.matter_id}`);
    }
  }, [navigate, updateResponse]);

  useEffect(() => {
    refetchOrgUsers();
  }, [refetchOrgUsers]);

  const handleChangeMatterName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMatterName(e.target.value);
  };

  const handleChangeMatterDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMatterDescription(e.target.value);
  };

  const handleUpdateMatter = () => {
    updateMatter({
      matterId: matterId || '',
      matterName,
      matterDescription,
      selectedUsers,
    });
  };

  const handleClickBack = () => {
    navigate(`/app/chronos/explorer/matters`);
  };

  const handleRemoveUser = (userId: string) => {
    const indexToRemove = selectedUsers.findIndex((x) => x.user_id === userId);
    const arrayCopy = [...selectedUsers];
    arrayCopy.splice(indexToRemove, 1);
    setSelectedUsers(arrayCopy);
  };

  const handleChangeSelectedUsers = (userToAdd: MyOptionType) => {
    const newSelectedUsers = [
      ...selectedUsers,
      {
        user_id: userToAdd?.value,
        user_email: userToAdd?.label,
      },
    ] as UserObject[];
    setSelectedUsers(newSelectedUsers);
  };

  const canCreateMatter = matterName && matterDescription && selectedUsers;

  const userId = authInfo?.user?.userId;
  const selectedIds = selectedUsers.map((x) => x.user_id);
  const orgUsersOptions = orgUsers?.users
    ?.filter((x: UserObjectResponse) => !selectedIds.includes(x.userId) && x.userId !== userId)
    .map((x: UserObjectResponse) => {
      return {
        value: x.userId,
        label: x.email,
      };
    }) as MyOptionType[];

  return (
    <div className="LotaGrotesque flex flex-row relative overflow-auto w-full text-white">
      {isLoadingMatter ? (
        <div className="w-full h-full flex items-center justify-center">
          <StageSpinner className="m-auto" color={'#081D57'} />
        </div>
      ) : (
        <>
          <div className="font-sans flex flex-col pl-8 w-full items-start justify-start">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer mt-6"
              onClick={handleClickBack}
            />
            <div className="text-black text-3xl not-italic font-bold w-full">New Matter</div>
            <div className="" style={{ width: '690px' }}>
              <TextAreaWithLabel
                ref={textAreaNameRef}
                value={matterName}
                onChange={handleChangeMatterName}
                label="Name of Matter"
              />
              <TextAreaWithLabel
                ref={textAreaDescriptionRef}
                value={matterDescription}
                onChange={handleChangeMatterDescription}
                label="Description"
              />
              <div className="text-black text-3xl not-italic font-bold w-full mt-5">Members ({selectedIds.length})</div>

              <Select
                className={`outline-none bg-white rounded-md mr-16 w-full`}
                styles={reactSelectStylesCaseCreator}
                onChange={handleChangeSelectedUsers}
                options={orgUsersOptions}
                value={[]}
                placeholder="Select team members to include"
              />
              <UserList users={selectedUsers} onRemove={handleRemoveUser} />
            </div>
          </div>
          <div className="w-full flex flex-row h-full sticky top-0 ml-7">
            <CreateMatterButton
              isLoading={isLoadingUpdate || isLoadingMatter}
              isDisabled={!canCreateMatter}
              onClick={handleUpdateMatter}
              text={'Update Matter'}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MatterCreator;
