import { useEffect, useState } from 'react';
import { ReactComponent as ReacFilter } from 'assets/filter.svg';
import { ReactComponent as ReactFilterActive } from 'assets/filter_active.svg';
import Select from 'react-select';
import { ChronosFact } from 'types';
import { Column } from '@tanstack/react-table';

interface OptionType {
  value: string;
  label: string;
}

interface ChronosDocumentCoordinate {
  doc_id: string;
  file_name: string;
}

interface FilterPopupProps {
  column: Column<ChronosFact, unknown>;
  filterValue: FilterOption[];
  allDocuments: ChronosDocumentCoordinate[];
}

interface FilterOption {
  value: string;
  label: string;
}

const FilterPopup = ({ column, filterValue = [], allDocuments = [] }: FilterPopupProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>();
  const deps = JSON.stringify(filterValue);

  // Create a map of unique documents using reduce
  const uniqueDocumentsMap = allDocuments.reduce<Record<string, ChronosDocumentCoordinate>>((acc, doc) => {
    acc[doc.doc_id] = doc;
    return acc;
  }, {});

  const uniqueDocuments = Object.values(uniqueDocumentsMap);

  const options = uniqueDocuments.map((document) => ({
    value: document.doc_id,
    label: document.file_name.split('.').filter(Boolean)[0],
  }));

  const handleApply = () => {
    column.setFilterValue(selectedOptions);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedOptions(filterValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);

  const showActiveFilter = filterValue.length > 0 || isOpen;
  return (
    <div className="relative w-6 h-6 flex items-center justify-center">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer w-5 h-5 rounded-md flex items-center justify-center"
        style={{ background: showActiveFilter ? '#0865E0' : 'none' }}
      >
        {showActiveFilter ? <ReactFilterActive /> : <ReacFilter />}
      </div>
      {isOpen && (
        <div className="absolute z-12 top-8 bg-white border border-gray-300 rounded shadow-xl shrink-0 w-96">
          <div className="p-4">
            <Select
              isMulti
              value={selectedOptions}
              onChange={(selected) => setSelectedOptions(selected as OptionType[])}
              options={options}
              placeholder="Select documents"
            />
            <div className="mt-3 flex justify-end space-x-2">
              <button onClick={() => setIsOpen(false)} className="text-gray-500 hover:text-gray-700">
                Cancel
              </button>
              <button onClick={handleApply} className="text-blue-500 hover:text-blue-700">
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterPopup;
