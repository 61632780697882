import { KEY_VALUE_TYPE } from 'types';

export const TIMEZONE_OPTIONS: KEY_VALUE_TYPE = {
  PreAction: {
    value: 'American',
    label: 'American',
  },
  Claim: {
    value: 'European',
    label: 'European',
  },
};
