import { APIBaseChronos } from 'api/hosts';

import { rankItem } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  OnChangeFn,
  PaginationState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosChronology, ChronosChronologyEvent, ChronosDoc } from 'types';
import useGetFetchConfig from '../../../../api/useGetFetchConfig';
import ChronologyEditorTable, { DraggableRowProps } from './ChronologyEditorTable';
import ChronologyEventAdder from './ChronologyEventAdder';
import ChronologyList from './ChronologyList';
import ChronologyTableToolbar from './ChronologyTableToolbar';
import useGetChronologyColumns from './useGetChronologyColumns';

const LOCAL_STORAGE_CHRONOLOGY_TABLE_COLUMN_VISIBILITY = 'chronology-table-column-visibility';
const initialVisibilityState = { source_text: false };

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export type ReorderArray<T> = (
  arr: T[],
  fromIndex: number,
  toIndex: number,
) => { newChronology: ChronosChronologyEvent[]; updatedRows: { line_id: string; entry_order: number }[] };

const reorderChronology: ReorderArray<ChronosChronologyEvent> = (arr, fromIndex, toIndex) => {
  const newChronology = [...arr];
  const isMovingUp = fromIndex < toIndex;
  const valueToMove = newChronology.splice(fromIndex, 1)[0];
  let editedRows = [];
  if (isMovingUp) {
    newChronology.splice(toIndex, 0, valueToMove);
    editedRows = newChronology.slice(fromIndex, toIndex + 1);
  } else {
    newChronology.splice(toIndex, 0, valueToMove);
    editedRows = newChronology.slice(toIndex, fromIndex + 1);
  }

  const updatedRows = editedRows.map((row) => {
    if (row.entry_order === fromIndex) {
      return {
        line_id: row.line_id,
        t: row.fact_description,
        entry_order: toIndex,
      };
    }
    return {
      line_id: row.line_id,
      t: row.fact_description,
      entry_order: isMovingUp ? row.entry_order - 1 : row.entry_order + 1,
    };
  });

  return { newChronology, updatedRows };
};

const sortByEntryOrder = (a: ChronosChronologyEvent, b: ChronosChronologyEvent) => {
  return a.entry_order - b.entry_order;
};

const fuzzyFilter: FilterFn<ChronosChronologyEvent> = (row, columnId, filterValue: string, addMeta) => {
  if (!filterValue.length) return true;
  const itemRank = rankItem(row.getValue(columnId), filterValue);

  addMeta({
    itemRank,
  });

  const wordsArray = filterValue.split(' ').filter(word => word.trim() !== '');
  console.log(wordsArray);
  const cellValue = row.getValue(columnId) as string || '';
  console.log(cellValue);
  const isWordPresent = wordsArray.every(word => cellValue.toLowerCase().includes(word.toLowerCase()));
  return isWordPresent;
};

const ChronologyEditor = () => {
  const [chronology, setChronology] = useState<ChronosChronologyEvent[]>([]);
  const [chronologies, setChronologies] = useState<ChronosChronology[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialVisibilityState);
  const [searchParams] = useSearchParams();
  const [chronologiesSearchFilter, setChronologiesSearchFilter] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasReorderError, setHasReorderError] = useState(false);
  const [viewDownloadOptions, setViewDownloadOptions] = useState(false);
  const [isRemovingRow, setIsRemovingRow] = useState(false);
  const [docs, setDocs] = useState<ChronosDoc[]>([]);
  const [isFetchingReorder, setIsFetchingReorder] = useState(false);
  const page = searchParams.get('page');
  const chronologyId = searchParams.get('chronologyId') || '';
  const caseId = searchParams.get('caseId');
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: page ? Number(page) : 0,
    pageSize: 100,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setViewDownloadOptions(false); // assuming toggleViewDownloadOptions can take a boolean to explicitly set state
    }
  };

  const onRowReorder: DraggableRowProps['onRowReorder'] = async ({ draggedRow, targetRowOrder }) => {
    setIsFetchingReorder(true);
    const originalRowOrder = draggedRow.index;
    const { updatedRows } = reorderChronology(chronology, originalRowOrder, targetRowOrder);
    const data = { updatedRows };
    const fetchConfig = getFetchConfig({ method: 'PUT', data });
    await fetch(`${APIBaseChronos}/client/case/chronology/order/${chronologyId}`, fetchConfig)
      .then(async (res) => {
        const data = await res.json();
        setChronology(data.updatedRows.sort(sortByEntryOrder));
        const persistantPageIndex = page ? parseInt(page) : 0;
        searchParams.set('page', String(persistantPageIndex));
        navigate(location.pathname + '?' + searchParams.toString());
        setPagination({pageIndex: persistantPageIndex,  pageSize: 100})
        return data;
      })
      .catch(() => {
        setHasReorderError(true);
      });
    setIsFetchingReorder(false);
  };

  const {
    data: responseChronology,
    isLoading: isLoadingChronologyTable,
    refetch: refetchCaseChronology,
  } = useQuery(
    ['userChronology', chronologyId],
    () => {
      if (chronologyId) {
        return fetch(`${APIBaseChronos}/client/case/chronology/${chronologyId}`, fetchConfigGET).then((res) => {
          return res.json() as Promise<{ chronology: ChronosChronologyEvent[] }>;
        });
      }
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  const columns = useGetChronologyColumns({ chronologyId, refetch: refetchCaseChronology });

  const resetVisibleColumns = useCallback(() => {
    const resetValue = {};
    localStorage.setItem(LOCAL_STORAGE_CHRONOLOGY_TABLE_COLUMN_VISIBILITY, JSON.stringify(resetValue));
    setColumnVisibility(resetValue);
  }, []);

  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = (updaterOrValue) => {
    // @ts-ignore
    const newColumnVisibility: VisibilityState = updaterOrValue();
    const newState = { ...columnVisibility, ...newColumnVisibility };

    setColumnVisibility(newState);
    localStorage.setItem(LOCAL_STORAGE_CHRONOLOGY_TABLE_COLUMN_VISIBILITY, JSON.stringify(newState));
  };

  const table = useReactTable({
    columns,
    getPaginationRowModel: getPaginationRowModel(),
    // onPaginationChange,
    data: chronology,
    state: { globalFilter, columnVisibility, pagination },
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.line_id,
    initialState: { pagination },
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange,
  });



  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(
    ['userDocs', caseId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  const {
    data: responseChronologies,
    isFetching: isLoadingChronologies,
    refetch: refetchCaseChronologies,
  } = useQuery(
    ['userChronologies', caseId],
    () => {
      const cleanSearchText = chronologiesSearchFilter?.replace(/\s+/g, ' ').trim() || '';
      return fetch(
        `${APIBaseChronos}/client/case/chronology/byCaseId/${caseId}?search=${cleanSearchText}`,
        fetchConfigGET,
      ).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  async function downloadChronology(format: string) {
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        format,
        caseId,
      },
    });

    setViewDownloadOptions(false);

    const downloadResult = await fetch(
      `${APIBaseChronos}/client/case/chronology/createAndDownloadDocChronology/${chronologyId}`,
      fetchConfig,
    );

    const blob = await downloadResult.blob();
    const link = document.createElement('a');
    const file = new Blob([blob], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);

    const chronologyTitle = chronologies?.filter((x) => x.chronology_id === chronologyId)?.[0]?.latest_title;
    link.download = `${chronologyTitle}.${format}`;
    link.click();

    URL.revokeObjectURL(link.href);
  }

  const { mutate: fetchDownload, isLoading: isLoadingDownload } = useMutation(downloadChronology);

  const handleGoToChronologiesList = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('chronologyId');
    navigate('/app/chronos/case-editor/chronology?' + searchParams.toString(), { replace: true });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const handleNewFactCreated = () => {
    setChronology([]);
    refetchCaseChronology();
    refetchCaseChronologies();
    closeModal();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    const columnVisibilityString = localStorage.getItem(LOCAL_STORAGE_CHRONOLOGY_TABLE_COLUMN_VISIBILITY);
    const columnVisibility = columnVisibilityString ? JSON.parse(columnVisibilityString) : initialVisibilityState;

    if (columnVisibility) {
      setColumnVisibility(columnVisibility);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    refetchCaseChronology();
    refetchCaseChronologies();
    refetchCaseDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chronologyId]);

  useEffect(() => {
    if (responseChronology?.chronology && responseChronology?.chronology?.length > 0) {
      const orderedChronology = responseChronology?.chronology.sort(sortByEntryOrder);
      setChronology(orderedChronology);
    }
  }, [responseChronology]);

  useEffect(() => {
    if (responseChronologies?.chronologies) {
      setChronologies(responseChronologies?.chronologies);
    }
  }, [responseChronologies]);

  useEffect(() => {
    if (responseDocs?.docs && responseDocs?.docs?.length > 0) {
      setDocs(responseDocs.docs);
    }
  }, [responseDocs]);

  const toggleViewDownloadOptions = () => {
    setViewDownloadOptions(!viewDownloadOptions);
  };

  const handleRemoveRow = async (lineId: string) => {
    setIsRemovingRow(true);
    const endpointToUpdate = `${APIBaseChronos}/client/case/chronology/${lineId}`;
    const fetchConfig = getFetchConfig({ method: 'PUT', data: { is_hidden: true } });
    await fetch(endpointToUpdate, fetchConfig)
      .then((res) => {
        refetchCaseChronology();
      })
      .catch((err) => {
        console.error('Fetch Error: ', err);
        Swal.fire({
          title: 'Error on update',
          text: 'There was an error on deleting the row. Please try again later.',
          showConfirmButton: false,
          timer: 3000,
        });
      });
      setIsRemovingRow(false);
    };

    const canGetPrevPage = pageIndex > 0;
    const canGetNextPage = pageIndex < Math.ceil(chronology.length / pageSize) - 1;
  
    const prevPage = () => {
      if (canGetPrevPage) {
        const newPaginationState = {
          pageIndex: pageIndex - 1,
          pageSize: 100,
        }
        setPagination(newPaginationState);
        searchParams.set('page', String(newPaginationState.pageIndex));
        navigate(location.pathname + '?' + searchParams.toString());
      }
    };
  
    const nextPage = () => {
      if (canGetNextPage){
        const newPaginationState = {
          pageIndex: pageIndex + 1,
          pageSize: 100,
        }
        setPagination(newPaginationState);
        searchParams.set('page', String(newPaginationState.pageIndex));
        navigate(location.pathname + '?' + searchParams.toString());
      }
    };
  
    const currentPage = pageIndex + 1;
    const noOfPages = Math.ceil(chronology.length / pageSize);

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <ChronologyEventAdder
          docs={docs}
          handleNewFactCreated={handleNewFactCreated}
          chronology_id={chronologyId || ''}
        />
      </Modal>
      {!chronologyId && (
        <ChronologyList
          caseId={caseId}
          chronologies={chronologies}
          isLoadingChronologies={isLoadingChronologies}
          refetchCaseChronologies={refetchCaseChronologies}
          setChronologiesSearchFilter={setChronologiesSearchFilter}
          chronologiesSearchFilter={chronologiesSearchFilter}
        />
      )}
      {chronologyId && (
        <div>
          {(isFetchingReorder || isRemovingRow ) && (
            <div
              className="fixed top-0 flex justify-center items-center h-full w-full z-50 bg-slate-200 bg-opacity-50"
              style={{ zIndex: 100, marginLeft: '-30px' }}
            >
              <StageSpinner color={'#4161FF'} style={{ left: '-100px' }} />
            </div>
          )}
          <ChronologyTableToolbar
            globalFilter={globalFilter}
            chronologyId={chronologyId}
            isLoadingDownload={isLoadingDownload}
            viewDownloadOptions={viewDownloadOptions}
            setGlobalFilter={setGlobalFilter}
            chronologies={chronologies}
            wrapperRef={wrapperRef}
            fetchDownload={fetchDownload}
            handleGoToChronologiesList={handleGoToChronologiesList}
            toggleViewDownloadOptions={toggleViewDownloadOptions}
            openModal={openModal}
            columns={table.getAllFlatColumns()}
            resetVisibleColumns={resetVisibleColumns}
            canGetPrevPage={canGetPrevPage}
            canGetNextPage={canGetNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            noOfPages={noOfPages}
            isLoadingFacts={isLoadingChronologies}
          />
          {isLoadingChronologyTable && (
            <div className="w-full h-24 flex items-center justify-center">
              <StageSpinner className="m-auto" color={'#4161FF'} />
            </div>
          )}
          <div
            className="w-full flex flex-column overflow-scroll max-h-[calc(100vh-200px)]"
            style={{
              borderRadius: '16px',
              border: '1px solid var(--colors-primary-slate-100, #E9EFF6)',
              background: '#FFF',
            }}
          >
            {!isLoadingChronologyTable && chronology && chronology.length > 0 && (
              <ChronologyEditorTable
                getRowModel={table.getRowModel}
                getHeaderGroups={table.getHeaderGroups}
                onRowReorder={onRowReorder}
                hasReorderError={hasReorderError}
                refetchChronology={refetchCaseChronology}
                handleRemoveRow={handleRemoveRow}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ChronologyEditor;
