export const interestsOptions = [
  'Commercial',
  'Contract',
  'Crime',
  'Data and communications',
  'Employment and work',
  'Environment',
  'Equality and human rights',
  'Family',
  'Finance',
  'Health and social welfare',
  'Insolvency',
  'Intellectual property',
  'Land',
  'Judicial Review',
  'Local government',
  'Personal injury',
  'Planning and construction',
  'Public and constitutional law',
  'Tax',
  'Torts',
  'Transport and shipping',
  'Trusts and personal property',
];

type AdvancedInterestsOptionsType = {
  [key: string]: string[];
};

export const advancedInterestsOptions: AdvancedInterestsOptionsType = {
  'Civil litigation': ['Conflict Of Laws', 'Damages', 'Estoppel', 'Evidence', 'Injunction', 'Practice'],
  Commercial: ['Admiralty', 'Arbitration', 'Contract', 'Insurance'],
  Costs: ['Costs', 'Practice', 'Solicitor'],
  Crime: ['Crime', 'Extradition', 'Road Traffic'],
  Employment: ['Discrimination', 'Employment', 'European Union', 'Industrial Relations', 'Solicitor', 'Trade Union'],
  'European Union': ['Conflict Of Laws', 'European Union'],
  Family: ['Children', 'Divorce', 'Husband And Wife', 'Marriage'],
  'IP and Media': [
    'Confidential Information',
    'Copyright',
    'Data Protection',
    'Defamation',
    'Libel And Slander',
    'Patent',
    'Practice',
    'Trade Mark',
  ],
  'Land law': [
    'Compulsory Purchase',
    'Easement',
    'Highway',
    'Housing',
    'Land',
    'Land Registration',
    'Landlord And Tenant',
    'Mines',
    'Vendor And Purchaser',
  ],
  'Public law': [
    'Contempt Of Court',
    'Discrimination',
    'Highway',
    'Immigration',
    'Justices',
    'Licensing',
    'Local Government',
    'Medical Practitioner',
    'Planning',
    'Road Traffic',
  ],
  Tax: ['European Union', 'Revenue'],
  Tort: ['Damages', 'Negligence', 'Nuisance', 'Tort'],
  'Trusts and Chancery': ['Accord And Satisfaction'],
};
