import {KEY_VALUE_TYPE} from 'types'

export const SORT_BY: KEY_VALUE_TYPE = {
  SCORE_DESC: {
    value: 'score_desc',
    label: 'Best Match',
  },
  CASE_DATE_ASC: {
    value: 'case_date_asc',
    label: 'Case Date ↑',
  },
  CASE_DATE_DESC: {
    value: 'case_date_desc',
    label: 'Case Date ↓',
  },
};

export const SORT_BY_CHRONOS: KEY_VALUE_TYPE = {
  SCORE_DESC: {
    value: 'score_desc',
    label: 'Best Match',
  },
  CASE_DATE_ASC: {
    value: 'case_date_asc',
    label: 'Case Date ↑',
  },
  CASE_DATE_DESC: {
    value: 'case_date_desc',
    label: 'Case Date ↓',
  },
};

export const IMPORTANCY_OPTIONS: KEY_VALUE_TYPE = {
  YES: {
    value: 'yes',
    label: 'YES',
  },
  NO: {
    value: 'no',
    label: 'NO',
  },
};

export const AGREED_OPTIONS: KEY_VALUE_TYPE = {
  Yes: {
    value: 'Yes',
    label: 'Yes',
  },
  "Potentially not agreed": {
    value: 'Potentially not agreed',
    label: 'Potentially not agreed',
  },
  No: {
    value: 'No',
    label: 'No',
  },
};
