import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import * as styledComponents from './styles';
import moment from 'moment';
import useGetFetchConfig from '../../../../../api/useGetFetchConfig';

interface TextInputCellProps {
  entryId: string;
  entryFieldKey: string;
  entryFieldValue: string | Date;
  endpointToUpdate: string;
  editable?: boolean;
}
const DateInputCell = ({ entryId, entryFieldKey, entryFieldValue, endpointToUpdate, editable }: TextInputCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFieldValue, setCurrentFieldValue] = useState<Date | undefined>(
    entryFieldValue ? new Date(entryFieldValue) : undefined,
  );
  const { getFetchConfig } = useGetFetchConfig();

  const { isFetching: isLoadingUpdateFieldValue, refetch: refetchUpdateFieldValue } = useQuery(
    ['updateFieldValue', entryId, entryFieldKey],
    () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { [entryFieldKey]: currentFieldValue } });

      return fetch(endpointToUpdate, fetchConfig)
        .then((res) => {
          setEditMode(false);
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error on update',
            text: 'There was an error on updating the fields. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const togleEditMode = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      refetchUpdateFieldValue();
    }
  };

  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5">
      <div className="flex flex-row items-center w-full justify-start">
        {editMode === true ? (
          <styledComponents.ContainerDatePicker>
            <DatePicker
              selected={currentFieldValue}
              onChange={(date: Date) => setCurrentFieldValue(date)}
              placeholderText="Date"
              dateFormat="MMM dd,yyyy"
            />
          </styledComponents.ContainerDatePicker>
        ) : isLoadingUpdateFieldValue ? (
          <StageSpinner className="m-auto" color={'#4161FF'} />
        ) : (
          <div className="w-full text-center">
            {currentFieldValue ? moment(currentFieldValue).format('MMM DD, YYYY') : '-'}
          </div>
        )}
        {editable && (
          <FontAwesomeIcon
            onClick={togleEditMode}
            icon={editMode === true ? faCheck : faPencil}
            className="text-gray-700 mr-2 ml-2 cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default DateInputCell;
