import React, { useState } from 'react';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

type SubInterestsInterface = {
  interestKey: string,
  currentInterests: string[];
  updateSubInterests: (keyInterest: string, newSubInterests: string[]) => void;
  isLoadingUpdate: boolean;
  availableOptions: string[];
};
const SubInterests: React.FC<SubInterestsInterface> = ({ availableOptions, interestKey, currentInterests, updateSubInterests, isLoadingUpdate }) => {
  const [selectedInterests, setSelectedInterests] = useState<string[]>(currentInterests);
  const handleClickInterest = (option: string) => {
    if (currentInterests.includes(option)) {
      const newSelectedInterests = currentInterests.filter((x) => !(x === option));
      setSelectedInterests(newSelectedInterests);
      updateSubInterests(interestKey, newSelectedInterests)
    } else if (currentInterests.length === 5) {
      Swal.fire({
        title: 'Max 5 Subinterests',
        text: 'You can only select a maximum of 5 interests',
        showConfirmButton: false,
        background: '#FFFFFF',
        color: '#081D57',
      });
    } else {
      const newSelectedInterestsKeys = [...currentInterests, option];
      setSelectedInterests(newSelectedInterestsKeys);
      updateSubInterests(interestKey, newSelectedInterestsKeys);
    }
  };

  return (
    <div className="LotaGrotesque flex flex-col">
      <div className="w-full max-w flex flex-row flex-wrap mt-8" style={{ maxWidth: '653px' }}>
        {availableOptions.map((option, idx) => {
          const currentlySelected = currentInterests.includes(option);
          const toChangeSelection =
            isLoadingUpdate &&
            ((selectedInterests.includes(option) && !currentInterests.includes(option)) ||
              (currentInterests.includes(option) && !selectedInterests.includes(option)));
          return (
            <div
              key={idx}
              className="flex flex-col justify-center items-center h-12 bg-white rounded-full py-2 not-italic font-semibold text-sm px-12 mr-4 mb-4 cursor-pointer"
              style={{
                border: '1px solid #8C8D96',
                background: currentlySelected ? '#DCEAFE' : '#FFFFFF',
                color: currentlySelected ? '#081D57' : '#585858',
                borderWidth: currentlySelected ? '0px' : '1px',
              }}
              onClick={() => handleClickInterest(option)}
            >
              {toChangeSelection ? <StageSpinner className="m-auto" color={'#081D57'} /> : option}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubInterests;
