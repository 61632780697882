import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faComment, faStar } from '@fortawesome/free-solid-svg-icons';
import { MODES } from 'constants/modes';
import { MARGIN_TOP, LINE_HEIGHT } from 'constants/styles';
import Quill from 'quill';
import Joyride, { Step, STATUS, CallBackProps } from 'react-joyride';
import { TOUR_STEPS, TOUR_KEYS } from 'tour';
import { tourStyles } from 'constants/tour';
import { UserContext } from 'Contexts/User';
interface DocEditorProps {
  pageYPosition: number;
  scrollTop: number;
  newComment: string;
  setNewComment: (val: string) => void;
  selectedStartIndex: number;
  setSelectedStartIndex: (val: number) => void;
  selectedEndIndex: number;
  setSelectedEndIndex: (val: number) => void;
  menuOpen: boolean;
  setMenuOpen: (val: boolean) => void;
  mode: string;
  setMode: (val: string) => void;
  commentToShow: string;
  HTMLCopy: String;
  setHTMLCopy: (val: String) => void;
  currentQuillEditor: Quill | undefined;
}

export const DocEditor: React.FC<DocEditorProps> = ({
  pageYPosition,
  scrollTop,
  newComment,
  setNewComment,
  selectedStartIndex,
  setSelectedStartIndex,
  selectedEndIndex,
  setSelectedEndIndex,
  menuOpen,
  setMenuOpen,
  mode,
  setMode,
  commentToShow,
  HTMLCopy,
  setHTMLCopy,
  currentQuillEditor,
}) => {
  const { markTourKeyDone } = useContext(UserContext);

  const updateContent = (newContent: string) => {
    setHTMLCopy(newContent);
  };

  const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.target.value);
  };

  const getMaxId = (innerHTML: string) => {
    const abstractDiv = document.createElement('div');
    abstractDiv.innerHTML = innerHTML;
    const aTagsArray = Array.from(abstractDiv.getElementsByTagName('a'));
    if (!aTagsArray.length) return -1;
    const tagsIds = Array.from(abstractDiv.getElementsByTagName('a')).map((aTag) => {
      return parseInt(aTag.href.split('_')[1]);
    });
    return Math.max(...tagsIds);
  };

  const handleClickAddComment = () => {
    if (currentQuillEditor?.root.innerHTML) {
      const maxId = getMaxId(currentQuillEditor?.root.innerHTML);
      currentQuillEditor?.formatText(
        selectedStartIndex,
        selectedEndIndex - selectedStartIndex,
        'link',
        `COMMENT_${maxId + 1}_${newComment}`,
      );
      currentQuillEditor?.formatText(
        selectedStartIndex,
        selectedEndIndex - selectedStartIndex,
        'background',
        '#fff2cc',
      );
      currentQuillEditor?.formatText(selectedStartIndex, selectedEndIndex - selectedStartIndex, 'underline', 'none');
      currentQuillEditor?.formatText(selectedStartIndex, selectedEndIndex - selectedStartIndex, 'color', 'black');
      updateContent(currentQuillEditor?.root.innerHTML);
      setNewComment('');
      setSelectedStartIndex(-1);
      setSelectedEndIndex(-1);
      setMenuOpen(false);
      setMode(MODES.MENU.id);
    }
  };

  const handleClickAddMarkAsImportant = () => {
    if (currentQuillEditor?.root.innerHTML) {
      const maxId = getMaxId(currentQuillEditor?.root.innerHTML);
      currentQuillEditor?.formatText(
        selectedStartIndex,
        selectedEndIndex - selectedStartIndex,
        'link',
        `IMPORTANT_${maxId + 1}_${newComment}`,
      ); //"COMMENT", "IMPORTANT", "NOT-RIGHT"
      currentQuillEditor?.formatText(
        selectedStartIndex,
        selectedEndIndex - selectedStartIndex,
        'background',
        '#d9ead3',
      );
      currentQuillEditor?.formatText(selectedStartIndex, selectedEndIndex - selectedStartIndex, 'underline', 'none');
      currentQuillEditor?.formatText(selectedStartIndex, selectedEndIndex - selectedStartIndex, 'color', 'black');
      updateContent(currentQuillEditor?.root.innerHTML);
      setNewComment('');
      setSelectedStartIndex(-1);
      setSelectedEndIndex(-1);
      setMenuOpen(false);
      setMode(MODES.MENU.id);
    }
  };

  const handleClickAddMarkAsNotRight = () => {
    if (currentQuillEditor?.root.innerHTML) {
      const maxId = getMaxId(currentQuillEditor?.root.innerHTML);
      currentQuillEditor?.formatText(
        selectedStartIndex,
        selectedEndIndex - selectedStartIndex,
        'link',
        `NOT-RIGHT_${maxId + 1}_${newComment}`,
      ); //"COMMENT", "IMPORTANT", "NOT-RIGHT"
      currentQuillEditor?.formatText(
        selectedStartIndex,
        selectedEndIndex - selectedStartIndex,
        'background',
        '#f4cccc',
      );
      currentQuillEditor?.formatText(selectedStartIndex, selectedEndIndex - selectedStartIndex, 'underline', 'none');
      currentQuillEditor?.formatText(selectedStartIndex, selectedEndIndex - selectedStartIndex, 'color', 'black');
      updateContent(currentQuillEditor?.root.innerHTML);
      setNewComment('');
      setSelectedStartIndex(-1);
      setSelectedEndIndex(-1);
      setMenuOpen(false);
      setMode(MODES.MENU.id);
    }
  };

  const handleClickCancelComment = () => {
    setNewComment('');
    setSelectedStartIndex(-1);
    setSelectedEndIndex(-1);
    setMenuOpen(false);
    setMode(MODES.MENU.id);
  };

  const handleOnClickComment = () => {
    setMode(MODES.COMMENT.id);
  };

  const handleOnClickMarkAsImport = () => {
    setMode(MODES.IMPORTANT.id);
  };

  const handleOnClickNotRight = () => {
    setMode(MODES.NOT_RIGHT.id);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as 'finished' | 'skipped')) {
      markTourKeyDone(TOUR_KEYS.ACTIONS_SECTION);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full md:w-1/4 h-auto md:h-full relative overflow-y-hidden">
      {menuOpen && localStorage.getItem(TOUR_KEYS.ACTIONS_SECTION) !== 'true' && (
        <Joyride
          steps={TOUR_STEPS.ACTIONS_SECTION as Step[]}
          continuous
          styles={tourStyles}
          callback={handleJoyrideCallback}
          run
          disableScrolling
        />
      )}
      <div
        className="menuView w-full pr-5 rounded-lg absolute"
        style={{ top: `${pageYPosition - scrollTop - MARGIN_TOP - LINE_HEIGHT}px` }}
      >
        {mode === MODES.MENU.id && menuOpen && (
          <div className={`relative flex flex-col items-start w-full`}>
            <button
              className="LotaGrotesque not-italic font-normal text-base text-blue-600 flex items-center justify-start p-3 mb-1 rounded-lg flex-grow-0 bg-white h-10 w-48"
              style={{ boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.02)' }}
              onClick={handleOnClickComment}
            >
              <FontAwesomeIcon icon={faComment} className="pr-2" />
              Comment
            </button>
            <button
              className="LotaGrotesque not-italic font-normal text-base text-blue-600 flex items-center justify-start p-3 mb-1 rounded-lg flex-grow-0 bg-white h-10 w-48"
              style={{ boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.02)' }}
              onClick={handleOnClickMarkAsImport}
            >
              <FontAwesomeIcon icon={faStar} className="pr-2" />
              Mark as important
            </button>
            <button
              className="LotaGrotesque not-italic font-normal text-base text-blue-600 flex items-center justify-start p-3 mb-1 rounded-lg flex-grow-0 bg-white h-10 w-48"
              style={{ boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.02)' }}
              onClick={handleOnClickNotRight}
            >
              <FontAwesomeIcon icon={faThumbsDown} className="pr-2" />
              Not quite right
            </button>
          </div>
        )}
        {mode === MODES.COMMENT.id && menuOpen && (
          <div
            className="relative flex flex-col items-start p-6 bg-white w-full"
            style={{ boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.14)', borderRadius: '24px' }}
          >
            <textarea
              className="w-full bg-white rounded-md not-italic font-normal text-base text-gray-600 focus:outline-none flex flex-start flex-col items-start p-4"
              style={{
                border: '1px solid #cecece',
                minHeight: '83px',
                boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.02)',
              }}
              onChange={handleChangeComment}
            />
            <div className="flex w-full flex-row items-start justify-between pt-3">
              <button
                className="LotaGrotesque not-italic font-normal text-base text-gray-600 flex-none order-none flex-grow-0 bg-transparent"
                onClick={handleClickAddComment}
              >
                Comment
              </button>
              <button
                className="LotaGrotesque not-italic font-normal text-base text-gray-600 flex-none order-none flex-grow-0 bg-transparent"
                onClick={handleClickCancelComment}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {mode === MODES.NOT_RIGHT.id && menuOpen && (
          <div
            className="relative flex flex-col items-start p-6 bg-white w-full"
            style={{ boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.14)', borderRadius: '24px' }}
          >
            <div className="not-italic font-normal text-base text-black mb-2">How can Wexler improve this:</div>
            <textarea
              className="w-full bg-white rounded-md not-italic font-normal text-base text-gray-600 focus:outline-none flex flex-start flex-col items-start p-4"
              style={{
                border: '1px solid #cecece',
                minHeight: '83px',
                boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.02)',
              }}
              onChange={handleChangeComment}
            />
            <div className="flex w-full flex-row items-start justify-between pt-3">
              <button
                className="LotaGrotesque not-italic font-normal text-base text-gray-600 flex-none order-none flex-grow-0 bg-transparent"
                onClick={handleClickAddMarkAsNotRight}
              >
                Comment
              </button>
              <button
                className="LotaGrotesque not-italic font-normal text-base text-gray-600 flex-none order-none flex-grow-0 bg-transparent"
                onClick={handleClickCancelComment}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {mode === MODES.IMPORTANT.id && menuOpen && (
          <div
            className="relative flex flex-col items-start p-6 bg-white w-full"
            style={{ boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.14)', borderRadius: '24px' }}
          >
            <div className="not-italic font-normal text-base text-black mb-2">
              Marking as important. Add further comment:
            </div>
            <textarea
              className="w-full bg-white rounded-md not-italic font-normal text-base text-gray-600 focus:outline-none flex flex-start flex-col items-start p-4"
              style={{
                border: '1px solid #cecece',
                minHeight: '83px',
                boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.02)',
              }}
              onChange={handleChangeComment}
            />
            <div className="flex w-full flex-row items-start justify-between pt-3">
              <button
                className="LotaGrotesque not-italic font-normal text-base text-gray-600 flex-none order-none flex-grow-0 bg-transparent"
                onClick={handleClickAddMarkAsImportant}
              >
                Comment
              </button>
              <button
                className="LotaGrotesque not-italic font-normal text-base text-gray-600 flex-none order-none flex-grow-0 bg-transparent"
                onClick={handleClickCancelComment}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {mode === MODES.VIEW_COMMENT.id && menuOpen && (
          <div
            className="relative flex flex-col items-start p-6 bg-white w-full"
            style={{ boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.14)', borderRadius: '24px' }}
          >
            <div className="not-italic font-normal text-base text-black" style={{ minHeight: '83px' }}>
              {commentToShow}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocEditor;
