import React, { useState } from 'react';
import SideMenu from './SideMenu/index';

const Header = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <SideMenu open={true} toggle={toggle} />
  );
};

export default Header;
