import React, { useContext } from 'react';
import { parsedCaseBody } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Joyride, { Step, STATUS, CallBackProps } from 'react-joyride';
import { tourStyles } from 'constants/tour';
import { TOUR_STEPS, TOUR_KEYS } from 'tour';
import { UserContext } from 'Contexts/User';
import ReactQuill from 'react-quill';
import * as styledComponents from './styles';
interface OriginalViewProps {
  caseBody: parsedCaseBody[];
  header_text_html: string;
  setViewType: (val: string) => void;
}

export const OriginalView: React.FC<OriginalViewProps> = ({ caseBody, setViewType, header_text_html }) => {
  const { markTourKeyDone } = useContext(UserContext);

  const handleClickClose = () => {
    setViewType('NORMAL');
  };
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as 'finished' | 'skipped')) {
      markTourKeyDone(TOUR_KEYS.SIDE_VIEW_SECTION);
    }
  };

  return (
    <aside className="flex flex-col items-start justify-start w-7/12 pl-3 h-full overflow-scroll relative">
      <Joyride
        steps={TOUR_STEPS.SIDE_VIEW_SECTION as Step[]}
        styles={tourStyles}
        callback={handleJoyrideCallback}
        continuous
        disableScrolling
        run
      />
      <div className="closeSideView sticky right-10 top-10 ml-auto mr-10 cursor-pointer" onClick={handleClickClose}>
        <FontAwesomeIcon icon={faClose} className="text-black fa-lg" />
      </div>
      <div className="bg-white py-16 text-black not-italic text-base px-28 w-full rounded-lg">
        <div className="mb-5">
          <styledComponents.EditorContainer>
            <ReactQuill
              value={header_text_html}
              onChange={() => {}}
              modules={{
                toolbar: false,
              }}
              readOnly={true}
            />
          </styledComponents.EditorContainer>
        </div>
        {caseBody.map((caseSection) => {
          return (
            <div>
              <div className="font-bold mb-11">{caseSection.section_title}</div>
              <div>
                {caseSection.section_content.map((contentObj) => {
                  return (
                    <div className="flex flex-row">
                      <div className="w-10 shrink-0">{contentObj.paragraph_id}</div>
                      <styledComponents.EditorContainerParagraph
                        className="mb-5"
                        id={`paragraph_id_${contentObj.paragraph_id}`}
                      >
                        <ReactQuill
                          value={contentObj.paragraph_content}
                          onChange={() => {}}
                          modules={{
                            toolbar: false,
                          }}
                          readOnly={true}
                        />
                      </styledComponents.EditorContainerParagraph>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </aside>
  );
};

export default OriginalView;
