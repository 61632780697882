import React, { RefObject } from 'react';
import { StageSpinner } from 'react-spinners-kit';
import { DocumentSection } from 'types';

interface IndexSectionProps {
  documentSections: DocumentSection[];
  listRef: RefObject<HTMLDivElement>;
  HTMLCopy: String;
  isLoading: Boolean;
  defaultFirstIndexTitle?: String;
}

export const IndexSection: React.FC<IndexSectionProps> = ({
  documentSections,
  listRef,
  HTMLCopy,
  isLoading,
  defaultFirstIndexTitle,
}) => {
  const getCommentsCount = (innerHTML: string) => {
    const abstractDiv = document.createElement('div');
    abstractDiv.innerHTML = innerHTML;
    const aTagsArray = Array.from(abstractDiv.getElementsByTagName('a')).filter(
      (aElement) => !aElement.getAttribute('href')?.startsWith('REFERENCE'),
    );
    if (!aTagsArray.length) return 0;
    return aTagsArray.length;
  };

  const headers = [...HTMLCopy.matchAll(/<h2>.+?<\/h2>/g)].map((dirtyHeader) =>
    dirtyHeader[0].substring(4, dirtyHeader[0].length - 5),
  );
  const contents = [
    ...[...HTMLCopy.matchAll(/<\/h2>.+?<h2>/g)].map((dirtyContent) =>
      dirtyContent[0].substring(5, dirtyContent[0].length - 4),
    ),
    HTMLCopy.substring(HTMLCopy.lastIndexOf('</h2>') + 5),
  ];

  const handleOnClickTitle = (idx: number) => {
    listRef.current?.getElementsByTagName('h2')[idx].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <aside className="flex flex-col items-start justify-start w-full md:w-1/4 h-auto md:h-full p-10">
      {(documentSections.length || isLoading) && (
        <div className="not-italic font-semibold text-base text-gray-600 flex-none order-none flex-grow-0 mb-5">
          Jump to
        </div>
      )}
      {headers.map((header, idx) => {
        return (
          <div className="w-full min-h-10 mb-3 flex flex-row not-italic font-semibold text-sm text-gray-900" key={idx}>
            <div
              className="bg-white rounded-lg p-3 flex items-start justify-start h-full mr-4 cursor-pointer w-full"
              onClick={() => handleOnClickTitle(idx)}
            >
              {header || (idx === 0 ? defaultFirstIndexTitle : '')}
            </div>
            <div
              className="bg-gray-100 rounded-lg p-3 w-10 flex items-center justify-center h-full shrink-0 ml-auto cursor-pointer"
              onClick={() => handleOnClickTitle(idx)}
            >
              {getCommentsCount(contents[idx])}
            </div>
          </div>
        );
      })}
      {isLoading && (
        <div className="w-ful h-full flex items-center justify-center">
          <StageSpinner className="m-auto" color={'#081D57'} />
        </div>
      )}
    </aside>
  );
};

export default IndexSection;
