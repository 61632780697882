import moment from 'moment';

interface ViewCellProps {
  date: Date;
  format?: string;
}

const DateCell = ({ date, format }: ViewCellProps) => {
  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5" style={{ maxWidth: '127px' }}>
      <div className="flex flex-row items-center w-full justify-start">{moment(date).format(format || 'MMM DD, YYYY')}</div>
    </div>
  );
};

export default DateCell;
