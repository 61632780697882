import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { APIBaseChronos } from 'api/hosts';
import { StageSpinner } from 'react-spinners-kit';
import { useNavigate, useLocation } from 'react-router-dom';
import useGetFetchConfig from '../../../../../api/useGetFetchConfig';

interface ViewCellProps {
  file_name: string;
  file_extension: string;
  doc_id: string;
}
const FileNameCell = ({ file_name, file_extension, doc_id }: ViewCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFileName, setCurrentFileName] = useState(file_name?.split('.')?.filter(Boolean)[0]);
  const location = useLocation();
  const navigate = useNavigate();
  const { getFetchConfig } = useGetFetchConfig();

  const goToDocReference = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', doc_id);
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  };

  const { isFetching: isLoadingUpdateFieldName } = useQuery(
    'updateFieldName',
    () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { fileName: file_name } });

      return fetch(`${APIBaseChronos}/client/case/doc/${doc_id}`, fetchConfig)
        .then((res) => {
          setEditMode(false);
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleChangeFilename = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFileName(e.target.value);
  };

  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5">
      <div className="flex flex-row items-center w-full justify-start">
        {editMode === true ? (
          <input
            onChange={handleChangeFilename}
            value={currentFileName}
            className="w-full"
            style={{ outline: 'none', border: '1px solid rgba(0,0,0,0.1)' }}
          />
        ) : isLoadingUpdateFieldName ? (
          <StageSpinner className="m-auto" color={'#4161FF'} />
        ) : (
          <div
            className="w-full cursor-pointer text-blue-500 hover:underline hover:text-blue-600"
            onClick={goToDocReference}
          >
            {currentFileName}
          </div>
        )}
      </div>
      <div
        className="text-xs not-italic font-normal mt-2"
        style={{ color: 'var(--colors-primary-slate-500, #5E718D)' }}
      >
        {file_extension?.toUpperCase()}
      </div>
    </div>
  );
};

export default FileNameCell;
