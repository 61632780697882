export const DOC_TYPES = {
  BD_ARTICLE: {
    value: 'BD_ARTICLE',
    label: 'BD Article',
  },
  INTERNAL_UPDATE: {
    value: 'INTERNAL_UPDATE',
    label: 'Internal Update',
  },
  CLIENT_MEMO: {
    value: 'CLIENT_MEMO',
    label: 'Memo (coming soon)',
    disabled: true,
  },
  NEWSLETTER: {
    value: 'NEWSLETTER',
    label: 'Newsletter (coming soon)',
    disabled: true,
  },
};

export const DOC_LENGTHS = {
  SHORT: {
    value: 'SHORT',
    label: 'Short',
  },
  MEDIUM: {
    value: 'MEDIUM',
    label: 'Medium',
  },
  LONG: {
    value: 'LONG',
    label: 'Long',
  },
};

export const DOC_AUDIENCES = {
  'NON-LAWYERS': {
    value: 'NON-LAWYERS',
    label: 'Non-lawyers',
  },
  PROFESSIONALS: {
    value: 'PROFESSIONALS',
    label: 'Professionals familiar with the law',
  },
  LAWYERS: {
    value: 'LAWYERS',
    label: 'Lawyers',
  },
};
