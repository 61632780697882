import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { StageSpinner } from 'react-spinners-kit';

export interface PaginationProps {
  currentPage: number;
  noOfPages: number;
  canGetPrevPage: boolean;
  canGetNextPage: boolean;
  prevPage: () => void;
  nextPage: () => void;
  isLoadingFacts: boolean;
}

const Pagination = ({
  currentPage,
  noOfPages,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  isLoadingFacts,
}: PaginationProps) => {
  const prevButtonDisabled = isLoadingFacts || !canGetPrevPage;
  const nextButtonDisabled = isLoadingFacts || !canGetNextPage;
  const currentPageLabel = noOfPages === 0 ? 0 : currentPage;
  return (
    <div className="flex items-center w-1/5 justify-between">
      <button
        style={{ backgroundColor: '#ECEFFF' }}
        className={`px-2 py-3 rounded-lg mr-3 cursor-pointer disabled:cursor-not-allowed hover:opacity-80`}
        onClick={prevPage}
        disabled={prevButtonDisabled}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-gray-700 mx-2"
          style={{ color: !prevButtonDisabled ? '#4161FF' : 'var(--colors-primary-slate-400, #8897AE)' }}
        />
      </button>
      {isLoadingFacts ? (
        <StageSpinner className="m-auto" color={'#4161FF'} />
      ) : (
        <p className="text-center">
          Page {currentPageLabel} of {noOfPages}
        </p>
      )}
      <button
        style={{ backgroundColor: '#ECEFFF' }}
        className={`px-2 py-3 rounded-lg ml-3 cursor-pointer disabled:cursor-not-allowed hover:opacity-80`}
        onClick={nextPage}
        disabled={nextButtonDisabled}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-gray-700 mx-2"
          style={{ color: !nextButtonDisabled ? '#4161FF' : 'var(--colors-primary-slate-400, #8897AE)' }}
        />
      </button>
    </div>
  );
};

export default memo(Pagination);
