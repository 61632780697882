import { Column } from '@tanstack/react-table';
import { useState } from 'react';
import { hiddenColumns as factHiddenColumns } from '../FactsEditor/FactsEditorTable';
import { hiddenColumns as chronologyHiddenColumns } from '../ChronologyEditor/ChronologyEditorTable';

interface ShowHideColumnsProps {
  columns: Column<any>[];
  columnLabels: Record<string, string>;
  resetVisibleColumns: () => void;
}

const ShowHideColumns = ({ columns, resetVisibleColumns, columnLabels }: ShowHideColumnsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const numberOfColumnsHidden = columns.filter((col) => !col.getIsVisible()).length;
  return (
    <>
      <button
        className="flex justify-center items-center px-2 py-3 rounded-lg not-italic font-bold  w-56 cursor-pointer disabled:cursor-not-allowed"
        onClick={toggleOpen}
        style={{
          backgroundColor: '#ECEFFF',
          color: '#4161FF',
        }}
      >
        {numberOfColumnsHidden > 0
          ? `${numberOfColumnsHidden} ${numberOfColumnsHidden === 1 ? 'column' : 'columns'} hidden`
          : 'Show/hide columns'}
      </button>
      {isOpen && (
        <div
          data-testid="filter-dropdown"
          className="absolute flex flex-col z-10 top-8 bg-white border border-gray-300 rounded shadow-xl shrink-0 w-52 p-4"
        >
          <div className="flex flex-col mb-3">
            {columns.map((column) => {
              if (factHiddenColumns.concat(chronologyHiddenColumns).includes(column.id)) {
                return null;
              }

              const onChange = column.getToggleVisibilityHandler();

              return (
                <div key={column.id} className="px-1">
                  <label className="cursor-pointer">
                    <input
                      className="mr-2"
                      {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: onChange,
                      }}
                    />
                    {columnLabels[column.id]}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="self-end mr-3">
            {numberOfColumnsHidden > 0 && (
              <button onClick={resetVisibleColumns} className="text-slate-400 hover:text-slate-700	mr-4">
                Show all
              </button>
            )}
            <button onClick={toggleOpen} className="text-blue-500 hover:text-blue-700">
              Done
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowHideColumns;
