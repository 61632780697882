import { Column } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { StageSpinner } from 'react-spinners-kit';

import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { APIBaseChronos } from '../../../../../api/hosts';
import useGetFetchConfig from '../../../../../api/useGetFetchConfig';
import { CheckState, ChronosDoc, ChronosFact } from '../../../../../types';
import Pagination, { PaginationProps } from '../../../Pagination';
import ShowHideColumns from '../../ShowHideColumns';
import SearchBox from '../../components/SearchBox';
import FactAdder from '../FactAdder';
import { factColumnLabels } from '../useGetFactColumns';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

interface FactsEditorToolbarProps {
  caseId: string | null;
  docId?: string;
  globalFilter: string;
  resultsLength: number;
  facts: ChronosFact[];
  checkedRows: Record<string, CheckState>;
  isLoadingChecked: boolean;
  isDateDirty: boolean;
  refetchCaseFacts: () => void;
  clearAllFilters: () => void;
  resetVisibleColumns: () => void;
  setGlobalFilter: (value: string) => void;
  columns: Column<ChronosFact>[];
}

const FactsEditorToolbar = ({
  docId,
  caseId,
  facts,
  columns,
  checkedRows,
  globalFilter,
  refetchCaseFacts,
  clearAllFilters,
  setGlobalFilter,
  isLoadingFacts,
  isLoadingChecked,
  resetVisibleColumns,
  resultsLength,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  isDateDirty,
}: FactsEditorToolbarProps & PaginationProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [docs, setDocs] = useState<ChronosDoc[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(
    ['userDocs', docId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (responseDocs?.docs && responseDocs?.docs?.length > 0) {
      setDocs(responseDocs.docs);
    }
  }, [responseDocs]);

  useEffect(() => {
    refetchCaseDocs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  const handleNewFactCreated = () => {
    refetchCaseFacts();
    closeModal();
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const { isFetching: isLoadingStartChronology, refetch: refetchStartChronology } = useQuery(
    ['startChronology'],
    () => {
      const fetchConfig = getFetchConfig({ method: 'POST', data: { caseId: caseId, runId: facts[0].run_id } });

      return fetch(`${APIBaseChronos}/client/case/chronology`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .then((resposeJson) => {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set('chronologyId', resposeJson.lambdaResponse.chronology_id);
          navigate('/app/chronos/case-editor/chronology?' + searchParams.toString(), { replace: true });
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error while creating the chronology',
            text: 'Please try again later',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const calculateFactsAndDisabledState = () => {
    return Object.values(checkedRows).reduce(
      (acc, fact) => {
        if (fact.isIncluded) {
          acc.factsIncludedLength += 1;
          if (fact.isVerified) {
            acc.factsVerifiedLength += 1;
          } else {
            acc.createChronologyDisabled = true; // At least one included fact is not verified.
          }
        }
        return acc;
      },
      { factsIncludedLength: 0, factsVerifiedLength: 0, createChronologyDisabled: false },
    );
  };

  const { factsIncludedLength } = useMemo(() => {
    const results = calculateFactsAndDisabledState();
    results.createChronologyDisabled = results.createChronologyDisabled || results.factsIncludedLength === 0;

    return results;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRows]);

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <FactAdder docs={docs} handleNewFactCreated={handleNewFactCreated} docId={docId} />
      </Modal>
      <div className="mb-3 mt-4 flex flex-row items-center justify-between">
        <div className="flex">
          <div
            className="flex justify-center items-center px-2 py-3 rounded-lg not-italic font-bold w-56 cursor-pointer"
            style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
            onClick={() => setModalIsOpen(true)}
          >
            Add Fact
          </div>
          {!docId && (
            <button
              disabled={!factsIncludedLength || isLoadingFacts || isLoadingChecked}
              className="ml-3 flex justify-center items-center px-2 py-3 rounded-lg not-italic font-bold w-56 cursor-pointer disabled:cursor-not-allowed"
              style={{
                backgroundColor: '#ECEFFF',
                color: '#4161FF',
                opacity: !factsIncludedLength || isLoadingFacts || isLoadingChecked ? 0.2 : 1,
              }}
              onClick={() => refetchStartChronology()}
            >
              {isLoadingStartChronology ? <StageSpinner className="m-auto" color={'#4161FF'} /> : 'Create Chronology'}
            </button>
          )}
        </div>
        {!docId && (
          <Pagination
            canGetPrevPage={canGetPrevPage}
            canGetNextPage={canGetNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            noOfPages={noOfPages}
            isLoadingFacts={isLoadingFacts}
          />
        )}
      </div>
      {!docId && (
        <div className="flex items-center mb-3">
          <ShowHideColumns
            columns={columns}
            resetVisibleColumns={resetVisibleColumns}
            columnLabels={factColumnLabels}
          />
          <div>
            <p className="text-gray-900 font-bold leading-6 ml-8">{resultsLength} fact results</p>
            <p className="text-gray-900 font-bold leading-6 ml-8">
              {factsIncludedLength} facts included in the chronology
            </p>
          </div>
          <button
            className="text-gray-900 not-italic font-bold leading-6 ml-auto mr-5 cursor-pointer"
            onClick={clearAllFilters}
          >
            Clear filters
          </button>
          <SearchBox value={globalFilter} placeholder={`Search ${resultsLength} facts`} onChange={setGlobalFilter} />
        </div>
      )}
      {isDateDirty && (
        <div className="flex justify-center mb-3">
          <button
            className="ml-3 flex justify-center items-center px-4 py-3 rounded-lg not-italic font-bold cursor-pointer disabled:cursor-not-allowed"
            style={{
              backgroundColor: '#ECEFFF',
              color: '#4161FF',
            }}
            onClick={refetchCaseFacts}
          >
            Click to update dates in table
          </button>
        </div>
      )}
    </>
  );
};

export default FactsEditorToolbar;
