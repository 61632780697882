import { ColumnDef, SortingFn, SortingState } from '@tanstack/react-table';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { IMPORTANCY_OPTIONS } from 'constants/chronos_table_options';
import { TooltipStyles } from 'constants/styles';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { APIBaseChronos } from '../../../../api/hosts';
import { CheckState, ChronosDocumentCoordinate, ChronosFact } from '../../../../types';
import customDateSortV8 from '../../helpers/customDateSortV8';
import DateCell from '../DocumentsEditor/DateCell';
import { default as SelectInputCell } from '../components/ContentiousInputCell';
import DateInputCell from '../components/DateInputCell';
import IncludeAndVerifyCell, { SetCheckedRows } from '../components/IncludeAndVerifyCell';
import TextInputCell from '../components/TextInputCell';
import { filterFns } from './index';

interface useGetFactColumnsProps {
  setCheckedRows: SetCheckedRows;
  setIsDateDirty: Dispatch<SetStateAction<boolean>>;
  checkedRows: Record<string, CheckState>;
  sorting: SortingState;
  callback: ()=>void;
}

const sortDate: SortingFn<ChronosFact> = customDateSortV8;

export const factColumnLabels: Record<string, string> = {
  date_of_subject_text: 'Date',
  action_described: 'Action Described',
  subject_matter: 'Subject Matter',
  source_text: 'Source Text',
  source_doc: 'Source Doc',
  document_date_text: 'Document Date',
  date_uploaded: 'Upload Date',
  important: 'Important',
  included: 'Included',
  relevance_reason: 'Significance',
};

const useGetFactColumns = ({ checkedRows, setCheckedRows, setIsDateDirty, sorting, callback }: useGetFactColumnsProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const goToDocReference = (newDocId: string, newEventId: string, pageNumber: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', newDocId);
    searchParams.set('eventId', newEventId);
    searchParams.set('pageNumber', String(pageNumber));
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  };

  const sortDateEmptyToBootom = (rowA: any, rowB: any, columnIdRef: any): number => {
    const columnId = (columnIdRef === 'date_of_subject_text') ? 'date_of_subject': columnIdRef;
    const desc = sorting.filter((x) => x.id === 'date_of_subject_text')[0]?.desc;
    if (!rowA.getValue(columnId) && !rowB.getValue(columnId)) {
      return 0;
    }

    if (!rowA.getValue(columnId)) {
      return desc ? -1 : 1;
    }

    if (!rowB.getValue(columnId)) {
      return desc ? 1 : -1;
    }

    return rowA.getValue(columnId).localeCompare(rowB.getValue(columnId));
  };

  const columns = useMemo<ColumnDef<ChronosFact, any>[]>(
    () => [
      {
        header: () => (
          <div className="flex flexrow items-center">
            <ReactInfo
              data-tooltip-id="date-subject-tooltip"
              data-tooltip-content="Date of the fact"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="date-subject-tooltip" style={TooltipStyles} />
            Date
          </div>
        ),
        accessorKey: 'date_of_subject',
        enableColumnFilter: false,
        sortingFn: sortDateEmptyToBootom as SortingFn<ChronosFact>,
        cell: ({
          cell: {
            row: {
              original: { date_of_subject, event_id },
            },
          },
        }: any) => (
          <DateInputCell
            editable={true}
            entryId={event_id}
            entryFieldKey={'date_of_subject'}
            entryFieldValue={date_of_subject}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="date-subject-text-tooltip"
              data-tooltip-content="Date of the fact"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="date-subject-text-tooltip" style={TooltipStyles} />
            {factColumnLabels.date_of_subject_text}
          </div>
        ),
        accessorKey: 'date_of_subject_text',
        enableColumnFilter: false,
        minSize: 80,
        sortingFn: sortDateEmptyToBootom as SortingFn<ChronosFact>,
        cell: ({
          cell: {
            row: {
              original: { event_id, date_of_subject_text, is_future_date },
            },
          },
        }: any) => (
          <div className="flex flex-row items-center">
            {!!is_future_date && (
              <ReactInfo
                data-tooltip-id={`fact-date-text-tooltip-${event_id}`}
                data-tooltip-content="Hypothetical future date"
                className="text-gray-700 mr-2 text-sm cursor-pointer"
                style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
              />
            )}
            {!!is_future_date && <Tooltip id={`fact-date-text-tooltip-${event_id}`} style={TooltipStyles} />}
            <TextInputCell
              editable={true}
              entryId={event_id}
              entryFieldKey={'date_of_subject_text'}
              entryFieldValue={date_of_subject_text}
              endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
              setIsDateDirty={setIsDateDirty}
              callback={callback}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="actiondescribed-tooltip"
              data-tooltip-content="Description of the event or action taken"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="actiondescribed-tooltip" style={TooltipStyles} />
            {factColumnLabels.action_described}
          </div>
        ),
        accessorKey: 'action_described',
        minSize: 260,
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { action_described, event_id },
            },
          },
        }: any) => (
          <TextInputCell
            className={'max-w-md'}
            editable={true}
            entryId={event_id}
            entryFieldKey={'action_described'}
            entryFieldValue={action_described}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="subject-matter-tooltip"
              data-tooltip-content="High level subject matter of the fact within the dispute"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="subject-matter-tooltip" style={TooltipStyles} />
            {factColumnLabels.subject_matter}
          </div>
        ),
        minSize: 150,
        accessorKey: 'subject_matter',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { subject_matter, event_id },
            },
          },
        }: any) => (
          <TextInputCell
            editable={true}
            entryId={event_id}
            entryFieldKey={'subject_matter'}
            entryFieldValue={subject_matter}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="source-text-tooltip"
              data-tooltip-content="Source text of the event"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="source-text-tooltip" style={TooltipStyles} />
            {factColumnLabels.source_text}
          </div>
        ),
        minSize: 150,
        accessorKey: 'source_text',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { source_text, event_id },
            },
          },
        }: any) => (
          <TextInputCell
            html={true}
            className={'max-w-md'}
            editable={false}
            entryId={event_id}
            entryFieldKey={'source_text'}
            entryFieldValue={source_text || ''}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="sourcedoc-tooltip"
              data-tooltip-content="Document from which fact was extracted, click to view source text"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="sourcedoc-tooltip" style={TooltipStyles} />
            {factColumnLabels.source_doc}
          </div>
        ),
        minSize: 128,
        size: 128,
        maxSize: 128,
        accessorKey: 'source_doc',
        filterFn: filterFns.multiSelectDoc,
        cell: ({
          cell: {
            row: {
              original: { documents, event_id },
            },
          },
        }: any) => (
          <div className="flex flex-col w-32">
            {documents.map((document: ChronosDocumentCoordinate, idx: number) => {
              const pageNumber = document.coordinate_details[0]?.n_page || 1;
              const file = document?.file_name?.split('.')?.filter(Boolean)[0];
              return (
                <div
                  key={document.doc_id}
                  className="cursor-pointer text-blue-500 hover:underline hover:text-blue-600 text-ellipsis overflow-hidden whitespace-nowrap"
                  onClick={() => goToDocReference(document.doc_id, event_id, pageNumber)}
                  data-tooltip-id={`sourcedoc-link-tooltip-${event_id}-${idx}`}
                  data-tooltip-content={file}
                >
                  <Tooltip id={`sourcedoc-link-tooltip-${event_id}-${idx}`} style={TooltipStyles} />
                  {file}
                </div>
              );
            })}
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flexrow items-center">
            <ReactInfo
              data-tooltip-id="document-date-tooltip"
              data-tooltip-content="Document Date"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="document-date-tooltip" style={TooltipStyles} />
            {factColumnLabels.document_date_text}
          </div>
        ),
        minSize: 100,
        accessorKey: 'document_date',
        enableColumnFilter: false,
        sortingFn: sortDate,
        cell: ({
          cell: {
            row: {
              original: { document_date, event_id },
            },
          },
        }: any) => (
          <DateInputCell
            editable={true}
            entryId={event_id}
            entryFieldKey={'document_date'}
            entryFieldValue={document_date}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <p>{factColumnLabels.date_uploaded}</p>
          </div>
        ),
        accessorKey: 'date_uploaded',
        enableColumnFilter: false,
        minSize: 130,
        cell: ({
          cell: {
            row: {
              original: { date_uploaded },
            },
          },
        }: any) => <DateCell format={'DD MMMM YYYY'} date={date_uploaded} />,
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="document-date-text-tooltip"
              data-tooltip-content="Document Date"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="document-date-text-tooltip" style={TooltipStyles} />
            {factColumnLabels.document_date_text}
          </div>
        ),
        minSize: 100,
        accessorKey: 'document_date_text',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { event_id, document_date_text },
            },
          },
        }: any) => (
          <TextInputCell
            editable={true}
            entryId={event_id}
            entryFieldKey={'document_date_text'}
            entryFieldValue={document_date_text}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="important-tooltip"
              data-tooltip-content="Is this fact important to the case, as extracted by Wexler"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="important-tooltip" style={TooltipStyles} />
            {factColumnLabels.important}
          </div>
        ),
        minSize: 100,
        accessorKey: 'important',
        filterFn: filterFns.stringMatch,
        enableColumnFilter: true,
        cell: ({
          cell: {
            row: {
              original: { important, event_id },
            },
          },
        }: any) => (
          <SelectInputCell
            className={'mr-5'}
            entryId={event_id}
            entryFieldKey={'important'}
            entryFieldValue={important}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
            options={IMPORTANCY_OPTIONS}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="significance-tooltip"
              data-tooltip-content="A short analysis of the significance of the fact as it pertains to the synopsis and the context of the document it is extracted from."
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="significance-tooltip" style={TooltipStyles} />
            {factColumnLabels.relevance_reason}
          </div>
        ),
        minSize: 260,
        maxSize: 700,
        accessorKey: 'relevance_reason',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { event_id, relevance_reason },
            },
          },
        }: any) => (
          <TextInputCell
            editable={true}
            entryId={event_id}
            entryFieldKey={'relevance_reason'}
            entryFieldValue={relevance_reason}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => {
          return <div className="flex flex-row items-start justify-center">{factColumnLabels.included}</div>;
        },
        minSize: 140,
        accessorKey: 'included',
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({
          cell: {
            row: {
              original: { event_id, editor_id_email },
            },
          },
        }: any) => {
          return (
            <IncludeAndVerifyCell
              included={checkedRows[event_id].isIncluded}
              event_id={event_id}
              verified={checkedRows[event_id].isVerified}
              verified_by_email={checkedRows[event_id].verified_by_email}
              editor_id_email={editor_id_email}
              verified_date={checkedRows[event_id].verified_date}
              setCheckedRows={setCheckedRows}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedRows, sorting],
  );

  return columns;
};

export default useGetFactColumns;
