import React, { useState, createRef, useEffect, useContext } from 'react';
import QuillEditorAdvanced from 'components/QuillEditorAdvanced';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { MODES } from 'constants/modes';
import { APIBase } from 'api/hosts';
import { UserContext } from 'Contexts/User';
import { HotjarContext } from 'Contexts/Hotjar';
import moment from 'moment';
import { Summary } from 'types';
import Quill from 'quill';
import ActionsSectionAdvanced from '../ActionsSectionAdvanced';
import IndexSectionAdvanced from '../IndexSectionAdvanced';
import LiabilityDisclaimer from '../LiabilityDisclaimer';
import Joyride, { Step, STATUS, CallBackProps } from 'react-joyride';
import { tourStyles } from 'constants/tour';
import OriginalView from '../OriginalView';
import { TOUR_STEPS, TOUR_KEYS } from 'tour';
import useGetFetchConfig from '../../api/useGetFetchConfig';

const useDownloadSummaryDocument = (summaryId: string, APIBase: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const {
    isFetching: isFetchingDownloadSummary,
    refetch: fetchDownload,
    data: blob,
  } = useQuery(
    ['downloadSummaryDocument', summaryId],
    async () => {
      const response = await fetch(`${APIBase}/client/summary/createAndDownload/${summaryId}`, fetchConfigGET);
      if (!response.ok) {
        throw new Error('Error fetching document summary');
      }
      return response.blob();
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (blob) {
      const link = document.createElement('a');
      const file = new Blob([blob], { type: 'text/plain' });
      link.href = URL.createObjectURL(file);
      link.download = `SummaryDocument-${summaryId}.docx`; // Adjust the file naming convention as needed
      document.body.appendChild(link); // Append link to body to make it work in Firefox
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link); // Clean up
    }
  }, [blob, summaryId]);

  return { isFetchingDownloadSummary, fetchDownload };
};

function Editor() {
  const listRef = createRef<HTMLDivElement>();
  const { summaryId } = useParams();
  const navigate = useNavigate();
  const [pageYPosition, setPageYPosition] = useState(-1000);
  const [scrollTop, setScrollTop] = useState(0);
  const [newComment, setNewComment] = useState('');
  const [selectedStartIndex, setSelectedStartIndex] = useState(-1);
  const [selectedEndIndex, setSelectedEndIndex] = useState(-1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mode, setMode] = useState(MODES.MENU.id);
  const [commentToShow, setCommentToShow] = useState('');
  const [viewType, setViewType] = useState('NORMAL');
  const [summaryHTML, setSummaryHTML] = useState('');
  const [summarizationResponse, setSummarizationResponse] = useState<Summary>({
    _id: '',
    title: '',
    editor_title: '',
    date: new Date(),
    code: '',
    description: '',
    alert: '',
    case_body: [],
    categoryTagsA: '',
    categoryTagsB: '',
    summary_sections: [],
    summary_html: '',
    neutral_citation: '',
    case_url: '',
    is_summary_complete: true,
    is_summary_processing: true,
    is_reporting_restrictions: false,
    is_parser_error: false,
    case_date: new Date(),
    last_update_date: new Date(),
    is_parsed: true,
    original_id: '',
    summary_time_estimate: 10,
    dateSummaryRequest: new Date(Date.now()),
    categorySubjectMatter: [],
    categoryLegalTopic: [],
    categorySubjectMatterNew: '',
    categoryLegalTopicNew: '',
    header_text: '',
    header_text_html: '',
  });
  const [HTMLCopy, setHTMLCopy] = useState<String>('');
  const [lastSaveTime, setLastSavetime] = useState(new Date(Date.now()));
  const { hotjar } = useContext(HotjarContext);
  const [activeTour, setActiveTour] = useState(false);
  const [currentQuillEditor, setCurrentQuillEditor] = useState<Quill>();
  const { markTourKeyDone } = useContext(UserContext);
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const {
    isLoading: isLoadingInitialData,
    data: initialData,
    refetch: initialFetch,
  } = useQuery(
    ['summaryByIdResult', summaryId],
    () => {
      return fetch(`${APIBase}/client/summary/${summaryId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
    },
  );

  const { isFetching: isFetchingUpdate, refetch: refetchUpdateOrCopy } = useQuery(
    'summaryUpdateOrCopyById',
    async () => {
      if (summarizationResponse._id) {
        const fetchConfig = getFetchConfig({ method: 'PUT', data: { summary_html: HTMLCopy } });

        setLastSavetime(new Date(Date.now()));
        const responseJson = await fetch(`${APIBase}/client/summary/${summarizationResponse._id}`, fetchConfig).then(
          (res) => {
            return res.json();
          },
        );
        if (summarizationResponse._id !== responseJson.updatedSummary._id) {
          navigate(`/app/editor/${responseJson.updatedSummary._id}`);
        } else if (!summarizationResponse.is_summary_complete) {
          setSummarizationResponse(responseJson.updatedSummary);
          setSummaryHTML(responseJson.updatedSummary.summary_html);
          setHTMLCopy(responseJson.updatedSummary.summary_html);
        }
      }
    },
    {
      enabled: true,
      refetchInterval: 3000,
    },
  );

  useQuery(
    'startSummaryById',
    () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { summary_html: HTMLCopy } });

      return fetch(`${APIBase}/client/summary/start/${summaryId}`, fetchConfig).then((res) => {
        return res.json();
      });
    },
    {
      cacheTime: 0,
      enabled:
        !summarizationResponse.is_summary_complete &&
        !summarizationResponse.is_summary_processing &&
        !summarizationResponse.original_id,
    },
  );

  useEffect(() => {
    if (initialData) {
      setSummarizationResponse(initialData?.summary);
      setSummaryHTML(initialData?.summary?.summary_html);
      setHTMLCopy(initialData?.summary?.summary_html);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  useEffect(() => {
    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryId]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  const handleClickNextStep = () => {
    if (!summarizationResponse.is_summary_processing) {
      hotjar.event('from-editor-compose-click');
      refetchUpdateOrCopy();
      navigate(`/app/generator/${summarizationResponse._id}`);
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as 'finished' | 'skipped')) {
      markTourKeyDone(TOUR_KEYS.EDITOR_SECTION);
    }
  };

  const { isFetchingDownloadSummary, fetchDownload } = useDownloadSummaryDocument(summaryId || '', APIBase);

  const handleClickDownload = () => {
    hotjar.event('download-summary-click');
    fetchDownload();
  };

  const estimatedEndTime = moment(new Date(summarizationResponse.dateSummaryRequest))
    .add(summarizationResponse.summary_time_estimate as number, 'minutes')
    .format('h:mm A');

  const tourEnabled = !!(
    summaryHTML &&
    summarizationResponse.original_id &&
    TOUR_STEPS.EDITOR_SECTION.length &&
    localStorage.getItem(TOUR_KEYS.EDITOR_SECTION) !== 'true'
  );

  useEffect(() => {
    if (tourEnabled) {
      setTimeout(() => {
        setActiveTour(true);
      }, 1000);
    }
  }, [tourEnabled]);

  return (
    <div className="flex flex-col">
      {activeTour && (
        <Joyride
          steps={TOUR_STEPS.EDITOR_SECTION as Step[]}
          continuous
          styles={tourStyles}
          callback={handleJoyrideCallback}
          run
        />
      )}
      <div className="font-sans flex flex-col md:flex-row items-start justify-start max-w-full pt-7 text-white h-screen">
        {viewType === 'SIDE_TO_SIDE' ? (
          <></>
        ) : (
          <IndexSectionAdvanced
            {...{
              documentSections: summarizationResponse.summary_sections,
              listRef,
              HTMLCopy,
              isLoading: isLoadingInitialData,
            }}
          />
        )}
        <div
          className="flex flex-col items-start justify-start p-5 w-full md:w-2/4 h-full overflow-scroll"
          style={{ width: '1050px' }}
          onScroll={handleScroll}
        >
          {(isLoadingInitialData || !summarizationResponse.original_id) && (
            <div className="text-sm pt-5 w-full flex flex-col items-center justify-center italic text-black">
              <StageSpinner className="m-auto" color={'#081D57'} />
              {!summarizationResponse.original_id && (
                <div className="mt-2">Loading tonally neutral case summary for you</div>
              )}
            </div>
          )}
          {summarizationResponse.original_id && !isLoadingInitialData && (
            <div className="w-full bg-white px-3 py-4 rounded-t-lg">
              <div className="w-full flex flex-row bg-gray-200 rounded-lg p-2" style={{ border: '1px solid #ECECEC' }}>
                <button
                  className="goToCompose flex flex-row justify-center items-center w-full h-12 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-400"
                  onClick={handleClickNextStep}
                  disabled={!!summarizationResponse.is_summary_processing || !summarizationResponse.is_summary_complete}
                >
                  {summarizationResponse.is_summary_processing || !summarizationResponse.is_summary_complete
                    ? `Summarisation in progress, estimated completion time is ${estimatedEndTime}. An email will be sent when complete`
                    : 'Continue to Compose'}
                </button>
              </div>
            </div>
          )}

          {summarizationResponse.original_id && !isLoadingInitialData && (
            <div ref={listRef} className="w-full bg-white py-16 text-black not-italic text-base px-28">
              {!isLoadingInitialData && summarizationResponse.is_summary_complete && (
                <div className="w-full shrink-0 bg-white text-black italic bold text-sm my-0 py-0 flex justify-start items-center flex-row mb-4">
                  Last saved edit: {moment(lastSaveTime).format('DD MMM h:mm:ss a')}{' '}
                  {isFetchingUpdate && <div className="text-xs ml-4">Saving changes</div>}
                </div>
              )}

              <div className="pb-9">
                {summarizationResponse.editor_title && (
                  <div className="text-2xl pb-5 font-bold">{summarizationResponse.editor_title}</div>
                )}
                {summarizationResponse.case_url && viewType !== 'SIDE_TO_SIDE' && (
                  <div
                    className="text-base text-blue-600 cursor-pointer mb-4"
                    onClick={() => setViewType('SIDE_TO_SIDE')}
                  >
                    [View original transcript]
                  </div>
                )}
                {summarizationResponse.case_url && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-blue-600 cursor-pointer"
                    href={summarizationResponse.case_url}
                  >
                    [View on National Archives]
                  </a>
                )}
              </div>
              {summaryHTML && (
                <div className="docView">
                  <QuillEditorAdvanced
                    content={summaryHTML}
                    setHTMLCopy={setHTMLCopy}
                    setMenuOpen={setMenuOpen}
                    setPageYPosition={setPageYPosition}
                    scrollTop={scrollTop}
                    setCurrentQuillEditor={setCurrentQuillEditor}
                    setMode={setMode}
                    setCommentToShow={setCommentToShow}
                    menuOpen={menuOpen}
                    setSelectedEndIndex={setSelectedEndIndex}
                    setSelectedStartIndex={setSelectedStartIndex}
                    setViewType={setViewType}
                    viewType={viewType}
                  />
                </div>
              )}
              {(summarizationResponse.is_summary_processing || !summarizationResponse.is_summary_complete) && (
                <div className="text-sm pt-5 w-full flex flex-col items-center justify-center italic">
                  <StageSpinner className="m-auto" color={'#081D57'} />
                  <div className="mt-2">
                    Summarization is in progress. Estimated completion time: {estimatedEndTime}
                  </div>
                </div>
              )}
            </div>
          )}
          {summarizationResponse.original_id && !isLoadingInitialData && (
            <>
              <div className="w-full bg-white px-3 py-4 rounded-b-lg">
                <div
                  className="w-full flex flex-row bg-gray-200 rounded-lg p-2"
                  style={{ border: '1px solid #ECECEC' }}
                >
                  {summarizationResponse.is_summary_complete && (
                    <button
                      className="flex flex-row justify-center items-center w-full h-12 bg-blue-600 rounded-lg not-italic font-semibold text-white mr-2"
                      onClick={handleClickDownload}
                    >
                      {isFetchingDownloadSummary ? (
                        <StageSpinner className="m-auto" color={'#FFFFFF'} />
                      ) : (
                        'Download to .docx'
                      )}

                      <FontAwesomeIcon icon={faDownload} className="pl-2" />
                    </button>
                  )}

                  <button
                    className="flex flex-row justify-center items-center w-full h-12 bg-blue-600 rounded-lg hover:bg-blue-500  disabled:bg-gray-400"
                    onClick={handleClickNextStep}
                    disabled={
                      !!summarizationResponse.is_summary_processing || !summarizationResponse.is_summary_complete
                    }
                  >
                    {summarizationResponse.is_summary_processing || !summarizationResponse.is_summary_complete
                      ? `Summarisation in progress, estimated completion time is ${estimatedEndTime}. An email will be sent when complete`
                      : 'Continue to Compose'}
                  </button>
                </div>
              </div>
              <LiabilityDisclaimer />
            </>
          )}
        </div>
        {viewType === 'SIDE_TO_SIDE' ? (
          <OriginalView
            caseBody={summarizationResponse.case_body}
            setViewType={setViewType}
            header_text_html={summarizationResponse.header_text_html}
          />
        ) : (
          <ActionsSectionAdvanced
            {...{
              pageYPosition,
              scrollTop,
              newComment,
              setNewComment,
              selectedStartIndex,
              setSelectedStartIndex,
              selectedEndIndex,
              setSelectedEndIndex,
              menuOpen,
              setMenuOpen,
              mode,
              setMode,
              commentToShow,
              HTMLCopy,
              setHTMLCopy,
              currentQuillEditor,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Editor;
