import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { APIBaseChronos } from 'api/hosts';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import useGetFetchConfig from '../../../../../api/useGetFetchConfig';

interface PersonAdderProps {
  case_id: string;
  handleNewPersonCreated: () => void;
}
const PersonAdder = ({ case_id, handleNewPersonCreated }: PersonAdderProps) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const { getFetchConfig } = useGetFetchConfig();

  const { isFetching: isLoadingAddNewPerson, refetch: refetchAddNewPerson } = useQuery(
    ['addPersonQuery', case_id],
    () => {
      const fetchConfig = getFetchConfig({ method: 'POST', data: { name, description } });

      return fetch(`${APIBaseChronos}/client/case/person/${case_id}`, fetchConfig)
        .then((res) => {
          Swal.fire({
            title: 'New person created succesfully',
            text: 'A new person was created succesfully',
            showConfirmButton: false,
            timer: 2500,
          });
          handleNewPersonCreated();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error while adding new person',
            text: 'There was an error while adding new person. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };
  const handleAddPerson = () => {
    refetchAddNewPerson();
  };

  return (
    <div>
      <div className="h-12 pl-3 w-full focus:outline-none text-black text-3xl not-italic font-bold placeholder:text-gray-400 rounded-lg bg-white mt-4">
        New Person
      </div>
      <input
        onChange={handleChangeName}
        className="h-12 pl-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={name}
        placeholder="Name"
      ></input>
      <input
        onChange={handleChangeDescription}
        className="h-12 pl-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={description}
        placeholder="Description"
      ></input>
      <button
        onClick={handleAddPerson}
        className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold w-56 cursor-pointer ml-auto"
        style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
      >
        {isLoadingAddNewPerson ? <StageSpinner className="m-auto" color={'#4161FF'} /> : 'Add'}
      </button>
    </div>
  );
};

export default PersonAdder;
