import {
  faBookAtlas,
  faBookOpen,
  faDoorOpen,
  faEnvelope,
  faGear,
  faPhone
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLogoutFunction } from '@propelauth/react';
import { settingsHost } from 'api/settings_hosts';
import { ReactComponent as ProjectIcon } from 'assets/project.svg';
import React, { useState } from 'react';
// import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as styledComponents from './styles';
// import { UserContext } from 'Contexts/User';

interface SideMenuProps {
  open: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ open }) => {
  // const { restartTourDone } = useContext(UserContext);
  // const [showResearchLinks, setShowResearchLinks] = useState<boolean>(false);
  // const [showLitigationLinks, setShowLitigationLinks] = useState<boolean>(true);
  const [compressed, setCompressed] = useState<boolean>(false);
  const logoutFn = useLogoutFunction();

  const handleClickLogout = (): void => {
    logoutFn(true);
    Swal.fire({
      title: 'Succcesfully logged out',
      text: 'Hope to see you soon',
      showConfirmButton: false,
      timer: 3000,
      background: '#FFFFFF',
      color: '#081D57',
    });
  };

  // const handleClickTutorial = (): void => {
  //   restartTourDone();
  // };

  const handleClickSettings = (): void => {
    window.open(settingsHost, '_self');
  };

  return (
    <styledComponents.ContainerPanel open={open} compressed={compressed}>
      <styledComponents.ContainerMenu className="w-full h-full">
        <div
          className="not-italic font-semibold text-3xl text-white  cursor-pointer mb-10"
          onClick={() => setCompressed(!compressed)}
        >
          {compressed ? 'w' : 'wexler.ai'}
        </div>

        {/* <div
        className="text-xs not-italic font-bold leading-6 uppercase mb-6 w-full flex flex-row items-center justify-center cursor-pointer"
        onClick={() => {
          if (!showResearchLinks) {
            setShowResearchLinks(true);
            setShowLitigationLinks(false);
          } else {
            setShowResearchLinks(false);
          }
        }}
      >
        RESEARCH
        <FontAwesomeIcon
          icon={showResearchLinks ? faChevronDown : faChevronUp}
          className="mr-3 text-white w-4 h-4 ml-auto"
        />
      </div>
      {showResearchLinks && (
        <>
          <Link
            to="app/explore"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
          >
            <ProjectIcon className="mr-3" /> Search
          </Link>
          <Link
            to="app/my-projects"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
          >
            <ProjectIcon className="mr-3" /> My Cases
          </Link>
          <Link
            to="app/my-compositions"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
          >
            <ProjectIcon className="mr-3" /> My Compositions
          </Link>
          <Link
            to="app/interests"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
          >
            <ProjectIcon className="mr-3" /> Interests
          </Link>
          <Link
            to="app/explore"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
            onClick={handleClickTutorial}
          >
            <ProjectIcon className="mr-3 text-white" /> Tutorial
          </Link>
        </>
      )} */}

        {/* <div
        className="text-xs not-italic font-bold leading-6 uppercase mb-6 w-full flex flex-row items-center justify-center cursor-pointer"
        onClick={() => {
          if (!showLitigationLinks) {
            setShowLitigationLinks(true);
            // setShowResearchLinks(false);
          } else {
            setShowLitigationLinks(false);
          }
        }}
      >
        LITIGATION PREPARATION
        <FontAwesomeIcon
          icon={showLitigationLinks ? faChevronDown : faChevronUp}
          className="mr-3 text-white w-4 h-4 ml-auto"
        />
      </div> */}
        <>
          <Link
            to="app/chronos/matters"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
          >
            <ProjectIcon className="mr-3 w-5 text-white" /> {!compressed && 'Chronos'}
          </Link>
          {/* <Link
            to="app/chronos/admin"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
          >
            <ProjectIcon className="mr-3 text-white" /> Admin
          </Link> */}
          <Link
            to="app/chronos/guide"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
          >
            <FontAwesomeIcon icon={faBookAtlas} className="mr-3  w-5 text-white" /> {!compressed && 'User Guide'}
          </Link>
          <Link
            to="app/chronos/case-synopsis"
            className="LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center"
          >
            <FontAwesomeIcon icon={faBookOpen} className="mr-3  w-5 text-white" />
            {!compressed && 'Case Synopsis'}
          </Link>
        </>
        <a
          href="mailto:support@wexler.ai"
          className="LotaGrotesque not-italic font-normal text-base text-white cursor-pointer h-11 flex flex-row items-center  mt-auto"
        >
          <FontAwesomeIcon icon={faEnvelope} className="mr-3 w-5 text-white" />
          {!compressed && 'support@wexler.ai'}
        </a>
        <a
          href="tel:+442080409060"
          className="LotaGrotesque not-italic font-normal text-base text-white cursor-pointer h-11 flex flex-row items-center"
        >
          <FontAwesomeIcon icon={faPhone} className="mr-3 w-5 text-white" />
          {!compressed && '+44 20 80 40 90 60'}
        </a>
        <div
          className="LotaGrotesque not-italic font-normal text-base text-white cursor-pointer h-11 flex flex-row items-center"
          onClick={handleClickSettings}
        >
          <FontAwesomeIcon icon={faGear} className="mr-3 w-5 text-white" />
          {!compressed && 'Settings'}
        </div>
        <div
          className="LotaGrotesque not-italic font-normal text-base text-white cursor-pointer h-11 flex flex-row items-center"
          onClick={handleClickLogout}
        >
          <FontAwesomeIcon icon={faDoorOpen} className="mr-3 w-5 text-white" />
          {!compressed && 'Logout'}
        </div>
      </styledComponents.ContainerMenu>
    </styledComponents.ContainerPanel>
  );
};

export default SideMenu;
