interface ViewCellProps {
  is_processed: boolean;
}

const DocumentIsProcessedCell = ({ is_processed }: ViewCellProps) => {
  return (
    <div className="cursor-pointer flex justify-center items-center px-2 py-3 rounded-lg m-auto not-italic font-bold  w-12 h-10 ">
      {is_processed ? '✅' : '❌'}
    </div>
  );
};

export default DocumentIsProcessedCell;
