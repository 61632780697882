import React from 'react';
import { Link } from 'react-router-dom';

const LiabilityDisclaimer = () => {
  return (
    <div className="text-black italic text-sm px-28 py-5 bg-white rounded-b-lg font-semibold">
      Wexler is not intended for use by 'laypersons'. As such all output should be checked by a lawyer before
      publishing. For full details see <Link to='/app/liability' className='text-blue-600 underline'>here</Link>
    </div>
  );
};

export default LiabilityDisclaimer;
