import { useAuthInfo } from '@propelauth/react';
import { APIBase } from 'api/hosts';
import React, { useEffect, useState } from 'react';
import { TOUR_KEYS } from 'tour';
import useGetFetchConfig from '../api/useGetFetchConfig';

export const UserContext = React.createContext();
export const UserProvider = ({ children }) => {
  const [toursDone, setToursDone] = useState([]);
  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const getUserToursDone = async () => {
    await fetch(`${APIBase}/client/user/toursDone`, fetchConfigGET)
      .then((res) => {
        return res.json();
      })
      .then((newToursDoneResponse) => {
        Object.keys(TOUR_KEYS).forEach((TOUR_KEY) => {
          if (newToursDoneResponse?.toursDone?.includes(TOUR_KEY)) {
            localStorage.setItem(TOUR_KEY, 'true');
          } else {
            localStorage.setItem(TOUR_KEY, 'false');
          }
        });
        setToursDone(newToursDoneResponse?.toursDone || []);
      })
      .catch((err) => {
        console.log('Tours response error:', err);
      });
  };

  const markTourKeyDone = async (TOUR_KEY) => {
    localStorage.setItem(TOUR_KEY, 'true');
    const fetchConfigPUT = getFetchConfig({
      method: 'PUT',
      data: {
        toursDone: toursDone.length ? [...toursDone, TOUR_KEY] : [TOUR_KEY],
      },
    });

    const newToursDoneResponse = await fetch(`${APIBase}/client/user/toursDone`, fetchConfigPUT).then((res) => {
      return res.json();
    });
    setToursDone(newToursDoneResponse?.toursDone || []);
  };

  const restartTourDone = async () => {
    Object.keys(TOUR_KEYS).forEach((TOUR_KEY) => {
      localStorage.setItem(TOUR_KEY, 'false');
    });
    const fetchConfigPUT = getFetchConfig({
      method: 'PUT',
      data: {
        toursDone: [],
      },
    });

    const newToursDoneResponse = await fetch(`${APIBase}/client/user/toursDone`, fetchConfigPUT).then((res) => {
      return res.json();
    });
    setToursDone(newToursDoneResponse?.toursDone || []);
  };

  useEffect(() => {
    localStorage.setItem(TOUR_KEYS.ACTIONS_SECTION, 'true');
    localStorage.setItem(TOUR_KEYS.EDITOR_SECTION, 'true');
    localStorage.setItem(TOUR_KEYS.EXPLORER_SECTION, 'true');
    localStorage.setItem(TOUR_KEYS.GENERATOR_SECTION, 'true');
    localStorage.setItem(TOUR_KEYS.SIDE_VIEW_SECTION, 'true');
  }, []);

  useEffect(() => {
    getUserToursDone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <UserContext.Provider
      value={{
        toursDone: toursDone,
        restartTourDone: restartTourDone,
        markTourKeyDone: markTourKeyDone,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
