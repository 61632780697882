import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
interface ViewCellProps {
  doc_id: string;
}
const ViewCell = ({ doc_id }: ViewCellProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleViewDoc = (doc_id: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', doc_id);
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  };

  return (
    <div
      className="cursor-pointer flex justify-center items-center px-2 py-3 rounded-lg m-auto not-italic font-bold  w-12 h-10 mr-5"
      style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
      onClick={() => handleViewDoc(doc_id)}
    >
      View
    </div>
  );
};

export default ViewCell;
