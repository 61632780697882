import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColumnSizingInstance,
  Header,
  HeadersInstance,
  RowModel,
  SortDirection,
  flexRender,
} from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { StageSpinner } from 'react-spinners-kit';
import { ChronosDocumentCoordinate, ChronosFact, FilterOption } from '../../../../../types';
import MultiselectFilter from '../../components/Filters/MultiselectFilter';
import MultiselectFilterDocs from '../../components/Filters/MultiselectFilterDocs';

export const hiddenColumns = ['date_of_subject', 'document_date'];
const sortingIcons: Record<SortDirection, JSX.Element> = {
  asc: (
    <FontAwesomeIcon
      icon={faArrowUp}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
  desc: (
    <FontAwesomeIcon
      icon={faArrowDown}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
};

const resizerStyles = {
  default: 'absolute right-0 top-0 h-3/5 bg-blue-200 w-0.5 cursor-col-resize select-none touch-none mt-2',
  isResizing: 'bg-blue',
};

interface HeaderContentProps {
  header: Header<ChronosFact, unknown>;
  allDocuments: ChronosDocumentCoordinate[];
  handleToggleAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allRowsChecked: boolean;
}

const HeaderContent = function GenerateColumnHeader({
  header,
  allDocuments,
  handleToggleAll,
  allRowsChecked,
}: HeaderContentProps) {
  const centerItem = ['include', 'verify', 'important'].includes(header.id);
  const filterValue = header.column.getFilterValue() as FilterOption[];

  return (
    <div
      className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'} pl-3 ${
        header.column.getCanSort() ? 'cursor-pointer' : ''
      }`}
    >
      <div className="flex flex-row items-center" onClick={header.column.getToggleSortingHandler()}>
        {header.column.id === 'included' && (
          <input
            onChange={handleToggleAll}
            id="checkbox-all"
            type="checkbox"
            checked={allRowsChecked}
            className="cursor-pointer w-5 h-5 mr-4 text-blue-600 bg-gray-100 border-gray-300 rounded-xl focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        )}
        {flexRender(header.column.columnDef.header, header.getContext())}
        {sortingIcons[header.column.getIsSorted() as SortDirection] ?? null}
      </div>
      {header.column.getCanFilter() && header.column.id !== 'source_doc' && (
        <MultiselectFilter column={header.column} filterValue={filterValue} />
      )}
      {header.column.getCanFilter() && header.column.id === 'source_doc' && (
        <MultiselectFilterDocs column={header.column} filterValue={filterValue} allDocuments={allDocuments} />
      )}
    </div>
  );
};

interface FactsEditorTableProps {
  isLoadingFacts: boolean;
  isEmpty: boolean;
  clearAllFilters: () => void;
  getHeaderGroups: HeadersInstance<ChronosFact>['getHeaderGroups'];
  getCenterTotalSize: ColumnSizingInstance['getCenterTotalSize'];
  getRowModel: () => RowModel<ChronosFact>;
  handleToggleAll: HeaderContentProps['handleToggleAll'];
  allRowsChecked: boolean;
  hasActiveFilters: boolean;
  facts: ChronosFact[];
}

const FactsEditorTable = ({
  isEmpty,
  facts,
  getRowModel,
  clearAllFilters,
  isLoadingFacts,
  getHeaderGroups,
  getCenterTotalSize,
  handleToggleAll,
  allRowsChecked,
  hasActiveFilters,
}: FactsEditorTableProps) => {
  const rowsToRender = getRowModel().rows;
  const isEmptyAfterFiltering = rowsToRender.length === 0;

  const allDocuments = useMemo(() => {
    return facts.flatMap((fact) => fact.documents);
  }, [facts]);

  const clearAllFiltersMemoized = useCallback(() => {
    clearAllFilters();
  }, [clearAllFilters]);

  if (isLoadingFacts) {
    return (
      <div className="w-full h-24 flex items-center justify-center">
        <StageSpinner className="m-auto" color={'#4161FF'} />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className="w-full h-24 flex items-center justify-center">
        <p>Could not find facts</p>
      </div>
    );
  }

  const headersLength = getHeaderGroups()[0].headers.length - hiddenColumns.length;

  return (
    <div className="max-h-[calc(100vh-250px)] max-w-full overflow-auto">
      <div style={{ width: getCenterTotalSize(), minWidth: '100%' }}>
        <table style={{minWidth: '100%'}}>
          <thead
            className="h-12"
            style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)', position: 'sticky', top: '0' }}
          >
            {getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const cellStyle = hiddenColumns.includes(header.id) ? { display: 'none' } : {};
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          ...cellStyle,
                          color: 'var(--colors-primary-slate-400, #8897AE)',
                          width: header.getSize(),
                        }}
                        className="not-italic font-medium leading-5 relative"
                      >
                        <HeaderContent
                          header={header}
                          allDocuments={allDocuments}
                          handleToggleAll={handleToggleAll}
                          allRowsChecked={allRowsChecked}
                        />
                        <div
                          {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `${resizerStyles.default} ${
                              header.column.getIsResizing() && resizerStyles.isResizing
                            }`,
                          }}
                        />
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {isEmptyAfterFiltering && !isLoadingFacts ? (
              <tr>
                <td colSpan={headersLength}>
                  <div className="flex justify-center items-center flex-col my-10">
                    <div className="mb-5">No results</div>
                    {hasActiveFilters && (
                      <button
                        className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold  w-56 cursor-pointer"
                        style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                        onClick={clearAllFiltersMemoized}
                      >
                        Clear filters
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ) : (
              rowsToRender.map((row) => {
                return (
                  <tr
                    key={row.id}
                    style={{
                      background: row.index % 2 === 0 ? 'white' : '#F9FAFB',
                      height: '100px',
                      borderBottom: '1px solid #c8cad7',
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const cellStyle = hiddenColumns.includes(cell.column.id) ? { display: 'none' } : {};
                      return (
                        <td
                          className="pl-3"
                          {...{
                            key: cell.id,
                            style: {
                              ...cellStyle,
                              width: cell.column.getSize(),
                              height: '100px',
                              minHeight: '100px',
                              maxHeight: '100px',
                            },
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FactsEditorTable;
