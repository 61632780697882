import { APIBaseChronos } from 'api/hosts';
import TextInputCell from '../../components/TextInputCell';

interface ViewCellProps {
  longer_description: string;
  line_id: string;
}

const FactShortLongDescriptionCell = ({ longer_description, line_id }: ViewCellProps) => {
  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5">
      <TextInputCell
        className={'py-0'}
        editable={true}
        entryId={line_id}
        entryFieldKey={'longer_description'}
        entryFieldValue={longer_description}
        endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${line_id}`}
      />
    </div>
  );
};

export default FactShortLongDescriptionCell;
