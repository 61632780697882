import { rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnFiltersState,
  ColumnSizingState,
  FilterFn,
  OnChangeFn,
  PaginationState,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { APIBaseChronos } from 'api/hosts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CheckState, ChronosFact, FilterOption } from 'types';
import useGetFetchConfig from '../../../../api/useGetFetchConfig';
import FactsEditorTable from './FactsEditorTable';
import FactsEditorToolbar from './FactsEditorToolbar';
import useGetFactColumns from './useGetFactColumns';

interface FactsEditorProps {
  docId?: string;
  onlyTable?: boolean;
}

const LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING = 'fact-table-column-sizing';
const LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY = 'fact-table-column-visibility';
const initialSortingState: SortingState = [
  {
    id: 'date_of_subject',
    desc: false,
  },
  {
    id: 'document_date',
    desc: false,
  },
  {
    id: 'included',
    desc: false,
  },
  {
    id: 'important',
    desc: true,
  },
];

interface FilterFns {
  stringMatch: FilterFn<ChronosFact>;
  fuzzyFilter: FilterFn<ChronosFact>;
  multiSelectDoc: FilterFn<ChronosFact>;
}

export const filterFns: FilterFns = {
  stringMatch: (row, columnId, filterValue: FilterOption[]) => {
    if (!filterValue.length) return true;

    return filterValue.map((values) => values.value).includes(row.getValue(columnId));
  },
  fuzzyFilter: (row, columnId, filterValue: string, addMeta) => {
    if (!filterValue.length) return true;
    const itemRank = rankItem(row.getValue(columnId), filterValue);

    addMeta({
      itemRank,
    });

    const wordsArray = filterValue.split(' ').filter((word) => word.trim() !== '');
    const cellValue = (row.getValue(columnId) as string) || '';
    const isWordPresent = wordsArray.every((word) => cellValue.toLowerCase().includes(word.toLowerCase()));
    return isWordPresent;
  },
  multiSelectDoc: (row, columnId, filterValue: FilterOption[]) => {
    if (!filterValue.length) return true;

    const rowValue = row.getValue<string>(columnId);
    const docIdsInRow = rowValue.split(',');

    const isMatch = filterValue.reduce((accum, curr) => {
      if (docIdsInRow.includes(curr.value)) {
        return true;
      }
      return accum;
    }, false);

    return isMatch;
  },
};

const initialVisibilityState = {
  source_text: false,
  date_uploaded: false,
  subject_matter: false,
  document_date_text: false,
};

const FactsEditor = ({ docId, onlyTable }: FactsEditorProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { fetchConfigGET } = useGetFetchConfig();
  const caseId = searchParams.get('caseId');
  const page = searchParams.get('page');
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialVisibilityState);
  const [facts, setFacts] = useState<ChronosFact[]>([]);
  const [sorting, setSorting] = useState<SortingState>(initialSortingState);
  const [checkedRows, setCheckedRows] = useState<Record<string, CheckState>>({});
  const [isEmpty, setIsEmpty] = useState(false);
  const [isDateDirty, setIsDateDirty] = useState(false);
  const [isLoadingChecked, setIsLoadingChecked] = useState(false);
  const [isLoadingFacts, setIsLoadingFacts] = useState(false);
  const { getFetchConfig } = useGetFetchConfig();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: page ? Number(page) : 0,
    pageSize: 100,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const {
    data: responseFacts,
    refetch: refetchCaseFacts,
    isLoading,
    isFetching,
  } = useQuery(
    ['userFacts', docId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/fact/${caseId}?docId=${docId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  const onPaginationChange: OnChangeFn<PaginationState> = (updaterOrValue) => {
    // @ts-ignore
    const newPaginationState: PaginationState = updaterOrValue(pagination);
    searchParams.set('page', String(newPaginationState.pageIndex));
    navigate(location.pathname + '?' + searchParams.toString());
    setPagination(newPaginationState);
  };

  const handleToggleAll = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (caseId) {
      setIsLoadingChecked(true);

      const isChecked = event.target.checked;
      const idsToToggle = table.getFilteredRowModel().rows.map((row) => row.id);
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { included: isChecked, caseId: caseId, idsToToggle } });

      const newCheckedState = idsToToggle.reduce(
        (accum, eventId) => {
          accum[eventId] = {
            isIncluded: isChecked,
            isVerified: !isChecked ? false : checkedRows[eventId].isIncluded ? checkedRows[eventId].isVerified : false,
            verified_by_email: allRowsChecked ? '' : checkedRows[eventId].verified_by_email,
            verified_date: allRowsChecked ? new Date(Date.now()) : checkedRows[eventId].verified_date,
          };
          return accum;
        },
        { ...checkedRows },
      );

      // Update the local state before starting the fetches
      setCheckedRows(newCheckedState);

      await fetch(`${APIBaseChronos}/client/case/fact/toggleAll`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error on update',
            text: 'There was an error on updating the fields. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });

      setIsLoadingChecked(false);
    }
  };

  const columns = useGetFactColumns({
    checkedRows,
    setCheckedRows,
    setIsDateDirty,
    sorting,
    callback: refetchCaseFacts,
  });

  const onColumnSizingChange: OnChangeFn<ColumnSizingState> = (updaterOrValue) => {
    // @ts-ignore
    const columnSize: ColumnSizingState = updaterOrValue();
    const newState = { ...columnSizing, ...columnSize };

    setColumnSizing(newState);
    localStorage.setItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING, JSON.stringify(newState));
  };

  useEffect(() => {
    const columnSizingString = localStorage.getItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING);
    const columnSizing = columnSizingString ? JSON.parse(columnSizingString) : {};

    const columnVisibilityString = localStorage.getItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY);
    const columnVisibility = columnVisibilityString ? JSON.parse(columnVisibilityString) : initialVisibilityState;

    if (columnSizing) {
      setColumnSizing(columnSizing);
    }

    if (columnVisibility) {
      setColumnVisibility(columnVisibility);
    }
  }, []);

  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = (updaterOrValue) => {
    // @ts-ignore
    const newColumnVisibility: VisibilityState = updaterOrValue();
    const newState = { ...columnVisibility, ...newColumnVisibility };

    setColumnVisibility(newState);
    localStorage.setItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY, JSON.stringify(newState));
  };

  const onColumnFiltersChange: OnChangeFn<ColumnFiltersState> = (updaterOrValue) => {
    // @ts-ignore
    const newColumnFilters: ColumnFiltersState = updaterOrValue(columnFilters);

    searchParams.set('page', '0');
    setColumnFilters(newColumnFilters);
  };

  const resetVisibleColumns = useCallback(() => {
    const resetValue = {};
    localStorage.setItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY, JSON.stringify(resetValue));
    setColumnVisibility(resetValue);
  }, []);

  const table = useReactTable<ChronosFact>({
    columns,
    data: facts,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange,
    columnResizeMode: 'onChange',
    getRowId: (row) => row.event_id,
    onColumnSizingChange,
    onSortingChange: setSorting,
    enableMultiSort: true,
    onColumnFiltersChange,
    globalFilterFn: filterFns.fuzzyFilter,
    initialState: { pagination },
    state: { globalFilter, sorting, columnFilters, columnSizing, columnVisibility, pagination },
  });

  const allRowsChecked = useMemo(() => {
    return table.getFilteredRowModel().rows.every((r) => checkedRows[r.id].isIncluded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRows, columnFilters, globalFilter]);

  const clearAllFilters = useCallback(() => setColumnFilters([]), []);

  useEffect(() => {
    if (isFetching) {
      setIsLoadingFacts(isLoading || isFetching);
    }
  }, [isLoading, isFetching]);

  useEffect(() => {
    refetchCaseFacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  useEffect(() => {
    if (responseFacts?.facts && responseFacts?.facts?.length > 0) {
      const modifiedFacts = responseFacts.facts.map((fact: ChronosFact) => ({
        ...fact,
        source_doc: fact.documents.map((doc) => doc.doc_id).join(','),
      }));

      const newCheckedState: any = {};
      responseFacts.facts.forEach((row: ChronosFact) => {
        newCheckedState[row.event_id] = {
          isIncluded: row.included,
          isVerified: row.verified,
          verified_by_email: row.verified_by_email,
          verified_date: row.verified_date,
        };
      });
      setFacts(modifiedFacts);
      setCheckedRows(newCheckedState);
      setIsEmpty(false);
    } else if (responseFacts?.facts?.length === 0) {
      setIsEmpty(true);
    }
    setIsDateDirty(false);
    setIsLoadingFacts(false);
  }, [responseFacts]);

  const hasActiveFilters = useMemo(() => columnFilters.flatMap((filter) => filter.value).length > 0, [columnFilters]);

  return (
    <>
      <FactsEditorToolbar
        docId={docId}
        caseId={caseId}
        facts={facts}
        checkedRows={checkedRows}
        isLoadingChecked={isLoadingChecked}
        isLoadingFacts={isLoadingFacts}
        globalFilter={table.getState().globalFilter}
        clearAllFilters={clearAllFilters}
        setGlobalFilter={setGlobalFilter}
        refetchCaseFacts={refetchCaseFacts}
        columns={table.getAllFlatColumns()}
        resetVisibleColumns={resetVisibleColumns}
        resultsLength={table.getFilteredRowModel().rows.length}
        canGetPrevPage={table.getCanPreviousPage()}
        canGetNextPage={table.getCanNextPage()}
        prevPage={table.previousPage}
        nextPage={table.nextPage}
        currentPage={table.getState().pagination.pageIndex + 1}
        noOfPages={table.getPageCount()}
        isDateDirty={isDateDirty}
      />
      <FactsEditorTable
        isEmpty={isEmpty}
        isLoadingFacts={isLoadingFacts || isFetching}
        getRowModel={table.getRowModel}
        getCenterTotalSize={table.getCenterTotalSize}
        getHeaderGroups={table.getHeaderGroups}
        allRowsChecked={allRowsChecked}
        handleToggleAll={handleToggleAll}
        clearAllFilters={clearAllFilters}
        hasActiveFilters={hasActiveFilters}
        facts={facts}
      />
    </>
  );
};

export default FactsEditor;
