import { ColumnDef } from '@tanstack/react-table';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { TooltipStyles } from 'constants/styles';
import { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { APIBaseChronos } from '../../../../api/hosts';
import { ChronosChronologyEvent, ChronosDocumentCoordinate } from '../../../../types';
import DateInputCell from '../components/DateInputCell';
import TextInputCell from '../components/TextInputCell';
import FactShortLongDescriptionCell from './FactShortLongDescriptionCell';

export const chronologyColumnLabels: Record<string, string> = {
  fact_date_text: 'Date',
  fact_description: 'Fact & Description',
  longer_description: 'Description',
  source_text: 'Source Text',
  source_doc: 'Source Doc',
  relevance_reason: 'Relevance reason'
};

interface UseGetChronologyColumns {
  chronologyId: string;
  refetch: ()=> {};
}

const useGetChronologyColumns = ({ chronologyId, refetch }: UseGetChronologyColumns) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const goToDocReference = (newDocId: string, newEventId: string, pageNumber: number) => {
    return () => {
      searchParams.set('docId', newDocId);
      searchParams.set('eventId', newEventId);
      searchParams.set('chronologyId', chronologyId);
      searchParams.set('pageNumber', String(pageNumber));

      navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
    };
  };

  const columns: ColumnDef<ChronosChronologyEvent>[] = useMemo(
    () => [
      {
        header: () => (
          <div className="flex items-center">
            <ReactInfo
              data-tooltip-id="date-tooltip"
              data-tooltip-content="Fact Date"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="date-tooltip" style={TooltipStyles} />
            Date
          </div>
        ),
        accessorKey: 'fact_date',
        maxSize: 50,
        cell: ({
          cell: {
            row: {
              original: { fact_date, line_id },
            },
          },
        }: any) => (
          <DateInputCell
            editable={true}
            entryId={line_id}
            entryFieldKey={'fact_date'}
            entryFieldValue={fact_date}
            endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${line_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex items-center">
            <ReactInfo
              data-tooltip-id="fact-date-tooltip"
              data-tooltip-content="Date of the fact"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="fact-date-tooltip" style={TooltipStyles} />
            Date
          </div>
        ),
        maxSize: 70,
        accessorKey: 'fact_date_text',
        cell: ({
          cell: {
            row: {
              original: { line_id, fact_date_text, is_document_date },
            },
          },
        }: any) => {
          return (
            <div className="flex flex-row items-center">
              {!!is_document_date && (
                <ReactInfo
                  data-tooltip-id={`fact-date-text-tooltip-${line_id}`}
                  data-tooltip-content="Date taken from document"
                  className="text-gray-700 mr-2 text-sm cursor-pointer"
                  style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
                />
              )}
              {!!is_document_date && <Tooltip id={`fact-date-text-tooltip-${line_id}`} style={TooltipStyles} />}
              <TextInputCell
                editable={true}
                entryId={line_id}
                entryFieldKey={'fact_date_text'}
                entryFieldValue={fact_date_text}
                callback={refetch}
                endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${line_id}`}
              />
            </div>
          );
        },
      },
      {
        header: () => (
          <div className="flex items-center max-w-sm">
            <ReactInfo
              data-tooltip-id="factdescription-tooltip"
              data-tooltip-content="Description"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="factdescription-tooltip" style={TooltipStyles} />
            Description
          </div>
        ),
        accessorKey: 'longer_description',
        cell: ({
          cell: {
            row: {
              original: { longer_description, line_id },
            },
          },
        }: any) => <FactShortLongDescriptionCell longer_description={longer_description} line_id={line_id} />,
      },
      {
        header: () => (
          <div className="flex items-center">
            <ReactInfo
              data-tooltip-id="source-text-tooltip"
              data-tooltip-content="Source text of the event"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="source-text-tooltip" style={TooltipStyles} />
            Source Text
          </div>
        ),
        accessorKey: 'source_text',
        cell: ({
          cell: {
            row: {
              original: { source_text, line_id },
            },
          },
        }: any) => {
          return (
            <TextInputCell
              html={true}
              className={'max-w-md'}
              editable={false}
              entryId={line_id}
              entryFieldKey={'source_text'}
              entryFieldValue={source_text || ''}
              endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${line_id}`}
            />
          );
        },
      },
      {
        header: () => (
          <div className="flex items-center">
            <ReactInfo
              data-tooltip-id="sourcedoc-tooltip"
              data-tooltip-content="Document from which fact was extracted, click to view source text"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="sourcedoc-tooltip" style={TooltipStyles} />
            Source Doc
          </div>
        ),
        accessorKey: 'source_doc',
        maxSize: 100,
        cell: ({
          cell: {
            row: {
              original: { documents, event_id },
            },
          },
        }: any) => (
          <div>
            {documents.map((document: ChronosDocumentCoordinate, i: number) => {
              const pageNumber = document.coordinate_details[0]?.n_page || 1;

              return (
                <div
                  key={`${document.fact_id}-${i}`}
                  className="cursor-pointer text-blue-500 hover:underline hover:text-blue-600"
                  onClick={goToDocReference(document.doc_id, event_id, pageNumber)}
                >
                  <p className="text-ellipsis overflow-hidden whitespace-nowrap">
                    {document?.file_name?.split('.')?.filter(Boolean)[0]}
                  </p>
                </div>
              );
            })}
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex items-center">
            <ReactInfo
              data-tooltip-id="significance-tooltip"
              data-tooltip-content="A short analysis of the significance of the fact as it pertains to the synopsis and the context of the document it is extracted from."
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="significance-tooltip" style={TooltipStyles} />
            Significance
          </div>
        ),
        accessorKey: 'relevance_reason',
        cell: ({
          cell: {
            row: {
              original: { relevance_reason, line_id },
            },
          },
        }: any) => {
          return (
            <TextInputCell
              html={false}
              className={'max-w-md'}
              editable={true}
              entryId={line_id}
              entryFieldKey={'relevance_reason'}
              entryFieldValue={relevance_reason || ''}
              endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${line_id}`}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chronologyId],
  );

  return columns;
};

export default useGetChronologyColumns;
