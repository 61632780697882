import { useState } from 'react';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { reactSelectStylesTableGenerator } from 'constants/styles';
import { MyOptionType, KEY_VALUE_TYPE } from 'types';
import { IMPORTANT_COLOR_MAP, IMPORTANT_COLORS_KEYS, AGREED_COLOR_MAP, AGREED_COLORS_KEYS } from 'constants/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faCheck } from '@fortawesome/free-solid-svg-icons';
import useGetFetchConfig from '../../../../../api/useGetFetchConfig';
interface SelectInputCellProps {
  entryId: string;
  entryFieldKey: string;
  entryFieldValue: string;
  endpointToUpdate: string;
  editable?: boolean;
  placeholder?: string;
  options: KEY_VALUE_TYPE;
  className?: string;
}
const SelectInputCell = ({
  entryId,
  entryFieldKey,
  entryFieldValue,
  endpointToUpdate,
  options,
  placeholder,
  className,
}: SelectInputCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFieldValue, setCurrentFieldValue] = useState<MyOptionType>(options[entryFieldValue]);

  const { getFetchConfig } = useGetFetchConfig();
  const getColorFromMap = () => {
    switch (entryFieldKey) {
      case 'important':
        return IMPORTANT_COLOR_MAP[currentFieldValue?.value as IMPORTANT_COLORS_KEYS] || 'inherit';
      case 'agreed':
        return AGREED_COLOR_MAP[currentFieldValue?.value as AGREED_COLORS_KEYS] || 'inherit';
      default:
        return 'inherit';
    }
  };

  const color = getColorFromMap();

  const handleChangeFieldValue = (val: MyOptionType) => {
    setCurrentFieldValue(val);
    const fetchConfig = getFetchConfig({ method: 'PUT', data: { [entryFieldKey]: val?.value } });

    return fetch(endpointToUpdate, fetchConfig)
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.error('Fetch Error: ', err);
        Swal.fire({
          title: 'Error on update',
          text: 'There was an error on updating the fields. Please try again later.',
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const togleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5 z-50">
      <div className="flex flex-row items-center w-full justify-start pl-2">
        {editMode ? (
          <div className="flex items-center">
            <Select
              options={Object.values(options)}
              className={`${className} outline-none w-32 bg-white rounded-md`}
              styles={reactSelectStylesTableGenerator(color)}
              onChange={handleChangeFieldValue}
              value={currentFieldValue}
              placeholder={placeholder}
            />
            <FontAwesomeIcon onClick={togleEditMode} icon={faCheck} className="text-gray-700 ml-2 cursor-pointer" />
          </div>
        ) : (
          <div className="flex items-center font-bold" style={{ color: color }}>
            {currentFieldValue?.label}
            <FontAwesomeIcon onClick={togleEditMode} icon={faPencil} className="text-gray-700 ml-5 cursor-pointer" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectInputCell;
